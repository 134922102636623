import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import {Loader} from '../../elements/Loader';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export const FinalSubmit= ({pageDefault,navigate})=>{
    
  
     let api_token=localStorage.getItem('_user_token');
     const [loader, setLoader] = useState(false);
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
     }; 
   
    

     const [confirmdata, setConfirmData] = useState({
        status:false,
        message:"",
        data:null,
      }); 

    const [confirmshow, setConfirmShow] = useState({status:false});
    const confirmHandleClose = () => setConfirmShow({status:false});



function add_final_test_answer(event) {
    
    try {
        
        
        setLoader(true);
   
        axios.get(APP_URL+'add_final_test_answer',{headers,params:{test_id:pageDefault.test_id}},[])
        .then(response=>{
            
            setTimeout(() => {
                setLoader(false);
                navigate("/test_score_card",{state:{
                    test_id:pageDefault.test_id,
                    topic_name:pageDefault.topic_name,
                    test_name:pageDefault.test_name,
                    sub_category_image:pageDefault.sub_category_image,
                }});
            }, 5000); //5 seconds

       
         
          
        })
        .catch(error=>{
         setLoader(false);  
                
        })


      } catch (e) { //code will jump here if there is a network problem
     setLoader(false);  
        
 }

}


const confirmHandleShow=(single_data)=>{
        
    try {
    
        setLoader(true);
   
        axios.get(APP_URL+'test_confirmation_before_final_submit',{headers,params:{test_id:pageDefault.test_id}},[])
        .then(response=>{
         setLoader(false);
        
         setConfirmData({
             status: response.data.status,
             message: response.data.message,
             data: response.data.data,
           });
          
           setConfirmShow({status:true})
         
          
        })
        .catch(error=>{
         setLoader(false);  
         setConfirmData({
             status: false,
             message: "",
             data: null,
           });
       
        })


      } catch (e) { //code will jump here if there is a network problem
     setLoader(false);  
     setConfirmData({
         status: false,
         message: "",
         data: null,
       });
     
 }

   
}

    

       return (
        <>
        {loader ? <Loader />: ""}
        <Button className="btn"  onClick={()=>confirmHandleShow("")}>Submit</Button>

        <Modal show={confirmshow.status} onHide={confirmHandleClose} size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub p-0" >
              <Modal.Header closeButton>
                <Modal.Title><h3 className="m-0"> Test Submission</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>

              <div className="modal_body p-0">
                            <div className="submission_box-wrap">
                              
                                    <div className="submission_box justify-content-between d-flex bl-blue blue">
                                        <i className="lar la-clock"></i>
                                        <div className="submission_right ">
                                            <span>Time Left</span>
                                            <h3 className="mt-2">{localStorage.getItem('test_display_time')}</h3>
                                        </div>
                                    </div>
                              
                              
                                    <div className="submission_box justify-content-between d-flex bl-green green">
                                        <i className="las la-check-circle"></i>
                                        <div className="submission_right ">
                                            <span>Attempted</span>
                                            <h3 className="mt-2">{confirmdata.status==true?confirmdata.data.total_attempted:""}</h3>
                                        </div>
                                    </div>
                               
                              
                                    <div className="submission_box justify-content-between d-flex bl-orange orange">
                                        <i className="las la-minus-circle"></i>
                                        <div className="submission_right ">
                                            <span>Unattempted</span>
                                            <h3 className="mt-2">{confirmdata.status==true?confirmdata.data.total_question-confirmdata.data.total_attempted:""}</h3>
                                        </div>
                                    </div>
                               
                              
                                    <div className="submission_box justify-content-between d-flex bl-yellow yellow">
                                        <i className="las la-bookmark"></i>
                                        <div className="submission_right ">
                                            <span>Reviewed</span>
                                            <h3 className="mt-2">{confirmdata.status==true?confirmdata.data.total_reviewed:""}</h3>
                                        </div>
                                    </div>
                               
                            </div>
                        </div>

                             
              </Modal.Body>
            
              <Modal.Footer>
                <Button variant="secondary" className=" r_submit-btn gr-blue text-white" type="button"  onClick={(event) => {add_final_test_answer(event)}}>
                Submit
                </Button>
                <Button variant="primary" className=" r_resume-btn" type="button"  onClick={confirmHandleClose}>
                Resume
                </Button>
              </Modal.Footer>
            </Modal>

        </>
        
            
        );
   
      
    }  
    

export default FinalSubmit;