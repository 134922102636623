import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';

import { APP_URL } from '../constants/global';
import { useNavigate,useLocation } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import {Message} from './elements/Message';
export const Subscription= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    

    let pageDefault=location.state;
   
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 
      
      useEffect(() => { 
              
       try {

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 

           setLoader(true);
      
           axios.get(APP_URL+'get_subscription_details',{headers,params:{subscription_id:pageDefault.subscription_id}},[])
           .then(response=>{
            setLoader(false);
           
            setData({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
              });
             
              
            
             
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
        //console.log(e);
    }

       return () => {
            
        }

      },[pageDefault]);
      

      const add_to_cart=(subscription_id,type,duration,price,is_buy_subscription_click)=>{
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
                }; 
               setLoader(true);
          
               axios.post(APP_URL+"add_to_cart",{subscription_id:subscription_id,type:type,duration:duration,price:price},{headers})
               .then(response=>{
                setLoader(false);
                
                if (response.data.cart_total) {
                    localStorage.setItem('cart_count',response.data.cart_total);
                }else{
                    localStorage.removeItem('cart_count');
                }
                
                if (is_buy_subscription_click===1) {
                    navigate("/subscription_cart");
                }else{
                  
                }
                
               })
               .catch(error=>{
                setLoader(false);  
                
                //console.log(error);
               })
      
       
             } catch (e) { //code will jump here if there is a network problem
            setLoader(false);  
            
            //console.log(e);
        }
    }

    
        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}

             <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                               
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                            <span className="ae_heading text-start">{pageDefault.sub_category_name} </span>
                                            <h3 className="text-uppercase text-left">{data.status?data.data.name:""}</h3>
                                        </div>
                                        
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                            <button className="btn text-white text-end">{pageDefault.type}</button>
                                        </div>
                                  
                                </div>
                              
                              
                                <div className="page_overlay"></div>
                               
                            </div>

                          
                     

                            <ProfileSideMenu />
                    </div>

          

            <div className="get_sub-wrap">
                <div className="get_sub">
                    <div className="sub_card-l p-0 position-relative">
                        <img alt="" className="" src={data.status===true && data.data.image!=null?IMAGE_URL+data.data.image:"/images/plan-card/dummy.png"} />
                       <div>
                      
                        <h4 className="m-0">{data.status?data.data.name:""}</h4>
                     
                       </div>
                      
                    </div>
                    <p className="my-xxl-4 my-xl-4 my-md-3 my-sm-2 my-2">what will you get in this course?</p>
                </div>
                <div className="row justify-content-xl-between justify-content-md-center justify-content-sm-center ">
                    <div className="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 px-xxl-0 px-xl-2 px-md-2 px-sm-2 px-1">
                        <div className="offer_box border-0 m-0">
                            <img alt="" className="img-fluid" src="/images/dashboard/practice.png" />
                            <div className="align-middle d-flex">  
                                 <div className="m-auto">
                                    <h3>Chapters</h3>
                                <p className="m-0 get_sub-data">{data.status?data.data.total_chapter:""}</p>
                                 </div>
                            </div>
                         
                           </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 px-xxl-0 px-xl-2 px-md-2 px-sm-2 px-1">
                        <div className="offer_box border-0 m-0">
                            <img alt="" className="img-fluid" src="/images/dashboard/practice.png" />
                            <div className="align-middle d-flex">  
                                 <div className="m-auto">
                                    <h3>Topics</h3>
                                <p className="m-0 get_sub-data orange">{data.status?data.data.total_topic:""}</p>
                                 </div>
                            </div>
                         
                           </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 px-xxl-0 px-xl-2 px-md-2 px-sm-2 px-1">
                        <div className="offer_box border-0 m-0">
                            <img alt="" className="img-fluid" src="/images/dashboard/practice.png" />
                            <div className="align-middle d-flex">  
                                 <div className="m-auto">
                                    <h3>Questions</h3>
                                <p className="m-0 get_sub-data green">{data.status?data.data.total_practice_question:""}</p>
                                 </div>
                            </div>
                         
                           </div>
                    </div>
                </div>
                  <div dangerouslySetInnerHTML={{__html: data.details}}></div>
                <div className="exam_catagory p-0 mt-xxl-3 mt-xl-3 mt-md-3 mt-sm-3 mt-3 pt-3">
                    <h3 className="mt-2">Get Subscription</h3>
                    <div className="get_sub_box-wrap ">
                      { data.status===true && data.data.subscription_plan.map((plan,key) => ( 
                        
                                <div className="get_sub-box d-xl-flex d-md-flex d-sm-block d-block justify-content-between" key={key}  style={{background: data.data.is_active===1 && plan.duration===data.data.duration?"lightgreen":""}}>
                                   <div className="d-flex align-items-center">
                                    {data.data.duration_type === 0 ? 
                                    <div className="get_month d-flex align-items-center">
                                        <p><span>{plan.duration}</span></p>
                                        <small>Month</small>
                                    </div> : 
                                    <div className="get_month d-flex align-items-center">
                                        <small>Date based</small>
                                    </div>
                                    }
                                    <h5 className="offer_p ">₹{plan.dummy_price}/-</h5>
                                    <h5 className="final_p ">₹{plan.price}/-</h5>
                                    {data.data.duration_type === 1 ?
                                    <h5>Start from:{plan.start_date} <small> to </small> {plan.end_date}</h5>
                                    :'' }
                                   </div>
                                   
                                    <div className="d-flex align-items-center justify-content-end ">
                                  
                                        {data.data.is_active===1 && plan.duration===data.data.duration?"Active Plan":""}

                                        <button className="rounded-pill get_but-sub" type="button"  onClick={()=>add_to_cart(data.data.id,data.data.type,plan.duration,plan.price,1)}>Buy Subscription</button>
                                    <div className="c_cart">
                                    
                                    <button type="button" className="p-0" onClick={()=>add_to_cart(data.data.id,data.data.type,plan.duration,plan.price,0)}><i className="las la-shopping-cart"></i></button>
                                        
                                    </div>
                                    </div>

                                </div>
                            ))}

                        
                       


                    </div>
                </div>
            </div>

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default Subscription;