import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {Loader} from './elements/Loader';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import {Message} from './elements/Message';
export const SubscriptionCart= ()=>{
    const navigate = useNavigate();
    let api_token=localStorage.getItem('_user_token');
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({
        status:false,
        message:"",
        student_cart: null,
        gst: null,
        
      });
    const location = useLocation();
    
    let pageDefault=location.state!==null?location.state:"";
    
      
    const [coupon_details, setCouponDetails] = useState({
        status:false,
        message:"",
        data: null,
      });
    const [coupon_code, setCouponCode] = useState("");
    
   
    
    const [flashShow, setFlashShow] = useState("");

     

      const [amount, setAmount] = useState({
        subtotal:0,
        coupon_discount: 0,
        taxable_amount: 0,
        cgst_amount: 0,
        sgst_amount: 0,
        total_amount: 0,
      }); 

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + api_token
        };


        function calculation(parameter,coupon_discount) {
         
            let item_total=0;
            parameter.student_cart.forEach(child_data => 
                item_total+=child_data.price
            );
           
            let subtotal=(item_total*100)/118;
            
            let discount=0;
            let taxable_amount=0;
            
           
            if (coupon_discount.discount>0) {
            
                discount=coupon_discount.discount;
                taxable_amount = item_total - coupon_discount.discount;
            }else{
                taxable_amount = item_total;
            }           

            let taxable_amount_main=0;
            if (coupon_discount.discount>0) {
                taxable_amount_main = (taxable_amount * 100) / 118;
            }else{
                taxable_amount_main = subtotal;
            }
           
            let cgst_percentage_amount=0;
            let sgst_percentage_amount=0;
           
            cgst_percentage_amount=taxable_amount_main*9/100;
            sgst_percentage_amount=taxable_amount_main*9/100;
            if (subtotal.toFixed(2)!==discount.toFixed(2)) {
                setAmount({
                    subtotal:subtotal.toFixed(2),
                    coupon_discount: discount.toFixed(2),
                    taxable_amount: taxable_amount_main.toFixed(2),
                    cgst_amount: cgst_percentage_amount.toFixed(2),
                    sgst_amount: sgst_percentage_amount.toFixed(2),
                    total_tax: (cgst_percentage_amount+sgst_percentage_amount).toFixed(2),
                    total_amount: (taxable_amount).toFixed(2),
                });    
            }else{
                
                setAmount({
                    subtotal:subtotal.toFixed(2),
                    coupon_discount: discount.toFixed(2),
                    taxable_amount: 0.00,
                    cgst_amount: 0.00,
                    sgst_amount: 0.00,
                    total_tax: 0.00,
                    total_amount: 0.00,
                });
            }
            
        
        
  
  }  

   
      function get_cart_details() {
        
       
        try {
           
               setLoader(true);
          
               axios.get(APP_URL+'get_cart_details',{headers},[])
               .then(response=>{
                setLoader(false);
               
                setData({
                    status: response.data.status,
                    message: response.data.message,
                    student_cart: response.data.student_cart,
                    gst: response.data.gst,
                  });
                  localStorage.setItem('cart_count',response.data.student_cart.length);

                  calculation(response.data,{});
                  return true;
                 
               })
               .catch(error=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate("/login");   
                }
                
                if (error.response.status===404) {
                  localStorage.removeItem('cart_count');   
                 //navigate("/dashboard"); 
                }
                
                setLoader(false);  
                setData({
                    status: false,
                    message: "",
                    student_cart: null,
                    gst: null,
                  });
                //console.log(error);
               })
      
               return false;
             } catch (e) { //code will jump here if there is a network problem
            localStorage.removeItem('cart_count');
            setLoader(false);  
            setData({
                status: false,
                message: "",
                student_cart: null,
                gst: null,
              });
              return false;
            //console.log(e);
        }
        
      }
      
      useEffect(() => { 
       
        get_cart_details();
        
       return () => {
            
        }

      },[]);
      

      const remove_cart_item=(student_cart_id)=>{
        
        try {
            
               setLoader(true);
          
               axios.post(APP_URL+"remove_from_cart",{cart_id:student_cart_id},{headers})
               .then(response=>{
                setLoader(false);
               
                if (response.data.cart_total) {
                    localStorage.setItem('cart_count',response.data.cart_total);
                }else{
                    localStorage.removeItem('cart_count');
                }
                
                setFlashShow(<Message variant="success" children={response.data.message}/>);    
                setCouponDetails({
                    status:false,
                    message:"",
                    data: null,
                });
                
                if (response.data.cart_total<=0) {
                    
                    setAmount({
                        subtotal:0.00,
                        coupon_discount: 0.00,
                        taxable_amount: 0.00,
                        cgst_amount: 0.00,
                        sgst_amount: 0.00,
                        total_tax: 0.00,
                        total_amount: 0.00,
                    });

                    // navigate("/dashboard");
                }
               
                /*  */
                get_cart_details();

                setCouponCode("");
                
               })
               .catch(error=>{
                setLoader(false);  
                
                //console.log(error);
               })
      
       
             } catch (e) { //code will jump here if there is a network problem
            setLoader(false);  
            
            //console.log(e);
        }
            
       

      }
     
    const changeHandler = (e) => {
        setCouponCode(e.target.value);
    }


    function apply_coupon_code_fun(coupon_code_param) {
        try {
           
            if (coupon_code_param.length<=0) {
             return null;
            }  
         
            setLoader(true);
       
            let apply_remove=(coupon_details.status===false?"apply":"remove");
            axios.post(APP_URL+"apply_coupon_code",{coupon_code:coupon_code_param,apply_remove:apply_remove},{headers})
            .then(response=>{
            
             setLoader(false);
             setFlashShow(<Message variant="success" children={response.data.message}/>);    
             
             if (apply_remove==="apply") {
                
                 setCouponDetails({
                     status:response.data.status,
                     message:response.data.message,
                     data: response.data.data,
                     });    
                     
                 calculation(data,response.data.data);
                 return null;

             }else{
                 setCouponDetails({
                 status:false,
                 message:"",
                 data: null,
                 });
                 setCouponCode("");
                 calculation(data,response.data.data);
             }
            })
            .catch(error=>{
              
             setLoader(false);  
             if (error.response.status===422) {
                setFlashShow(<Message variant="danger" children={error.response.data.message}/>);   
             }
             
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         //setFlashShow(<Message variant="danger" children="Please check your internet connection"/>);
        
     }
    }

      const apply_coupon_code = (e) => {
        e.preventDefault();
        apply_coupon_code_fun(coupon_code);
    }
    
    
    useEffect(() => { 

        if (pageDefault.offer_coupon_code && pageDefault.offer_coupon_code.length>0 && coupon_code.length<=0) {
            setCouponCode(pageDefault.offer_coupon_code);
            document.getElementById("apply_coupon_code_btn").click();
            
            setTimeout(() => document.getElementById('apply_coupon_code_btn').click(), 700);

            //window.location.reload();
           // apply_coupon_code_fun(pageDefault.offer_coupon_code);
        }

       return () => {
            
        }

      },[pageDefault]);
        return (
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
              {/* Inner _age Banner End */}
              <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                        <img alt="" className="img-fluid" src="/images/pyq/label.svg" />
                                 <div className="d-flex">
                                    <h1 className="text-capitalize">Subscription Cart</h1>
                                 </div>
                                    </div>
                                </div>                              
                                <div className="page_overlay"></div>
                               
                            </div>
                        
                    </div>

            {/* Inner _age Banner End */}


            {/*---Subscription-plan start----*/}

            <div className="sub_plan-board">
            {flashShow}
                <div className="sub_card-wrapper d-block">
                <div className="sub_card_w-inner">
                { data.status===true && data.student_cart.map((student_cart,key) => ( 
                  
                        <div className="sub_card" key={key}>
                        <div className="sub_card-l position-relative">
                            <img alt="" className=""  src="/images/plan-card/electronic.png" />
                           <div>
                           {/*  <p>gate ee</p> */}
                            <h4>{student_cart.name}</h4>
                            {student_cart.duration_type === 1 ? 
                            <span>Date Based Subscription</span>
                            :
                            <span>{student_cart.duration} Month Subscription</span>}
                           </div>
                           <img alt="" className="gear_sub" src="/images/plan-card/bg-gear.png" />
                        </div>
                        <div className="d-flex">
                        <div className="sub_card-r d-flex">
                                <p className="orange">₹ {student_cart.price}/-</p>
                        </div>
                       
                        <div className="close ">
                            
                            <button type="button" className="m-auto" onClick={()=>remove_cart_item(student_cart.id)}><i className="las la-times"></i></button>
                          
                        </div>
                        </div>
                    </div>
                ))}
                    </div>
                    <form onSubmit={apply_coupon_code}>
                    <div className="discount_bar-wrapper">
                        <div className="discount_bar p-0">
                            <div className="input-group ">

                                {coupon_details.status===false?<><input type="text" name="coupon_code" className="form-control" placeholder="Enter Coupon Code" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={changeHandler} value={coupon_code ? coupon_code : ""} /><button className="btn btn-outline-secondary" id="apply_coupon_code_btn" type="submit">Apply</button></>:<><input type="text" name="coupon_code" className="form-control" placeholder="Enter Coupon Code" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={changeHandler} value={coupon_code ? coupon_code : ""} readOnly={true}/><button className="btn btn-outline-secondary" type="submit" id="button-addon2">Remove</button></>}
                                
                              </div>
                              {/* <div className="max_dis">
                                    <img alt="" className="img-fluid" src="/images/subscription/coin.png" />
                                   <div className="d-flex">
                                    <span>get up to 10% extra discount</span>
                                   </div>
                              </div> */}
                        </div>
                    </div>
                    </form>
                    <div className="bill_box-wrapper">
                        <div className="bill_box">
                            <div className="rs_bar">
                                <h4>Subtotal ({data.status?data.student_cart.length:0} Subscription)</h4>
                                <p>₹ {amount.subtotal}</p>
                            </div>
                            {coupon_details.status===true?<><div className="rs_bar">
                                <h4>Coupon Discount ({coupon_details.data.code})</h4>
                                <p>₹ {coupon_details.data.discount.toFixed(2)}</p>
                            </div><div className="rs_bar">
                                <h4>Taxable Amount</h4>
                                <p>₹ {amount.taxable_amount}</p>
                            </div></>:""}
                            
                            <div className="rs_bar">
                                <h4>CGST @9%</h4>
                                <p>₹ {amount.cgst_amount}</p>
                            </div>
                            <div className="rs_bar">
                                <h4>SGST @9%</h4>
                                <p>₹ {amount.sgst_amount}</p>
                            </div>
                            <div className="rs_bar">
                                <h4>Total Tax</h4>
                                <p>₹ {amount.total_tax}</p>
                            </div>
                            <div className="total_bar mb-0">
                                <p>Amount Payable</p>
                                <h3>₹ {amount.total_amount}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="p_checkout text-center" style={{ display: data.status ? "block" : "none" }}>
                    <button className="btn " type="submit"> <NavLink className="rounded-pill"  to="/billing_information" state={{coupon_details:coupon_details}}>Continue</NavLink></button>
                    </div>
                </div>
            </div>

          
            {/*------Subscription-plan-end--*/}
            <ProfileSideMenu />
             <Footer />
              </main>
             
            
            </>);
    }  
    

export default SubscriptionCart;