import React, { useState ,useEffect,memo}from "react";
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import axios from '../../general/axios.js';
import InfiniteScroll from "react-infinite-scroll-component";
import {Loader} from '../../elements/Loader';

const TabQuestionList= ({setActiveQuestion,pageDefault,countertwo})=>{
   
    const [loader, setLoader] = useState(false);
    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ]
        }
    };

    let api_token=localStorage.getItem('_user_token');
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + api_token
    }; 
    const [state, setState] = useState({
        breweries: [],
        pageNumber: 1,
        items: 10,
        hasMore: true
    });
      
    function fetchData(){
        try {
            axios.get(APP_URL+'get_all_pyq_question',{headers,params:{topic_id:pageDefault.topic_id,page:state.pageNumber,per_page:state.items}},[])
            .then(response=>{
                setState({
                    //updating data
                    breweries: [...state.breweries, ...response.data.data],
                    //updating page numbers
                    pageNumber: state.pageNumber + 1,
                    items: state.items,
                })
            })
            .catch(error=>{    
            })
        } catch (e) { //code will jump here if there is a network problem
        }
    }
  /*    useEffect(() => { 
        fetchData();
     return () => {
      
    }

    },[]); */

    useEffect(() => { 
        if (countertwo===1) {
            fetchData();    
        }
        
     return () => {
      
    }

    },[countertwo]);

const updateCurrentPage=(updated_question,event)=>{    
    if (event.currentTarget.classList.contains("bs_bar") ) {
        event.currentTarget.classList.remove('bs_bar');
        event.currentTarget.classList.add('bs_bar-o');
    }
    
    setActiveQuestion(updated_question);        
    return null;
    }
       return (
        <>
         {loader ? <Loader />: ""}
        <div className="tab-pane fade " id="bookmark" role="tabpanel" aria-labelledby="bookmark-tab">
        <div className="q_book-list d-block practice_sr">

        <div
        id="scrollableDiv"
        style={{
            height: 550,
            overflow: 'auto',
            display: 'flex',
        }}
        >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
            dataLength={state.breweries.length}
            next={fetchData}
            //To put endMessage and loader to the top.
            //inverse={true} //
            hasMore={true}
            // loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
            endMessage=""
        >
            
         {state.breweries.map((brewery,key) => (
                      
          <React.Fragment key={key}>
         {(() => {
          
            if(brewery.is_skip===1) {
                return ( <div className="bs_bar-o" onClick={(event)=>updateCurrentPage(key,event)}>
                <div className="d-flex">
                    <div className="bs_c-wrap">
                        <div className="bs_c d-flex rounded-circle">
                            <span className="m-auto">{brewery.sr_number}</span>
                            
                            {(brewery.is_bookmark===1) ?
                            <label className="bookmark active">
                                <input className="bookmark__toogle" type="checkbox" />
                                <div className="bookmark__stroke"></div>
                                <div className="bookmark__fill"></div>
                            </label>:""}
                        </div>
                    </div>
                  
                    <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: brewery.question}}></p></MathJax>
                    </MathJaxContext>
                    
                </div>
            </div>)
            }else if(brewery.is_true===1){
            return ( <div className="bs_bar-r" onClick={(event)=>updateCurrentPage(key,event)}>
            <div className="d-flex">
                <div className="bs_c-wrap">
                    <div className="bs_c d-flex rounded-circle">
                        <span className="m-auto">{brewery.sr_number}</span>
                        {(brewery.is_bookmark===1)? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                    </div>
                </div>
              
                <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: brewery.question}}></p></MathJax>
                    </MathJaxContext>
                    
            </div>
           
        </div>)
            }else if(brewery.is_true===0){
            return ( <div className="bs_bar-w" onClick={(event)=>updateCurrentPage(key,event)}>
            <div className="d-flex">
                <div className="bs_c-wrap">
                    <div className="bs_c d-flex rounded-circle">
                        <span className="m-auto">{brewery.sr_number}</span>
                        {(brewery.is_bookmark===1)? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                    </div>
                </div>
              
                <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: brewery.question}}></p></MathJax>
                    </MathJaxContext>
                    
            </div>
           
        </div>)
            }else{
                return ( <div className="bs_bar" onClick={(event)=>updateCurrentPage(key,event)}>
                <div className="d-flex">
                    <div className="bs_c-wrap">
                        <div className="bs_c d-flex rounded-circle">
                            <span className="m-auto">{brewery.sr_number}</span>
                            {(brewery.is_bookmark===1) ? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                        </div>
                    </div>
                  
                    <MathJaxContext config={config} className="demo" >
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: brewery.question}}></p></MathJax>
                    </MathJaxContext>                 
                </div>
            </div>)
            }
        })()}              
                </React.Fragment>
                ))}

                </InfiniteScroll>
            </div>
        </div>
    </div>
    </>        
    );      
}
export default memo(TabQuestionList);