import React from 'react';
import {
  Navigate,
  Outlet
} from "react-router-dom";

export const IsGuest = () => {

       const _token=localStorage.getItem("_user_token");
        if (_token!=null) { 
          return <Navigate to="/" />;
        }else{
          return <Outlet />;
        }
}
 

export const IsUser = () => {
  
  const _token=localStorage.getItem("_user_token");
  if (_token===null) { 
    return <Navigate to="/login" />;
  }else{
    return <Outlet />;
  }

  
}
