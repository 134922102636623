import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import { NavLink,useNavigate } from 'react-router-dom';



export const TermsAndConditions= ()=>{
   
    return (
        <><main>
         <Header />
        
   

        <div className="container mt-5">
          <div className="y_exam-heading">
            <h3 className="m-0">Terms and Conditions</h3>
            </div>

            <div className="pp_details mb-5">
              <p>Welcome to Abhyas Karo!</p>
              <p>These terms and conditions outline the rules and regulations for using Abhyas Karo's Website, located at <NavLink to="https://abhyaskaro.com">www.abhyaskaro.com</NavLink>.</p>
              <p>We will presume that you agree to our terms and conditions by continuing to use this website. Do not continue to use Abhyas Karo if you do not agree to take all of the terms and conditions stated on this page.</p>
              <p>Throughout our Terms and Conditions and Privacy Policy and all other Agreements, the words "You and Your" indicate the client or the person using our website by agreeing to the terms and conditions. The use of the words “We, our, us,” indicates our company. Unless otherwise specified, all of the following terms and conditions shall be governed and construed in accordance with the laws of India in effect for the sole purpose of meeting the Client's demands concerning the company’s provision of stated services. All of the aforementioned terms can be used interchangeably, with no difference in meaning, whether they are used in capitalized, plural, singular, or all of them.</p>

              <h3>Cookies:</h3>
              <p>We employ the use of cookies. By accessing Abhyas Karo, you agreed to use cookies in agreement with Abhyas Karo's Privacy Policy.</p>
              <p>Cookies are used by the vast majority of interactive websites to remember user information between visits. Our website uses cookie technology to make usage of its features more convenient for visitors. Cookies may also be used by some of our advertising partners as well as affiliates.</p>

              <h3>License:</h3>
              <p>Unless otherwise stated, Abhyas Karo and/or its licensors own the intellectual property rights for all material on Abhyas Karo. All intellectual property rights are reserved. You may access this from Abhyas Karo for your own personal use subject to restrictions set in these terms and conditions.</p>

              <h3>You must not:</h3>
              <ul>
              <li>Republish material from Abhyas Karo</li>
              <li>Sell, rent, or sub-license material from Abhyas Karo</li>
              <li>Reproduce, duplicate, or copy material from Abhyas Karo</li>
              <li>Redistribute content from Abhyas Karo</li>
              </ul>
            </div>

          
         </div>


         <Footer />
          </main>
         
        
        </>);

}
export default TermsAndConditions;