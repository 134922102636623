import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import "../css/slick-theme.min.css";
import "../css/slick.min.css";
import Slider from "react-slick";



export const Testimonials= ()=>{

    var settings = {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 2000,
      };
   
    return (
        <><main>
         <Header />
        
        <div className="container">
        <div className="y_exam-heading mt-5">
            <h3 className="m-0">Testimonials</h3>
            </div>
        </div>

        <section id="students-review">
          <div class="review-wrapper mb-5">
              <div class="review-heading">
                  <h1>
                      Our Students<br />
                      Reviews
                  </h1>
                  <p>Able an hope of body. Any nay shyness article matters own removal nothing his forming.</p>
              </div>
              <div class="review-box">
              <Slider {...settings}>
              <div className="item">
                          <div className="review-slider">
                              <img alt="" className="img-fluid" src="/images/reviews/student1.png" />
                              <div className="review-details position-relative">
                                  <p className="">Able an hope of body. Any nay shyness article matters own removal nothing his forming.Able an hope of body. Any nay shyness article matters.</p>
                                  <div className="">
                                      <h3>Meeta Roi</h3>
                                      <p className="s-post">IT Engineer</p>
                                  </div>
                                  <img alt="" className="app-left_arrow" src="/images/component411.png" />
                              </div>
                          </div>
                         
                      </div>
                      
                       <div className="item">
                          <div className="review-slider">
                              <img alt="" className="img-fluid" src="/images/reviews/student1.png" />
                              <div className="review-details position-relative">
                                  <p className="">Able an hope of body. Any nay shyness article matters own removal nothing his forming.Able an hope of body. Any nay shyness article matters.</p>
                                  <div className="">
                                      <h3>Meeta Roi</h3>
                                      <p className="s-post">IT Engineer</p>
                                  </div>
                                  <img alt="" className="app-left_arrow" src="/images/component411.png" />
                              </div>
                          </div>
                         
                      </div>
                </Slider>
              </div>
          </div>
          <img alt="" class="br-shap" src="/images/shap/br-shap.png" />
</section>


         <Footer />
          </main>
         
        
        </>);

}
export default Testimonials;