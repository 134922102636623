import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Message} from './elements/Message';
import {Loader} from './elements/Loader';
import { APP_URL} from '../constants/global'
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { useNavigate,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
export const EditProfile= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [flashShow, setFlashShow] = useState("");
    let user_data=JSON.parse(localStorage.getItem('_user_data'));
    let api_token=localStorage.getItem('_user_token');
    const [profile_data, setProfileData] = useState({
        firstname: user_data.firstname,
        lastname: user_data.lastname,
        email: user_data.email,
        mobile: user_data.mobile,
      });
      
       
      const [image_data, setImageData] = useState({
        img: IMAGE_URL+user_data.image,
      });
     
      const handleChangeImage = e => {
        
        
       

        setImageData({img: URL.createObjectURL(e.target.files[0])})
        update_profile_image(e.target.files[0])
      }
      

      const changeHandler = (e) => {

        const name = e.target.name;
        const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

       
        
        setProfileData({
            ...profile_data,
            [name]: value
          })
      }


      function update_profile_image(image_obj) {
        
        const formData = new FormData();
        formData.append(
            "image",
             image_obj
        );
        formData.append(
            "is_remove",
             0
        );

        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
          }; 
          
         
       /* below code is use for pass headers global
       
       axios.interceptors.request.use((config) => {
            return config;
        }); */
        setLoader(true);
        axios.post(APP_URL+'update_profile_image',formData,{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
                user_data.image=response.data.image_path;
                localStorage.setItem('_user_data', JSON.stringify(user_data));
                //navigate("/select_goal");
            }else{
                setFlashShow(<Message variant="danger" children={response.data.message}/>);
            }
           
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            
            
        })

    }

      function update_profile() {
        
        
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
          }; 
          
         
       /* below code is use for pass headers global
       
       axios.interceptors.request.use((config) => {
            return config;
        }); */
        setLoader(true);
        axios.post(APP_URL+'update_profile',profile_data,{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
                localStorage.setItem('_user_data', JSON.stringify(response.data.data));
                setFlashShow(<Message variant="success" children={response.data.message}/>);
            }else{
                setFlashShow(<Message variant="danger" children={response.data.message}/>);
            }
           
            
        })
        .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);
            

            if (error.response.status===400) {
              
                const errors = {};
                if (error.response.data.message.email && error.response.data.message.email.length>0) {
                    errors.email = "email address already exist in system";    
                }
                if (error.response.data.message.mobile && error.response.data.message.mobile.length>0) {
                    errors.mobile = "mobile number already exist in system";    
                }
                
                setFormErrors(errors);
                
                
            }
            
        })
        window.scrollTo(0, 0);
    }

      const updateProfile = (e) => {
        e.preventDefault();

        if (validate(profile_data)===true) {
            update_profile();  
        }    
     }

  

      const validate = (values) => {
        const errors = {};
       
        if (!values.firstname) {
            errors.firstname = "firstname is required!";
        }
        if (!values.lastname) {
            errors.lastname = "lastname is required!";
        }
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!values.email) {
            errors.email = "email is required!";
        }else if(!regEmail.test(values.email)){
            errors.email = "email address is invalid";
        }

        if (!values.mobile) {
            errors.mobile = "mobile is required!";
        } else if (values.mobile.length!==10){
            errors.mobile = "mobile number should be 10 digit";
        }      
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };

    return (
        <><main>
         <Header />
         {loader ? <Loader />: ""}
         <div className="signup_wrap h-100 main_profile justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top">
                
                <div className="signup_body">

                {flashShow}
                         {/* Complete-profile-start */}

                         <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-xxl-5 my-xl-5 my-md-4 my-sm-0 my-0">
                            <h4>Edit Profile</h4>
                            <p>Fill-Up Your Personal Information</p>
                            <form onSubmit={updateProfile}>

                            <div className="page_baneer-inner justify-content-center user_l-desh d-xl-flex d-md-flex d-sm-block d-block">
                                    <div className="page_banner-user justify-content-center">
                                       <div className="user_profile-main">
                                       <div className="user_profile mb-4">
                                            <img alt="" className="img-fluid" src={image_data.img!=='https://abhyaskaro.com/backend/storage/null'?image_data.img:"/images/dashboard/user.png"} />
                                            
                                        </div>
                                        <div className="edit_p-img">
                                                
                                           
                                            <input type="file" id="img" name="img" accept="image/*" className="w-100" onChange={handleChangeImage}/>
                                            <i className="las la-edit"></i>
                                            </div>
                                       </div>
                                </div>
                                </div>

                                <div className="er_box">
                                <input type="text" name="firstname" placeholder="First Name" className="form-control border-0" onChange={changeHandler} value={profile_data.firstname ? profile_data.firstname : ""}/>
                                <span className="text-danger">{formErrors.firstname}</span>
                                </div>

                                <div className="er_box">
                                <input type="text" name="lastname" placeholder="Last Name" className="form-control border-0" onChange={changeHandler} value={profile_data.lastname ? profile_data.lastname : ""}/>
                                <span className="text-danger">{formErrors.lastname}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="email" placeholder="Email Address" className="form-control border-0" onChange={changeHandler} value={profile_data.email ? profile_data.email : ""}/>
                                <span className="text-danger">{formErrors.email}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="mobile" placeholder="Mobile Number" className="form-control border-0" onChange={changeHandler} value={profile_data.mobile ? profile_data.mobile : ""}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                <span className="text-danger">{formErrors.mobile}</span>
                                </div>
                                <div className="signup_bottom text-center my-4">
                                     <button className="btn" type="submit ">Update</button>
                                     <NavLink className="et_pro" to="/edit_goal">Edit Category</NavLink>
                                </div>
                            </form>
                        </div>

                         {/* Complete-profile-end */}

                      





                </div>
            </div>
            
        </div>
        <ProfileSideMenu />
         <Footer />
          </main>
         
        
        </>);

}
export default EditProfile;