import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Loader} from '../../elements/Loader';
export const Report= ({data,active_question,setData})=>{
    
     
     let current_data=data.data[active_question];
     let api_token=localStorage.getItem('_user_token');
     const [loader, setLoader] = useState(false);
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
     }; 
     const [selectedreport,setSelectedReport]=useState([]);
     const [show, setShow] = useState({status:false});
     const handleClose = () => setShow({status:false});

     const handleShow=(single_data)=>{
        let multiSelectElements = document.getElementsByClassName('yaminsaiyedmulti');
        for(let i=0; multiSelectElements[i]; ++i){
            if(multiSelectElements[i].classList.contains("given_ans")){
                multiSelectElements[i].classList.remove('given_ans');
                
            }
        }
        setShow({status:true})
    }
    const[textboxshow,setTextBoxShow]=useState(false);
   

    function select_report_message(message,event) {

        if (event.currentTarget.classList.contains("act_r")) {
            event.currentTarget.classList.remove('act_r');
        }else{
            event.currentTarget.classList.add('act_r');
        }
    let report_message=selectedreport;
    if(!report_message.includes(message)){          //checking weather array contain the id
        report_message.push(message);               //adding to array because value doesnt exists
    }else{
        report_message.splice(report_message.indexOf(message), 1);  //deleting
    }
    setSelectedReport(report_message);
        
    }

    function reportQuestion(event){

       
        setLoader(true);
        try {
            
            let report_message=selectedreport;
            
            let report_question_txt=document.getElementById("floatingTextarea2")?document.getElementById("floatingTextarea2").value:"";
            
          
            if (report_question_txt && report_question_txt.length>0) {
                report_message.push(report_question_txt);    
            }
           
             axios.post(APP_URL+"pyq_question_report",{question_id:current_data.id,message:report_message.join([','])},{headers})
            .then(response=>{
               
                if (response.data.status===true) {
                    setShow({status:false});
                    setSelectedReport([]);
                }else{
                    
                }
                setLoader(false);   
                
            })
            .catch(error=>{
                console.log(error);
                setLoader(false);
               
                
            })
    
           
        
      } catch (e) { //code will jump here if there is a network problem
        console.log(e);
        setLoader(false);  
       
    }
    
    }


       return (
        <>
        {loader ? <Loader />: ""}
        <Button className={current_data.is_report===1?"active":""}  onClick={()=>handleShow("")}><i className="las la-exclamation-triangle"></i></Button>


        <Modal show={show.status} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub p-0" >
              <Modal.Header closeButton>
                <Modal.Title><h3 className="m-0">Report this question !</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="report_data">
             
              <ul id="report_que_id">

           

                    <li className="rounded-pill" onClick={(event) => {select_report_message("Wrong Question",event)}}><span>Wrong Question</span><input type="text" /></li>
                    
                    <li className="rounded-pill" onClick={(event) => {select_report_message("Formatting issue",event)}}><span>Formatting issue</span><input type="text" /></li>
                    
                    <li className="rounded-pill"  onClick={(event) => {select_report_message("Que & options both not visible",event)}}><span>Que & options both not visible</span><input type="text" /></li>
                    
                    <li className="rounded-pill"  onClick={(event) => {select_report_message("Options visible, ques not visible",event)}}><span>Options visible, ques not visible</span><input type="text" /></li>
                    
                    <li className="rounded-pill"  onClick={(event) => {select_report_message("Out of syllabus",event)}}><span>Out of syllabus</span><input type="text" /></li>
                    
                    <li className="rounded-pill"  onClick={(event) => {select_report_message("Wrong translation",event)}}><span>Wrong translation</span><input type="text" /></li>
                   
                   <li onClick={()=>setTextBoxShow(!textboxshow)} className={textboxshow?"rounded-pill act_r":"rounded-pill"}><span>Other</span><input type="text" /></li>
                    {textboxshow?<li className="border-0 p-0">
                        <textarea className="" name="report_question_txt" placeholder="Leave a Message here" id="floatingTextarea2"></textarea>
                    </li>:null}
                    
                    
                        
                    
                </ul>
                <div className="d-flex justify-content-center">
                        <button type="button" className="rounded-pill report_btn" onClick={(event) => {reportQuestion(event)}} >Report</button>
                        <button type="button" className="rounded-pill cl_btn" onClick={handleClose}>Close</button>
                </div>
              
              </div>                    
              </Modal.Body>
            
              {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>
            
        </>    
        );
   
      
    }  
    

export default Report;