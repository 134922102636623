import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Message} from './elements/Message';
import {Loader} from './elements/Loader';
import { APP_URL, MAIN_URL } from '../constants/global'
import { useNavigate } from "react-router-dom";


export const Register= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [login_data, setLoginData] = useState({
        mobile: "",
        is_login:0
      });
      const [formErrors, setFormErrors] = useState({});
      const [flashShow, setFlashShow] = useState("");
      const changeHandler = (e) => {
        setLoginData({[e.target.name]: e.target.value,is_login:0});
     }

     function send_otp(){
        
       
        const headers = {
             "Content-Type": "application/json",
             //Authorization: "Bearer " + token
           };
        
         setLoader(true);
         axios.post(APP_URL+'send_otp',login_data,{headers})
         .then(response=>{
            setLoader(false);
            
             if (response.data.status===true) {
                navigate("/register_verify_otp",{ state: { mobile: login_data.mobile} });
                //navigate("/register_verify_otp",{ state: { id: 7, color: 'green' } });
           }else{
                 setFlashShow(<Message variant="danger" children={response.data.message}/>);
             }
             //localStorage.setItem('_token', response.data.token);
             //localStorage.setItem('_token', response.data.token);
             //localStorage.setItem('user', "Yamin Saiyed");
             //localStorage.removeItem('_token');
             
         })
         .catch(error=>{
            setLoader(false);
             if (error.response.status===422) {
                 setFlashShow(<Message variant="danger" children={error.response.data.message}/>);
                 
             }
         })
 
     }
     
     const sendOtp = (e) => {
         e.preventDefault();
         if (send_otp_validate(login_data)===true) {
            
             send_otp();
         }        
 
     }
     const send_otp_validate = (values) => {
        const errors = {};
       
        if (!values.mobile) {
            errors.mobile = "Please enter mobile number.";
            
        }else if (values.mobile.length!==10){
            errors.mobile = "Invalid mobile number";
        }
        
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };
    return (
        <><main>
         <Header />
         {loader ? <Loader />: ""}
         <div className="signup_wrap justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top">
                <div className="signup_progress">
                    <div className="progress_box done_box">
                        <span>1</span>
                    </div>
                    <div className="progress_box">
                        <span>2</span>
                    </div>
                    <div className="progress_box">
                        <span>3</span>
                    </div>
                    <div className="progress_box">
                        <span>4</span>
                    </div>
                </div>
                <div className="signup_body">
               

                         {/* Enter-Mobilenumber-start */}

                                <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-5">
                                    {flashShow}
                                    <h4>Enter Mobile Number</h4>
                                    <p>we'll send an OTP for verification</p>
                                    <form onSubmit={sendOtp}>
                                    <div className="er_box">
                                    <input type="text" name="mobile" placeholder="Mobile Number" className="form-control border-0" id="inputGroup" onChange={changeHandler} value={login_data.mobile} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                    <span className="text-danger">{formErrors.mobile}</span>
                                    </div>
                                        <p className="or  text-center ">OR</p>
                                        <a href={MAIN_URL+"auth/google"} className="login-with-google-btn">
                                      
                                            Sign in with Google
                                        </a>

                                        <div className="signup_bottom text-center my-4">
                                        <button className="btn" type="submit ">submit</button>
                                        </div>

                                    </form>
                                </div>

                         {/* Enter-Mobilenumber-end */}

                                              

                </div>
            </div>
            
        </div>

         <Footer />
          </main>
         
        
        </>);

}
export default Register;