import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';

import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {finalDateFormat} from './elements/AppHelper';

import { APP_URL} from '../constants/global';
import { useNavigate } from 'react-router-dom';
import { MAIN_URL } from '../constants/global';
export const OrderList= ()=>{
   
   const [data, setData] = useState({
      status:false,
      message:"",
      data:null,
    }); 
    const navigate = useNavigate();
    let api_token=localStorage.getItem('_user_token');
   useEffect(() => { 
     
      
      try {
       const headers = {
           "Content-Type": "application/json",
           Authorization: "Bearer " + api_token
           }; 
          
     
          axios.get(APP_URL+'get_order_history',{headers},[])
          .then(response=>{
          
           
           setData({
               status: response.data.status,
               message: response.data.message,
               data: response.data.data,
             });
            
             
           
            
          })
          .catch(error=>{
            if (error.response.status===401) {
              localStorage.clear();
              navigate("/login");   
            }

           
           setData({
               status: false,
               message: "",
               data: null,
             });
          
          })
 
  
        } catch (e) { //code will jump here if there is a network problem
      
       setData({
           status: false,
           message: "",
           data: null,
         });
      
   }

      return () => {
           
       }

     },[]);
   
       return (
        
            <>
            <main>
             <Header />
                  {/* Inner _age Banner End */}
                  <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                        <img alt="" className="img-fluid" src="/images/pyq/label.svg" />
                                 <div className="d-flex">
                                    <h1 className="text-capitalize">My Order</h1>
                                 </div>
                                    </div>
                                </div>
                                <div className="page_overlay"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--My-Order-start----*/}
            <div className="my_order-wrapper">
                <div className="my_order">
                    <h2 className="co_heading text-start">Your Previous Order</h2>
                    <div className="sub_card-wrapper  d-block">
                    <div className="sub_card_w-inner list_sc-wrapper">
                    { data.status===true && data.data.map((result,key) => ( 

                        <div className="sub_card mb-0 align-items-center" key={key}>
                            <div className="sub_card-l position-relative  align-items-center w-auto">
                                <img alt="" className=""  src="/images/plan-card/electronic.png" />
                               <div>
                                <h4 className="m-0">#{result.id}</h4>
                               </div>
                               <img alt="" className="gear_sub" src="/images/plan-card/bg-gear.png" />
                            </div>
                           
                           <div className="dow_invoice-wrapper w-auto">
                            <div className="sub_card-r ">
                                <p>₹ {(result.discount>0 && parseFloat(result.sub_total-result.total_amount).toFixed(2)===parseFloat(result.discount).toFixed(2))?"0.00":result.total_amount.toFixed(2)}/-</p>
                          
                                <p>{finalDateFormat(result.created_at)}</p>
                            </div>
                          <div className="dow_invoice">
                        <button className="btn" type="">
                            <img alt="" className="" src="/images/order/cloud-download.png" />
                            <a href={MAIN_URL+"api/generate_pdf/"+result.order_id_enc}>Download<br/> Invoice</a></button>
                    </div>
                           </div>
                       
                        </div>
                    ))}
                    </div>




      
                    </div>
                </div>
            </div>

            {/*-----My-Order-end--*/}


            
  
            <ProfileSideMenu />

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default OrderList;