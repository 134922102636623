import React, { useState}from "react";
import OptionSingle from "./OptionSingle";
import OptionMulti from "./OptionMulti";
import OptionNumeric from "./OptionNumeric";
export const QuestionOption= ({data,active_question,config,setActiveQuestion,setData})=>{
    
      const option_array = {
        0:"A",
        1:"B",
        2:"C",
        3:"D",
      }
      

   

        
    if (data.data[active_question].type===1) {
        
        return (<OptionSingle data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData}/>);

    }else if (data.data[active_question].type===2){

        return (<OptionMulti data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData}/>);
    
    }else if (data.data[active_question].type===3){
        
        return (<OptionNumeric data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData}/>);
    }
 
 
 
      
    }  
    

export default QuestionOption;