import React,{useState} from "react";
import TabQuestionNumbers from "./TabQuestionNumbers";
import TabQuestionList from "./TabQuestionList";
import FinalSubmit from "./FinalSubmit";
export const RightSideBar= ({data,active_question,config,setActiveQuestion,setData,pageDefault,technical_aptitude_question_type,navigate})=>{
    
   
  const [countertwo,setcountertwo]=useState(0)
  const Yns=()=>{
   setcountertwo(1);
  }
    
   
return (<div className="qm_boookmark">
<div className="">
<ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item " role="presentation">
      <button className="nav-link active w-100" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions" type="button" role="tab" aria-controls="questions" aria-selected="true">questions</button>
    </li>
    <li className="nav-item" role="presentation">
      <button className="nav-link w-100" id="bookmark-tab" data-bs-toggle="tab" data-bs-target="#bookmark" type="button" role="tab" aria-controls="bookmark" aria-selected="false" onClick={Yns}>All Question</button>
    </li>
    
  </ul>



        
  <div className="tab-content" id="myTabContent">
    <TabQuestionNumbers  data={data} active_question={active_question} setActiveQuestion={setActiveQuestion} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
    <TabQuestionList  data={data} active_question={active_question} setActiveQuestion={setActiveQuestion} config={config} technical_aptitude_question_type={technical_aptitude_question_type} setData={setData} pageDefault={pageDefault} countertwo={countertwo}/>
    
  </div>
</div>

<FinalSubmit  data={data} active_question={active_question} setActiveQuestion={setActiveQuestion} setData={setData} pageDefault={pageDefault} navigate={navigate}/>


  
</div>);
   
      
    }  
    

export default RightSideBar;