import React, { useState ,useEffect}from "react";
import axios from '../../general/axios.js';
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import {CounterTimeNew} from '../../elements/CounterTimeNew';
import {BookMark} from './BookMark';
import {Report} from './Report';
import {RightSideBar} from './RightSideBar';
import QuestionOption from "./QuestionOption";
export const GetQuestionList= ({setLoader,pageDefault})=>{
    
    
    let api_token=localStorage.getItem('_user_token');
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + api_token
        }; 
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
        }); 

    
    const [active_question, setActiveQuestion] = useState(0);
    
    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
          packages: { "[+]": ["html"] },
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
          ]
        }
      };
      const option_array = {
        0:"A",
        1:"B",
        2:"C",
        3:"D",
      }
      
    useEffect(() => { 
       /*  document.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        }); */
        
     try {
         setLoader(true);
         axios.get(APP_URL+'get_practice_question',{headers,params:{topic_id:pageDefault.topic_id}},[])
         .then(response=>{
          setData({
              status: response.data.status,
              message: response.data.message,
              data: response.data.data,
            });
            setLoader(false);             
           
         })
         .catch(error=>{
          setData({
              status: false,
              message: "",
              data: null,
            });
            setLoader(false);             
        
         })

 
       } catch (e) { //code will jump here if there is a network problem
      setData({
          status: false,
          message: "",
          data: null,
        });
        setLoader(false);             
      
  }

     return () => {
      
      }

    },[]);

    
if (data.status===true) {
    
  
return (
<>
<div className="qm_board-wrap no-select" >
            <div className="qm_cb-wrap d-flex justify-content-between">
            </div>

<div className="qm_board">
              
<div className="qm_bar d-flex mt-1  ">
<div className="qm_n-wrap me-2">
    <div className="qm_n rounded-circle d-flex">
        <span className="m-auto ">{data.data[active_question].sr_number}</span>
    </div>
</div>
<MathJaxContext config={config} className="demo ">
    <MathJax dynamic={true}><p dangerouslySetInnerHTML={{__html: data.data[active_question].question}}></p></MathJax>
</MathJaxContext>


{/* <p dangerouslySetInnerHTML={{__html: data.data[active_question].question}}></p> */}
</div>

<div className="count-data-b d-flex align-items-center justify-content-end">


<div className="qm_ts me-2 d-flex justify-content-between align-items-center">
<div className="qm_solve d-flex">


<div className="qm_ts-dep rounded-pill">
<i className="las la-check green"></i>
<p className="green m-auto"> <b>{data.data[active_question].get_practice_answer!=null?Math.ceil(data.data[active_question].get_practice_answer.total_solved_correct*100/data.data[active_question].get_practice_answer.total_solved):0} </b> %</p>
</div>
<div className="qm_ts-pep rounded-pill ">
<i className="las la-user-circle blue"></i>
<p className="blue m-auto"> <b>{data.data[active_question].get_practice_answer!=null?data.data[active_question].get_practice_answer.total_solved:0} </b></p>
</div>
</div>
{data.data[active_question].user_selected_answer === null?<CounterTimeNew taken_time={data.data[active_question].taken_time}/>:""}


</div>
<div className="qm_b-icon">

<BookMark data={data} active_question={active_question} setData={setData}/>
<Report data={data} active_question={active_question} setData={setData}/>

</div>


</div>

 <QuestionOption data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData}/>

                  
 </div>


               
            
            </div>


 <RightSideBar data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData} pageDefault={pageDefault}/>




    </>
    );

}    
 
      
    }  
    

export default GetQuestionList;