import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import { APP_URL} from '../constants/global'
import { useNavigate } from 'react-router-dom';

export const CompleteProfile= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    let user_data=JSON.parse(localStorage.getItem('_user_data'));
    let api_token=localStorage.getItem('_user_token');
    const [profile_data, setProfileData] = useState({
        firstname: user_data.firstname,
        lastname: user_data.lastname,
        email: user_data.email,
        mobile: user_data.mobile,
      });
      
       
      const changeHandler = (e) => {

        const name = e.target.name;
        const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

       
        
        setProfileData({
            ...profile_data,
            [name]: value
          })
      }


      

      function update_profile() {
        
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
          }; 
          
         
       /* below code is use for pass headers global
       
       axios.interceptors.request.use((config) => {
            return config;
        }); */
        setLoader(true);
        axios.post(APP_URL+'update_profile',profile_data,{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
                localStorage.setItem('_user_data', JSON.stringify(response.data.data));
                navigate("/select_goal");
            }else{
               
            }
           
            
        })
        .catch(error=>{
            setLoader(false);
           
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }

            if (error.response.status===400) {
               
                const errors = {};
                if (error.response.data.message.email && error.response.data.message.email.length>0) {
                    errors.email = "email address already exist in system";    
                }
                if (error.response.data.message.mobile && error.response.data.message.mobile.length>0) {
                    errors.mobile = "mobile number already exist in system";    
                }
                
                setFormErrors(errors);
                
                
            }
            
        })

    }

      const updateProfile = (e) => {
        e.preventDefault();

        if (validate(profile_data)===true) {
            update_profile();  
        }    
     }

      const validate = (values) => {
        const errors = {};
       
        if (!values.firstname) {
            errors.firstname = "firstname is required!";
        }
        if (!values.lastname) {
            errors.lastname = "lastname is required!";
        }
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!values.email) {
            errors.email = "email is required!";
        }else if(!regEmail.test(values.email)){
            errors.email = "email address is invalid";
        }

        if (!values.mobile) {
            errors.mobile = "mobile is required!";
        } else if (values.mobile.length!==10){
            errors.mobile = "mobile number should be 10 digit";
        }      
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };

    return (
        <><main>
         <Header />
         {loader ? <Loader />: ""}
         <div className="signup_wrap justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top">
                <div className="signup_progress">
                    <div className="progress_box done_box">
                        <span>1</span>
                    </div>
                    <div className="progress_box done_box">
                        <span>2</span>
                    </div>
                    <div className="progress_box done_box">
                        <span>3</span>
                    </div>
                    <div className="progress_box">
                        <span>4</span>
                    </div>
                </div>
                <div className="signup_body">


                         {/* Complete-profile-start */}

                         <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-5">
                            <h4>Complete Profile</h4>
                            <p>Fill-Up Your Personal Information</p>
                            <form onSubmit={updateProfile}>
                                                                
                                <input type="text" name="firstname" placeholder="First Name" className="form-control border-0" onChange={changeHandler} value={profile_data.firstname ? profile_data.firstname : ""}/>
                                <span className="text-danger">{formErrors.firstname}</span>

                                <input type="text" name="lastname" placeholder="Last Name" className="form-control border-0" onChange={changeHandler} value={profile_data.lastname ? profile_data.lastname : ""}/>
                                <span className="text-danger">{formErrors.lastname}</span>

                                <input type="text" name="email" placeholder="Email Address" className="form-control border-0" onChange={changeHandler} value={profile_data.email ? profile_data.email : ""}/>
                                <span className="text-danger">{formErrors.email}</span>

                                <input type="text" name="mobile" placeholder="Mobile Number" className="form-control border-0" onChange={changeHandler} value={profile_data.mobile ? profile_data.mobile : ""}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                <span className="text-danger">{formErrors.mobile}</span>

                                <div className="signup_bottom text-center my-4">
                                     <button className="btn" type="submit ">Next</button>
                                </div>
                            </form>
                        </div>

                         {/* Complete-profile-end */}

                      





                </div>
            </div>
            
        </div>

         <Footer />
          </main>
         
        
        </>);

}
export default CompleteProfile;