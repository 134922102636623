import React from "react";

export const Loader= ()=>{   
      return (
            <div  className="page-section">
                  <div className="loder_img m-auto">
                        <img  src="/images/loading-gif.gif" alt="loading.." />
                  </div>
            </div>     
      );
}
