import React from "react";

export const RatingStar= ({rating_value})=>{
    const items = [];
let i=0;
let j=rating_value;
 for (i; i < rating_value; i++){
    items.push( <i className="las la-star" key={i}></i>);
 }
for (j; j < 5; j++){
    items.push( <i className="lar la-star"  key={j}></i>);
}

return (
    <div className="d-flex">
    {items}
    </div>
    );


/* return (
    <i className="las la-star" key={index}></i>
); */

}
