import React, {useEffect,useState} from "react";
import {secondsToHms} from './AppHelper';
import axios from '../general/axios.js';
import { APP_URL } from '../../constants/global';
import { useNavigate } from 'react-router-dom';
export const TestCounterTime= (props)=>{
  
    const [seconds, setSeconds] = useState(props.remaining_time);
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    useEffect(() => {
    
    
      
      const incrementCount = () => {
       
        setSeconds(parseInt(seconds) - 1);
       
       if (seconds<=0) {
        
        try {
        
          
          const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
          }; 
      
        
     
          axios.get(APP_URL+'add_final_test_answer',{headers,params:{test_id:props.test_id}},[])
          .then(response=>{
           
           
           setTimeout(() => {
           
            navigate("/test_score_card",{ state: { test_id: props.test_id,topic_name: props.topic_name,test_name: props.test_name,sub_category_image:props.sub_category_image} });    
        }, 5000); //5 seconds

           
         
             
           
            
          })
          .catch(error=>{
          
           
           //console.log(error);
          })
 
  
        } catch (e) { //code will jump here if there is a network problem
      
       
       //console.log(e);
   }

        
       }
        
        localStorage.setItem('test_seconds', seconds);
        localStorage.setItem('test_display_time',secondsToHms(seconds));  
      /*   if (isNaturalNumber(seconds/5)===true) {
          localStorage.setItem('seconds', seconds);
          localStorage.setItem('display_time',secondsToHms(seconds));  
        } */
        
      };
      const timer = setTimeout(() => incrementCount(), 1000);
      return () => clearTimeout(timer);
    }, [seconds]);

    return (
      <div className="qm_timer rounded-pill d-flex">
         <i className="las la-stopwatch text-white"></i>
         <span className="text-white">{secondsToHms(seconds)}</span>
     </div>

);


}