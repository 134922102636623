import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Message} from './elements/Message';
import {Loader} from './elements/Loader';
import {SelectGoalCategoryList} from './SelectGoalCategoryList';
import { APP_URL,IMAGE_URL } from '../constants/global'
import { useNavigate } from 'react-router-dom';
export const SelectGoal= ()=>{
    const navigate = useNavigate();
    const [flashShow, setFlashShow] = useState("");
    const [loader, setLoader] = useState(false);
    let api_token=localStorage.getItem('_user_token');
    const [categoryData, setcategoryData] = useState({
        status:false,
        data: null
      }); //useState default
    const [value, setValue] = useState('')
    const [selectedcategory,setSelectedCategory]=useState([]);
    
      function get_category(){

        try {
            
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);

        //    axios.get(APP_URL+'get_category',{headers,params: {
        //     foo: 'bar'
        //   }},[])
           axios.get(APP_URL+'get_category',{headers},[])
           .then(response=>{
            setLoader(false);
            setcategoryData({
                status: response.data.status,
                data: response.data.data,
              });
            
           })
           .catch(error=>{

            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            
            setLoader(false);  
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        //console.log(e);
    }
     }
     
      useEffect(() => { get_category(); },[]);
      
     
      
      const handleAdd=(category)=>{
        //const abc=[...selectedcategory,[category]]
        const abc=[...selectedcategory,category]
        
        setSelectedCategory(abc)
        let category_data=categoryData.data;
        
        //const result2 = cert1.filter(cer => abc.find(abb => abb.category_id != cer.category_id));
        
          category_data = category_data.filter(function(val) {
            return abc.indexOf(val) === -1;
          });
          
          setcategoryData({
            status: true,
            data: category_data,
          });
          setFlashShow("");
       
    }

        const handleDelete=(i,data)=>{
       
        const deletVal=[...selectedcategory]
        deletVal.splice(i,1)
        setSelectedCategory(deletVal)  

        // Add item to it
        let category_data=categoryData.data;

        category_data.push(data);

        setcategoryData({
            status: true,
            data: category_data,
          });
    }


    function AddUserCategory(){
        
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
          }; 
          
        let category_ids=[];
        selectedcategory.map(function(selectedcategory){
            category_ids.push(selectedcategory.category_id);
        })
        let category_id_comma=category_ids.join(',');
        if (category_id_comma.length<=0) {
            setFlashShow(<Message variant="danger" children="Select atleast 1 goal"/>);
            return null;
        }
        setLoader(true);
        axios.post(APP_URL+'add_user_category',{category_id:category_id_comma},{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
                navigate("/dashboard");
            }else{
                setFlashShow(<Message variant="danger" children={response.data.message}/>);
            }
           
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {

                setFlashShow(<Message variant="danger" children={error.response.data.message}/>);
                
            }
            
        })

    }


     
    return (
      
        <><main>
         <Header />
         
         {loader ? <Loader />: ""}

         <div className="signup_wrap h-100 justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top edit_goal-wrap">
                <div className="signup_progress">
                    <div className="progress_box done_box">
                        <span>1</span>
                    </div>
                    <div className="progress_box done_box">
                        <span>2</span>
                    </div>
                    <div className="progress_box done_box">
                        <span>3</span>
                    </div>
                    <div className="progress_box done_box">
                        <span>4</span>
                    </div>
                </div>
                <div className="signup_body">

                        {/* Complete-profile-end */}

                         <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-5">
                            <h4>Select Your Goal</h4>
                           
                            <form>
                                <div className="searchbar position-relative">
                                    <input type="text" placeholder="Search goal by name" className="form-control border-0" value={value} onChange={e => setValue(e.target.value)} />
                                    <i className="las la-search"></i>
                                </div>
                            </form>
                          
                            <div className=" mt-5">
                                
                                <div className="all-exam">
                               

                                {selectedcategory.map((data,i)=>{
                                    
                                return(
                                    <div className="exam-box select" key={i}>
                                        <img alt="" className="img-fluid" src={data.image?IMAGE_URL+data.image:"/images/right-fy/books.png"} />
                                        <p className="">{data.category_name}</p>
                                        <button onClick={()=>handleDelete(i,data)}>X</button>
                                    </div>
                                    )
                                })}

                                   
                                  
                                   
                                    
                                </div>
                            </div>
                            {flashShow}
                            <div className="exam_catagory">
                       
                                <ul>
                                  {/* <button onClick={()=>handleAdd()}>Add</button> */}

                                  { categoryData.status===true && categoryData.data.filter(category => {
                                    if (!value) return true
                                    if (category.category_name.toLowerCase().includes(value.toLowerCase())) {
                                        return true
                                    }
                                    }).map((category,key) => (
                                        
                                        <SelectGoalCategoryList category={category} key={key} onClickBtn={()=>handleAdd(category)}/>

                
                                  ))}

                                  
                                   
                                </ul>
                
                               
                            </div>
                        </div>

                          {/* Complete-profile-end */}





                </div>
            </div>
            <div className="signup_bottom text-center my-4">
               <button className="btn  " type="button" onClick={AddUserCategory}>Next</button>
            </div>
        </div>

         <Footer />
          </main>
         
        
        </>);

}
export default SelectGoal;