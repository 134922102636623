import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {secondsToHms} from './elements/AppHelper';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation} from 'react-router-dom';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const TestSolution= ()=>{
   

    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [technical_aptitude_question_type, setTechnicalAptitudeQuestionType] = useState(2);
    const [all_total_question, setTotalQuestion] = useState({
        total_question: 0,
        technical_total: 0,
        general_total: 0,
      });
    const location = useLocation();
    const [filter_state, setFilterState] = useState("all");
   
    const [show, setShow] = useState({status:false});
    const handleClose = () => setShow({status:false});
    const handleShow=(single_data)=>{
            
        setShow({status:true})
    }

   const [active_question, setActiveQuestion] = useState(1);
    
    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
          packages: { "[+]": ["html"] },
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
          ]
        }
      };
      const option_array = {
        0:"A",
        1:"B",
        2:"C",
        3:"D",
        4:"E",
        5:"F",
        6:"G",
      }
    let pageDefault=location.state;

      
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + api_token
        }; 
      const[textboxshow,setTextBoxShow]=useState(true);
      
      useEffect(() => { 
        
      
       try {
        
           setLoader(true);
      
           axios.get(APP_URL+'get_final_test_question',{headers,params:{test_id:pageDefault.test_id}},[])
           .then(response=>{
            setLoader(false);
            setData({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
              });
             
              let technical_total=0;
              let general_total=0;

              response.data.data.map(function(child_data,key){
               
                if (child_data.question_type===1) {
                    technical_total++;
                }else{
                    general_total++;
                }

            
                
             
           })
           setTotalQuestion({
            total_question: technical_total+general_total,
            technical_total: technical_total,
            general_total: general_total,
          });

          
            
             
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
          
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
        
    }

       return () => {
        
        }

      },[pageDefault]);
      

      const updateCurrentPage=(updated_question)=>{
       
        
        if (updated_question>=1) {
            window.scrollTo(0, 0);
            
            let total_current_type_question=0;
            if (technical_aptitude_question_type===1) {
                total_current_type_question=all_total_question.technical_total;
            }else{
                total_current_type_question=all_total_question.general_total;
            }

            if (updated_question<=total_current_type_question) {
                setActiveQuestion(updated_question);    
              
            }else{
                setActiveQuestion(1);    
            
            }
            
            
            
           /*  setTime("00:00:00");
            setSeconds(0); */
            
          
        }

        return null;
        
      }
     
    
      function change_general_aptitude_technical(question_type) {
        setTechnicalAptitudeQuestionType(question_type);
        setActiveQuestion(1);
       
      }



    return (
        
            <>
            <main>
             <Header />
             {loader ? <Loader />: ""}
             {/* Inner _age Banner End */}
        <div className="page_banner-wrapper">
            <div className="page_baneer">
                <div className="p_yq">
                    <div className="p_yq-left d-flex">
                        <img alt="" className="img-fluid" src="/images/pyq/pyq-bg.svg" />
                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                            <span className="ae_heading text-start">{pageDefault.topic_name}</span>

                            <h3 className="text-uppercase text-left fw-normal"><span> {pageDefault.test_name}</span></h3>
                        </div>
                    </div>
                    <div className="p_yq-right justify-content-end">
                        <button className="btn text-white text-end">Test</button>
                    </div>
                </div>
                <div className="page_overlay"></div>
            </div>
        </div>
        {/* Inner _age Banner End */}

        {/*--Subject-wise-mcq-sec-start----*/}

        <div className="sw_a-wrap">


            <div className="sw_a-header d-flex justify-content-between">
                <div className="sw_h-left d-flex">
                    <button className={technical_aptitude_question_type===2?"btn rounded-pill active":"btn rounded-pill"} onClick={(event) => {change_general_aptitude_technical(2)}}>General Aptitude</button>
                    <button className={technical_aptitude_question_type===1?"btn rounded-pill active":"btn rounded-pill"} onClick={(event) => {change_general_aptitude_technical(1)}}>Technical</button>
                    
                   
                </div>
               
            </div>
            
            
                <div className="qm_wrapper no-select li_test m-0  " >
                <div className="qm_board-wrap">
                    <div className="qm_board position-relative ">

                    { data.status===true && data.data/* .filter(data_filter => {
                                    if (data_filter.question_type===technical_aptitude_question_type) {
                                        return true
                                    }
                                    }) */.map((result,key) => (

                        
                        <div key={key} style={{ display: result.sr_number===active_question && result.question_type===technical_aptitude_question_type? "block" : "none" }}>

                            <div className="sw_h-right d-flex">
                                <div className="r_marks me-3">
                                    Marks : <span className="r_up green  me-2">+{result.positive_mark}</span><span className="r_down orange">-{result.negetive_mark}</span>
                                </div>
                              
                            </div>


                        <div className="qm_bar d-flex mt-3">
                            <div className="qm_n-wrap me-2">
                                <div className="qm_n rounded-circle d-flex">
                                    <span className="m-auto">{result.sr_number}</span>
                                </div>
                            </div>
                          
                            <MathJaxContext config={config} className="demo ">
                                <MathJax dynamic={true}><p dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                            </MathJaxContext>
                            
                            <div className="qm_b-icon d-flex">

                            <div className={result.is_bookmark===1?"bookmark active":"bookmark"}>
                                <input className="bookmark__toogle" type="checkbox"/>
                                <div className="bookmark__stroke"></div>
                                <div className="bookmark__fill"></div>
                            </div>
                            
                            <Button className={result.is_report===1?"active":""}  onClick={()=>handleShow("")}><i className="las la-exclamation-triangle"></i></Button> 


                            </div>
                        </div>
                        <div className=" mcq-solution">
                            
                            
                        {result.type===1?<div className="an_b-wrap pyqs_wrap mcq-solution">
                    { result.get_option.map((option,option_key) => ( 
                        <React.Fragment  key={option_key}>
                       {(() => {
                        
                        /* if (result.user_selected_answer==="[]") {
                            result.user_selected_answer=null;
                        } */
                     
                        
                        if (result.user_selected_answer && result.user_selected_answer.length>0) {
                            
                            if (option.is_true===1) {
                                return (
                            
                                    <div className="d-flex justify-content-between an_b-n right_ans">
        
                                    <div className="an_bl d-flex">
                                        <div className="an_count me-3 d-flex rounded-circle ">
                                            <span className="m-auto">{option_array[option_key]}</span>
                                        </div>
                                        <div className="an_dummy d-flex">
                                        <MathJaxContext config={config} className="demo">
                                             <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                        </MathJaxContext>
        
                                            
                                        </div>

                                        {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                            <span>Your Answer</span>
                                        </div>:""}


                                    </div>
                                  
                                </div>
                                )
                            
                            }else{
                               
                                 return (
                                <div className={(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n wrong_ans":"d-flex justify-content-between an_b-n"}>
        
                                    <div className="an_bl d-flex">
                                        <div className="an_count me-3 d-flex rounded-circle ">
                                            <span className="m-auto">{option_array[option_key]}</span>
                                        </div>
                                        <div className="an_dummy d-flex">
                                        <MathJaxContext config={config} className="demo">
                                             <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                        </MathJaxContext>
        
                                            
                                        </div>
                                        {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                            <span>Your Answer</span>
                                        </div>:""}

                                    </div>
                                  
                                </div>
                                )
                            }
                        
                        }else {
                        return (
                            <div className={result.user_selected_answer && (JSON.parse(result.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n right_ans":"d-flex justify-content-between an_b-n"}>
                             <div className="an_bl d-flex">
                                <div className="an_count me-3 d-flex rounded-circle ">
                                    <span className="m-auto">{option_array[option_key]}</span>
                                </div>
                                <div className="an_dummy d-flex">
                                <MathJaxContext config={config} className="demo">
                                     <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                </MathJaxContext>

                                    
                                </div>
                            </div>
                          
                        </div>
                        )
                        }
                    })()}
                       
                       </React.Fragment>
                    ))}
                        
                    </div>:""}

                    {result.type===2?<div className="an_b-wrap pyqs_wrap mcq-solution">
                    { result.get_option.map((option,option_key) => ( 
                       
                       <React.Fragment  key={option_key}>
                       {(() => {
                        if (result.user_selected_answer && result.user_selected_answer.length>0) {
                        
                            if (option.is_true===1) {
                                return (
                            
                                    <div className="d-flex justify-content-between an_b-n right_ans">
        
                                    <div className="an_bl d-flex">
                                        <div className="an_count me-3 d-flex rounded-circle ">
                                            <span className="m-auto">{option_array[option_key]}</span>
                                        </div>
                                        <div className="an_dummy d-flex">
                                        <MathJaxContext config={config} className="demo">
                                             <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                        </MathJaxContext>
        
                                            
                                        </div>
                                        {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                            <span>Your Answer</span>
                                        </div>:""}
                                    </div>
                                  
                                </div>
                                )
                            
                            }else{
                               
                                 return (
                                <div className={(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n wrong_ans":"d-flex justify-content-between an_b-n"}>
        
                                    <div className="an_bl d-flex">
                                        <div className="an_count me-3 d-flex rounded-circle ">
                                            <span className="m-auto">{option_array[option_key]}</span>
                                        </div>
                                        <div className="an_dummy d-flex">
                                        <MathJaxContext config={config} className="demo">
                                             <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                        </MathJaxContext>
        
                                            
                                        </div>
                                        {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                            <span>Your Answer</span>
                                        </div>:""}
                                    </div>
                                  
                                </div>
                                )
                            }
                        
                        }else {
                        return (
                            <div className="d-flex justify-content-between an_b-n">
                             <div className="an_bl d-flex">
                                <div className="an_count me-3 d-flex rounded-circle ">
                                    <span className="m-auto">{option_array[option_key]}</span>
                                </div>
                                <div className="an_dummy d-flex">
                                <MathJaxContext config={config} className="demo">
                                     <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                </MathJaxContext>

                                    
                                </div>
                            </div>
                          
                        </div>
                        )
                        }
                    })()}
                       
                       </React.Fragment>
                    ))}
                        
                    </div>:""}

                    {result.type===3?<div className="an_b-wrap">
                            {/* nat-solution.html */}
                            { result.get_option.map((option,option_key) => ( 
                       
                       <React.Fragment  key={option_key}>
                       {(() => {
                        if (result.user_selected_answer && result.user_selected_answer.length>0) {
                            
                            let user_selected_answer_val=JSON.parse(result.user_selected_answer);

                            if (result.is_true===1) {

                            
                              
                                return (
                                    <div className="an_b-wrap nat-solution">
                                   
                                        <div className="nat_sol-bar nat_s-r my-1">
                                            <div className="mb-2 d-flex">
                                                <span className="green fw-bold">Your Answer Is Correct</span>
                                            </div>
                                            <h1 className="text-dark m-0">{user_selected_answer_val[0]}</h1>
                                        </div>   
                                    
                                    
                                    </div>
                                )
                            
                            }else{
                              
                                 return (

                                    <div className="an_b-wrap nat-solution">
                                        <div className="nat_sol-bar nat_s-w my-1">
                                            <div className="mb-2 d-flex">
                                                <span className="red fw-bold">Your Answer</span>
                                            </div>
                                            <h1 className="text-dark m-0 ">{user_selected_answer_val[0]}</h1>
                                        </div>
                                        <div className="nat_sol-bar nat_s-r my-1">
                                            <div className="mb-2 d-flex">
                                                <span className="green fw-bold">Correct Answer</span>
                                            </div>
                                            <h1 className="text-dark m-0">Between {option.option_min} to {option.option_max}</h1>
                                            </div>   
                                        
                                        
                                        </div>

                                   
                                )
                            }
                        
                        }else {
                        return (
                            <div className="nat_ans"> 
                                    <label>Your Answer</label>
                                    <input type="text" name="answer" className="form-control " placeholder="Your Answer" onKeyPress={(event) => {if (!/^[\d]*\.?[\d]{0,2}$/.test(event.key)) {event.preventDefault();}}}/>
                                    </div>
                        )
                        }
                    })()}
                       
                       </React.Fragment>
                    ))}

                            

                               
                           
                        </div>:""}
                            
                        </div>
                        {(result.user_selected_answer && result.user_selected_answer.length>0)?<><div className="ay_time-wrap d-flex justify-content-center">
                            <button className="btn d-flex yt-btn mx-2">
                                <img alt="" className="img-fluid" src="/images/avg-time1.png" />
                                <div className="ay_time d-grid">
                                    <span>Your Time</span>
                                    <span>{secondsToHms(result.taken_time)}</span>
                                </div>
                            </button>
                            <button className="btn d-flex at-btn mx-2">
                                <img alt="" className="img-fluid" src="/images/avg-time2.png" />
                                <div className="ay_time d-grid">
                                    <span>Avg.Time</span>
                                    <span>{secondsToHms(result.get_test_answer?result.get_test_answer.average_time:0)}</span>
                                </div>
                            </button>

                            <button className="btn d-flex at-btn mx-2">
                                <img alt="" className="img-fluid" src="/images/video.png" />
                                <div className="ay_time d-grid">
                                    <span>Video <br />Link</span>
                                </div>
                            </button>


                            
                          </div>
                         
                        

                        <div className="com_sol-box">
                            <h3 className="blue">Solution</h3>
                            <div className="com_sol-box-inner">
                            <MathJaxContext config={config} className="demo">
                                     <MathJax dynamic={true}><p className="gray" dangerouslySetInnerHTML={{__html: result.solution}}></p></MathJax>
                                </MathJaxContext>
                                
                            </div>
                        </div></>:""}

                       
                        </div>
                        ))}
                         

                    </div>
                    <div className="nav_b d-flex justify-content-around m-0">
                            <div className="nav_bl">
                                <button className="btn d-flex rounded-circle" onClick={()=>updateCurrentPage(active_question-1)}><i className="las la-angle-double-left m-auto"></i></button>
                            </div>
                            <div className="nav_bl">
                                <button className="btn d-flex rounded-circle"  onClick={()=>updateCurrentPage(active_question+1)}><i className="las la-angle-double-right m-auto"></i></button>
                            </div>
                        </div>
                </div>
                <div className="qm_boookmark">
           <div className="">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item " role="presentation">
                  <button className="nav-link active w-100" id="questions-tab" data-bs-toggle="tab" data-bs-target="#questions" type="button" role="tab" aria-controls="questions" aria-selected="true">questions</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link w-100" id="bookmark-tab" data-bs-toggle="tab" data-bs-target="#bookmark" type="button" role="tab" aria-controls="bookmark" aria-selected="false">All Questions</button>
                </li>
                
              </ul>


            
                    
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active " id="questions" role="tabpanel" aria-labelledby="questions-tab">

                <ul className="filter-menu d-flex justify-content-start p-0 mt-4 align-items-center">
                    <li className="d-flex all_btn" data-target="all" onClick={()=>setFilterState("all")}>All</li>
                    
                    <li className="d-flex right " data-target="right" onClick={()=>setFilterState("right")}><span className="bl-green"></span></li>
                    
                    <li className="d-flex" data-target="review" onClick={()=>setFilterState("wrong")} ><span className="position-relative q_book-w"></span></li>
                  
                     <li className="d-flex s-next " data-target="s-next" onClick={()=>setFilterState("bookmark")}><span className="position-relative "> <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label></span></li>
                    <li className="d-flex  bg-transparent" data-target="skip" onClick={()=>setFilterState("nd")}><span className="skip"></span></li>
                    
                </ul>
                                    
                    <div className="q_book-list re ">
                    { data.status===true && data.data.filter(data_filter => {
                                    if (data_filter.question_type===technical_aptitude_question_type) {
                                        
                                        if (filter_state==="all"){
                                            return true 
                                        }else if (filter_state==="right") {
                                            
                                        if (data_filter.is_true===1) {
                                            return true 
                                        }

                                        }else if (filter_state==="wrong"){
                                           
                                            if (data_filter.is_true===0) {
                                                return true 
                                            }
                                            
                                        }else if (filter_state==="bookmark"){
                                           
                                            if (data_filter.is_bookmark===1) {
                                                return true 
                                            }

                                        }else if (filter_state==="nd"){
                                            
                                            if(((!data_filter.user_selected_answer && (data_filter.is_bookmark===null || data_filter.is_bookmark===0)) || (data_filter.is_skip===1 && (data_filter.is_bookmark===null || data_filter.is_bookmark===0)))){
                                                return true 
                                            }
                                            
                                            
                                        }

                                        return false;
                                    }
                                    

                                    }).map((result,key) => (
                          <React.Fragment key={key}>
                         {(() => {
                            if (result.is_true===1) { //answer diya + mark kiya
                                return (<div className="q_book-box d-flex m-auto rounded-circle bl-green" key={key} onClick={()=>updateCurrentPage(result.sr_number)}>
                                    
                                   <span className="bl-blue position-relative "></span>
                                    {/* ul to be remove after code done */}
                                <span className="m-auto">{result.sr_number}</span>
                                {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                            </div>)
                            }else if(result.is_true===0){ // only mark
                            return (<div className="q_book-box d-flex m-auto rounded-circle q_book-w" key={key}  onClick={()=>updateCurrentPage(result.sr_number)}>
                                <span className="m-auto">{result.sr_number}</span>
                                {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                            </div>)
                            }else if(result.is_bookmark===1){
                            return (<div className="q_book-box d-flex m-auto rounded-circle" key={key}  onClick={()=>updateCurrentPage(result.sr_number)}>
                                <span className="m-auto">{result.sr_number}</span>
                                {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                
                            </div>)
                            }else if(((!result.user_selected_answer && (result.is_bookmark===null || result.is_bookmark===0)) || (result.is_skip===1 && (result.is_bookmark===null || result.is_bookmark===0)))){
                                return (<div className="q_book-box d-flex m-auto rounded-circle" key={key} onClick={()=>updateCurrentPage(result.sr_number)}>
                                <span className="m-auto">{result.sr_number}</span>
                                {(result.is_bookmark===1)? <label className="bookmark active">
                                <input className="bookmark__toogle" type="checkbox" />
                                <div className="bookmark__stroke"></div>
                                <div className="bookmark__fill"></div>
                                </label>:""}
                                            
                                        </div>)
                            }else{
                                return (<div className="q_book-box d-flex m-auto rounded-circle" key={key} onClick={()=>updateCurrentPage(result.sr_number)}>
                                <span className="m-auto">{result.sr_number}</span>
                                {(result.is_bookmark===1)? <label className="bookmark active">
                                <input className="bookmark__toogle" type="checkbox" />
                                <div className="bookmark__stroke"></div>
                                <div className="bookmark__fill"></div>
                                </label>:""}
                                            
                                        </div>)
                            }
                        })()}
                         
                         </React.Fragment>
                    ))}
                   
                    </div>
                </div>
                <div className="tab-pane fade " id="bookmark" role="tabpanel" aria-labelledby="bookmark-tab">
                    <div className="q_book-list d-block ">
                        
                    { data.status===true && data.data.map((result,key) => (
                      
                          <React.Fragment key={key}>
                            <div style={{ display: result.question_type===technical_aptitude_question_type? "block" : "none" }}>
                         {(() => {

                           
                                if (result.is_true===1) {
                                return ( <div className="bs_bar-o" onClick={()=>updateCurrentPage(result.sr_number)}>
                                <div className="d-flex">
                                    <div className="bs_c-wrap">
                                        <div className="bs_c d-flex rounded-circle bl-green">
                                            <span className="m-auto">{result.sr_number}</span>
                                            {(result.is_bookmark===1)? <label className="bookmark active">
                                <input className="bookmark__toogle" type="checkbox" />
                                <div className="bookmark__stroke"></div>
                                <div className="bookmark__fill"></div>
                                </label>:""}
                                        </div>
                                    </div>
                                  
                                    <MathJaxContext config={config} className="demo">
                                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                                    </MathJaxContext>
                                    
                                </div>
                    
                               

                            </div>)
                            }else if(result.is_true===0){ // only mark
                            return ( <div className="bs_bar-r" onClick={()=>updateCurrentPage(result.sr_number)}>
                            <div className="d-flex">
                                <div className="bs_c-wrap">
                                    <div className="bs_c d-flex rounded-circle q_book-w">
                                        <span className="m-auto">{result.sr_number}</span>
                                        {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                    </div>
                                </div>
                              
                                <MathJaxContext config={config} className="demo">
                                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                                    </MathJaxContext>
                                    
                            </div>
                           
                        </div>)
                            }else if(result.is_bookmark===1){
                            return ( <div className="bs_bar-w" onClick={()=>updateCurrentPage(result.sr_number)}>
                            <div className="d-flex">
                                <div className="bs_c-wrap">
                                    <div className="bs_c d-flex rounded-circle">
                                        <span className="m-auto">{result.sr_number}</span>
                                        {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                    </div>
                                </div>
                              
                                <MathJaxContext config={config} className="demo">
                                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                                    </MathJaxContext>
                                    
                            </div>
                           
                        </div>)
                            }else if(((!result.user_selected_answer && (result.is_bookmark===null || result.is_bookmark===0)) || (result.is_skip===1 && (result.is_bookmark===null || result.is_bookmark===0)))){
                            return ( <div className="bs_bar" onClick={()=>updateCurrentPage(result.sr_number)}>
                            <div className="d-flex">
                                <div className="bs_c-wrap">
                                    <div className="bs_c d-flex rounded-circle">
                                        <span className="m-auto">{result.sr_number}</span>
                                        {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                    </div>
                                </div>
                              
                                <MathJaxContext config={config} className="demo">
                                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                                    </MathJaxContext>
                                    
                            </div>
                           
                        </div>)
                            }else{
                                return ( <div className="bs_bar" onClick={()=>updateCurrentPage(result.sr_number)}>
                                <div className="d-flex">
                                    <div className="bs_c-wrap">
                                        <div className="bs_c d-flex rounded-circle">
                                            <span className="m-auto">{result.sr_number}</span>
                                            {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                        </div>
                                    </div>
                                  
                                    <MathJaxContext config={config} className="demo" >
                                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                                    </MathJaxContext>

                                 
                                </div>
                              
                            </div>)
                            }


                        })()}
                        </div>                   
                        </React.Fragment>
                        ))}

                       
                      
                       



                    </div>
                </div>
                
              </div>
           </div>
               
            </div>
            </div>
        </div>

        {/*-----Subject-wise-mcq-sec-end--*/}

        <Modal show={show.status} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub p-0" >
              <Modal.Header closeButton>
                <Modal.Title><h3 className="m-0">Report this question !</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div className="report_data">
             
              <ul>
                    <li className="rounded-pill act_r"><span>Wrong Question</span><input type="text" /></li>
                    <li className="rounded-pill"><span>Formatting issue</span><input type="text" /></li>
                    <li className="rounded-pill"><span>Que & options both not visible</span><input type="text" /></li>
                    <li className="rounded-pill"><span>Options visible, ques not visible</span><input type="text" /></li>
                    <li className="rounded-pill"><span>Out of syllabus</span><input type="text" /></li>
                    <li className="rounded-pill"><span>Wrong translation</span><input type="text" /></li>
                   
                   <li onClick={()=>setTextBoxShow(!textboxshow)} className={textboxshow?"rounded-pill act_r":"rounded-pill"}><span>Other</span><input type="text" /></li>
                    {textboxshow?<li className="border-0 p-0">
                        <textarea className="" name="other_message" placeholder="Leave a Message here" id="floatingTextarea2" ></textarea>
                    </li>:null}
                    
                    
                        
                    
                </ul>
                <div className="d-flex justify-content-center">
                        <button type="button" className="rounded-pill report_btn">Report</button>
                        <button type="button" className="rounded-pill cl_btn" onClick={handleClose}>Close</button>
                </div>
              
              </div>                    
              </Modal.Body>
            
              {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>
            <ProfileSideMenu />
             <Footer />
              </main>
             
            
            </>);
    }  
    

export default TestSolution;