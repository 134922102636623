import React, { useState ,useRef}from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import MarkAndReview from "./MarkAndReview";
export const OptionSingle= ({data,active_question,config,setActiveQuestion,setData,technical_aptitude_question_type,all_total_question})=>{
    
     let current_data=data.data[technical_aptitude_question_type][active_question];

     const numeric_answer = useRef(null);
     const changeHandler= (e) => {
        numeric_answer.current.value = e.target.value;
     }

     let api_token=localStorage.getItem('_user_token');
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
         }; 
     
    




      function add_numeric_type_answer(question_id,question_type,chapter_id,subject_id,sr_number,event) {
        
        let numeric_answer_var=numeric_answer.current.value;
        let numericTextElements = document.getElementsByClassName('numeric_class_for_clear');
        for(let i=0; numericTextElements[i]; ++i){
            numericTextElements[i].value="";
        }
        let is_skip=0;
        let user_selected_answer=null;
        if (numeric_answer_var.length>0) {
            user_selected_answer=JSON.stringify([numeric_answer_var]);
            is_skip=0;
        }else{
            user_selected_answer=null;
            is_skip=1;   
        }
       
        current_data.user_selected_answer=user_selected_answer;
        current_data.is_skip=is_skip;
        current_data.taken_time=localStorage.getItem('seconds');
        data.data[technical_aptitude_question_type][active_question] = current_data;
        setData({status:true,message:"",data:data.data});

        add_single_question_answer(question_id,question_type,user_selected_answer,is_skip,chapter_id,subject_id);
    }

    function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
         
         try {
            

             axios.post(APP_URL+"add_single_test_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
             .then(response=>{
                
                 if (response.data.status===true) {
                     updateCurrentPage(active_question+1);
                  }else{
                    
                 }
                
                 
             })
             .catch(error=>{
                
                
                 
             })
     
            
         
       } catch (e) { //code will jump here if there is a network problem
        
        
       }
     }



      const updateCurrentPage=(updated_question,question_id)=>{

        
        if(updated_question>=0) {
            window.scrollTo(0, 0);
            let total_current_type_question=0;
            if (technical_aptitude_question_type===1) {
                total_current_type_question=all_total_question.technical_total;
            }else{
                total_current_type_question=all_total_question.general_total;
            }
                    
            if (updated_question<total_current_type_question) {
                setActiveQuestion(updated_question);    
            }else{
                setActiveQuestion(0);    
            
            }
            
           /*  setTime("00:00:00");
            setSeconds(0); */
            
          
        }
        return null;
        
      }

       return (
            <>
            <div className="an_b-wrap">
        {/* nat-solution.html */}
        { current_data.get_option.map((option,option_key) => ( 
   
   <React.Fragment  key={option_key}>
   {(() => {
    let user_selected_answer_val=JSON.parse(current_data.user_selected_answer);
    if (current_data.user_selected_answer && current_data.user_selected_answer.length>0) {
        return (
            <>
             Your Given Answer :{user_selected_answer_val[0]?parseFloat(user_selected_answer_val[0]):""}
             
            <div className="nat_ans"> 
                   
                   {/* <label>Enter numeric answer</label> */}
                   <input type="text" name="numeric_answer" className="form-control numeric_class_for_clear" ref={numeric_answer} placeholder="Enter numeric answer" onKeyPress={(event) => {if (!/^[-\d]*\.?[\d]{0,2}$/.test(event.key)) {event.preventDefault();}}} onChange={changeHandler}/>
                    </div>

                    <div className="nav_b d-flex justify-content-around" >
            <MarkAndReview data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={(event) => {add_numeric_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,current_data.sr_number,event)}}>Save & Next</button>
    
           </div>

                    </>
        )
       
    }else{

        return (<>
        
                     <div className="nat_ans"> 
                    {/* <label>Enter numeric answer</label> */}
                    <input type="text" name="numeric_answer" className="form-control numeric_class_for_clear" ref={numeric_answer} placeholder="Enter numeric answer" onKeyPress={(event) => {if (!/^[-\d]*\.?[\d]{0,2}$/.test(event.key)) {event.preventDefault();}}}  onChange={changeHandler}/>
                    </div>
                    <div className="nav_b d-flex justify-content-around" >
                    <MarkAndReview data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
                    <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
                    <button className="btn ne_btn" type="button"  onClick={(event) => {add_numeric_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,current_data.sr_number,event)}}>Save & Next</button>
                    </div>
                </>
          
        )
    }
   

})()}
   
   </React.Fragment>
))}

        

           
       
    </div>
            </>
            
        );
   
      
    }  
    

export default OptionSingle;