import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {secondsToHms} from './elements/AppHelper';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
export const TestAnalysisQuestionWise= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    
    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
        
      }); 
      let pageDefault=location.state;
   
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + api_token
    }; 

    useEffect(() => { 
        
      try {
    
          setLoader(true);
     
          axios.get(APP_URL+'get_question_analysis',{headers,params:{test_id:pageDefault.test_id}},[])
          .then(response=>{
           setLoader(false);
          
           setData({
               status: response.data.status,
               message: response.data.message,
               data: response.data.data,
             });
            
             
           
            
          })
          .catch(error=>{
          
          if (error.response.status===401) {
              localStorage.clear();
              navigate("/login");   
          }
          
           setLoader(false);  
           setData({
               status: false,
               message: "",
               data: null,
             });
           //console.log(error);
          })
 
  
        } catch (e) { //code will jump here if there is a network problem
       setLoader(false);  
       setData({
           status: false,
           message: "",
           data: null,
         });
       //console.log(e);
   }

      return () => {
           
       }

     },[pageDefault]);
  
 

       return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
       {/* Inner _age Banner End */}
       <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                 <span className="ae_heading text-start">{pageDefault.topic_name}</span>
                                    <h3 className="text-uppercase text-left">{pageDefault.test_name}</h3>   
                                 </div>
                                    </div>
                                    {/* <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Practice</button>
                                    </div> */}
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                         


                       


                        <NavLink to="/test_analysis_topic_wise" className="nav-link" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image,subscription_id:pageDefault.subscription_id,subscription_id:pageDefault.subscription_id}}>Topic Wise</NavLink>

                        <button className="nav-link active" id="nav-t-wise-tab" data-bs-toggle="tab" data-bs-target="#nav-t-wise" type="button" role="tab" aria-controls="nav-t-wise" aria-selected="true">Question Wise</button> 
                         
                        <NavLink to="/test_analysis_competitive" className="nav-link" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image,subscription_id:pageDefault.subscription_id,subscription_id:pageDefault.subscription_id}}>Comparative</NavLink>
                        


                        </div>
                      </nav>
                      <div className="tab-content final_analysis" id="nav-tabContent">
                       
                        <div className="tab-pane fade show active" id="nav-q-wise" role="tabpanel" aria-labelledby="nav-q-wise-tab">
                            <div className="data_table">
                                <div className="">
                                    <table className="table-scroll small-first-col m-0">
                                        <thead>
                                            <tr>
                                                <th>Question</th>
                                                <th>Mark</th>
                                                <th>Your Time</th>
                                                <th>Best Time</th>
                                              </tr>
                                        </thead>
                                       <tbody className="body-half-screen">

                                       { data.status===true && data.data.map((result,key) => (  
                                        <tr>
                                          <td>{result.sr_no}</td>
                                          
                                          {result.is_true===1?<td className="green">{result.marks!=null?parseFloat(result.marks).toFixed(2):"0.00"}</td>:""}

                                          {result.is_true===0?<td className="orange">{result.marks!=null?parseFloat(result.marks).toFixed(2):"0.00"}</td>:""}

                                          {result.is_true!==0 && result.is_true!==1?<td className="">{result.marks!=null?parseFloat(result.marks).toFixed(2):"0.00"}</td>:""}
                                          

                                          <td>{secondsToHms(result.taken_time)}</td>
                                          <td>{secondsToHms(result.best_taken_time)}</td>
                                        </tr>
                                       ))}
                                    </tbody>
                                      </table>
                
                                  
                                </div>
                             </div>
                        </div>
                       
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


            
  
            <ProfileSideMenu />



             <Footer />
              </main>
             
            
            </>);
    }  
    

export default TestAnalysisQuestionWise;