import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {Message} from './elements/Message';
import { APP_URL} from '../constants/global'
import { NavLink,useNavigate } from 'react-router-dom'

export const Login= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const [login_data, setLoginData] = useState({
       mobile: "",
       is_login:1
     });
     const [formErrors, setFormErrors] = useState({});
     const [flashShow, setFlashShow] = useState("");
     
    
     const [verify_data, setVerifyData] = useState({
        mobile: "",
        otp: "",
        user_id: "",
      });

     
    const [ is_mobile, setIsMobile ] = useState(true);
     
    const changeHandler = (e) => {
        setLoginData({[e.target.name]: e.target.value,is_login: 1});
    }
    const changeHandlerVerify = (e) => {
       
        setVerifyData({
        otp: e.target.value,
        mobile: verify_data.mobile,
        user_id: verify_data.user_id});
    }
   
    
    function send_otp(){
        
       
       const headers = {
            "Content-Type": "application/json",
            //Authorization: "Bearer " + token
        };
       
        setLoader(true);
        axios.post(APP_URL+'send_otp',login_data,{headers})
        .then(response=>{
            setLoader(false);
          
            if (response.data.status===true) {
                setIsMobile(false);
                setVerifyData({user_id:response.data.user_id,mobile:login_data.mobile});
                setFlashShow(<Message variant="success" children={response.data.message}/>);
                
            }else{
                
                setFlashShow(<Message variant="danger" children={response.data.message}/>);
                
            }
            //localStorage.setItem('_token', response.data.token);
            //localStorage.setItem('_token', response.data.token);
            //localStorage.setItem('user', "Yamin Saiyed");
            //localStorage.removeItem('_token');
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===422 || error.response.status===404) {
                setFlashShow(<Message variant="danger" children={error.response.data.message}/>);
                
            }
        })

    }
    
    const sendOtp = (e) => {
        e.preventDefault();
        if (send_otp_validate(login_data)===true) {
           
            send_otp();
        }        

    }


    const send_otp_validate = (values) => {
        const errors = {};
       
        if (!values.mobile) {
            errors.mobile = "Please enter mobile number.";
            
        }else if (values.mobile.length!==10){
            errors.mobile = "Invalid mobile number";
        }
        
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };



    function login_register_otp_verified() {
        
        const headers = {
            "Content-Type": "application/json",
            //Authorization: "Bearer " + token
          };
        
        setLoader(true);
        axios.post(APP_URL+"login_verify_otp",verify_data,{headers})
        .then(response=>{
            setLoader(false);   
            if (response.data.status===true) {
                setFlashShow(<Message variant="success" children={response.data.message}/>);
                
                localStorage.setItem('_user_token', response.data.api_token);   
                localStorage.setItem('_user_data', JSON.stringify(response.data.data));
                if (response.data.data.is_category_selected<=0) {
                    navigate("/complete_profile");    
                }else{
                    navigate("/dashboard");    
                }
                
            }else{
                setFlashShow(<Message variant="danger" children={response.data.message}/>);
            }
            //localStorage.setItem('_token', response.data.token);
            //localStorage.setItem('_token', response.data.token);
            //localStorage.setItem('user', "Yamin Saiyed");
            //localStorage.removeItem('_token');
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {

                setFlashShow(<Message variant="danger" children={error.response.data.message}/>);
                
            }
            
        })

    }

 
    const otpVerified = (e) => {
        e.preventDefault();

        if (verify_otp_validate(verify_data)===true) {
           
                login_register_otp_verified();  
           
        }    
    }

    const verify_otp_validate = (values) => {
        const errors = {};
       
        if (!values.otp) {
            errors.otp = "otp is required!";
        }else if (values.otp.length!==6){
            errors.otp = "invalid otp";
        }        
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };
      

      return (
        <>
         
         <main>
         <Header />
         {loader ? <Loader />: ""}
          <div className="login_wrap">
              <div className="login_screen">
                  <div className="login">
                     {flashShow}
                      <img alt="" className="img-fluid rd" src="/images/Final-Logo.png" />
                      <form onSubmit={sendOtp} style={{ display: is_mobile ? "block" : "none" }}>
                          
                         <div className="mobi_data-wrap">
                         <input type="text" name="mobile" placeholder="Mobile Number" className="form-control border-0 rounded-pill" id="inputGroup" onChange={changeHandler} value={login_data.mobile} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                          <span className="text-danger">{formErrors.mobile}</span>
                         </div>
                          <button className="btn rounded-pill " type="submit">Log In</button>
                      </form>
                      
                      <form onSubmit={otpVerified} style={{ display: is_mobile ? "none" : "block" }}>
                          
                         <div className="mobi_data-wrap">
                         <input type="text" name="otp" placeholder="OTP" className="form-control border-0 rounded-pill" onChange={changeHandlerVerify} value={verify_data.otp ? verify_data.otp : ""}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                          <span className="text-danger red">{formErrors.otp}</span>
                         </div>
                          <button className="btn rounded-pill " type="submit">Log In </button>
                      </form>
                      <p>Don't have an account? <NavLink to="/register" className="">sign up</NavLink></p>
                      <img alt="" className="img-fluid wave"  src="/images/experience/wave.png"/>
                  </div>
              </div>
          </div>
          <Footer />
          </main>
         
        
        </>
      )
}
export default Login;