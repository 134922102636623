export const getOnlyDate = (date)=>{
    
  let parsed_date = new Date(Date.parse(date));
  return parsed_date.getDate();
}

export const getMonthYear = (date)=>{
  const months = ["Jan", "Feb", "Mar","Apr", "May", "June", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let parsed_date = new Date(Date.parse(date));
  return months[parsed_date.getMonth()]+", "+parsed_date.getFullYear().toString().substr(-2);
}
export const finalDateFormat = (date)=>{
    
  const months = ["Jan", "Feb", "Mar","Apr", "May", "June", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let parsed_date = new Date(Date.parse(date));
  let formatted_date = parsed_date.getDate() + 
     "-" + months[parsed_date.getMonth()] + 
     "-" + parsed_date.getFullYear()
  return formatted_date;
}

export const getDateTimeFormat = (date)=>{
    
  let parsed_date = new Date(Date.parse(date));

  var h =  parsed_date.getHours(), m = parsed_date.getMinutes();
  
  if(h===0){ //At 00 hours we need to show 12 am
    h=12;
  }
  var _time = (h > 12) ? (zeroPad(h-12) + ':' + zeroPad(m) +' PM') : (zeroPad(h) + ':' + zeroPad(m) +' AM');


  let formatted_date = parsed_date.getDate() + 
     "-" + parsed_date.getMonth() + 
     "-" + parsed_date.getFullYear();

  return formatted_date+" "+_time;
}
 
export const isNaturalNumber = (str)=>{
    
  var pattern = /^(0|([1-9]\d*))$/;
  return pattern.test(str);
}

function zeroPad(num) {
  var n = Math.abs(num);
  var zeros = Math.max(0, 2 - Math.floor(n).toString().length );
  var zeroString = Math.pow(10,zeros).toString().substr(1);
  if( num < 0 ) {
      zeroString = '-' + zeroString;
  }
  return zeroString+n;
}

export const secondsToHms = (d)=>{
    
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);
  
  return zeroPad(h)+":"+zeroPad(m)+":"+zeroPad(s); 

}

