import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import { APP_URL} from '../constants/global';
import { useNavigate,useLocation} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
export const BillingInformation= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
    }); 
    
    const [formErrors, setFormErrors] = useState({});
    const [show, setShow] = useState({status:false});
    
    const [form_data, setFormData] = useState({});

    const location = useLocation();
    
    const navigate = useNavigate();
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + api_token
        };

        let pageDefault=location.state;
        
        
        const handleClose = () => setShow({status:false});
        
        const handleShow=(single_data)=>{
            
            setFormData(single_data);
            setShow({status:true})
            setFormErrors({});
        }
        const handleCheckBoxClick=(billing_id)=>{
            

            setLoader(true);
            axios.get(APP_URL+'set_default_billing_details',{headers,params:{billing_id:billing_id}},[])
               .then(response=>{
                setLoader(false);
                if (response.data.status===true) {
                   
                    
                }else{
                   
                }
                get_address_list();
               
                
            })
            .catch(error=>{
                setLoader(false);
                if (error.response.status===401) {
    
                   
                    
                }
                
            })

        }

        

        const changeHandler = (e) => {

            const name = e.target.name;
            const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;
    
            
            
            setFormData({
                ...form_data,
                [name]: value
              })
          }

       /*  const changeHandler = (e) => {
            setFormData({[e.target.name]: e.target.value});
        } */

      function get_address_list() {
        
        try {
           
               setLoader(true);
          
               axios.get(APP_URL+'billing_list',{headers},[])
               .then(response=>{
                setLoader(false);
               
                setData({
                    status: response.data.status,
                    message: response.data.message,
                    data: response.data.data,
                  });
                  
                 
                  
                 
               })
               .catch(error=>{
                setLoader(false);  
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate("/login");   
                }

                setData({
                    status: false,
                    message: "",
                    data: null,
                  });

                  
               
               })
      
       
             } catch (e) { //code will jump here if there is a network problem
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
           
        }
        
      }
      
      useEffect(() => { 
        
        get_address_list();
        
       return () => {
            
        }

      },[]);
      


      

      function update_address() {
        
        
        setLoader(true);
        axios.post(APP_URL+'update_billing_details',{address_one:form_data.address_one,address_three:form_data.address_three,address_two:form_data.address_two,email:form_data.email,fullname:form_data.fullname,billing_id:form_data.id,mobile:form_data.mobile,pincode:form_data.pincode},{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
              
                
            }else{
              
            }
            setShow({status:false});    
            get_address_list();
           
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {

               
                
            }
            
        })

    }
    function add_address() {
        
        
        setLoader(true);
        axios.post(APP_URL+'add_billing_details',form_data,{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
                
                
            }else{
                
            }
            setShow({status:false});    
            get_address_list();
           
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {

              
                
            }
            
        })

    }

    const updateAddress = (e) => {
        e.preventDefault();

        if (validate(form_data)===true) {
            update_address();  
        }    
     }

     const addAddress = (e) => {
        e.preventDefault();

        if (validate(form_data)===true) {
            add_address();  
        }    
     }

      const validate = (values) => {
        const errors = {};
       
        if (!values.fullname) {
            errors.fullname = "fullname is required!";
        }
        if (!values.mobile) {
            errors.mobile = "mobile is required!";
        } else if (values.mobile.length!==10){
            errors.mobile = "mobile number should be 10 digit";
        }    
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!values.email) {
            errors.email = "email is required!";
        }else if(!regEmail.test(values.email)){
            errors.email = "email address is invalid";
        }
        if (!values.address_one) {
            errors.address_one = "address1 is required!";
        }  
        if (!values.pincode) {
            errors.pincode = "pincode is required!";
        }else if (values.pincode.length!==6){
            errors.pincode = "pincode should be 6 digit";
        }    
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };

 
      const orderPaymentRequest = (e) => {

        try {
          
            setLoader(true);
           
           
            axios.post(APP_URL+"order_payment_request",{coupon_code:(pageDefault.coupon_details.status===true)?pageDefault.coupon_details.data.code:""},{headers})
            .then(response=>{
             setLoader(false);
             
           
            //document.forms["redirect"].submit();
           
             
             if (response.data.status===true) {
              
                window.location.replace(response.data.link);
            }else{
                document.getElementById('cc_data').innerHTML = response.data;
                setTimeout(document.redirect.submit(),15000);
            }
            setShow({status:false}); 
   
             
            })
            .catch(error=>{
             setLoader(false);  
            
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
       
     }

       

     }


      

   
        return (
        
            <>
            <main>
             <Header />
             {loader ? <Loader />: ""}
             <div id="cc_data"></div>
             <div className="position-relative">

              {/* Inner _age Banner End */}
              <div className="page_banner-wrapper">   
                <div className="page_baneer ">
                    <div className="p_yq">
                        <div className="p_yq-left d-flex">
                            <img className="img-fluid" src="/images/pyq/label.svg" />
                     <div className="d-flex">
                        <h1 className="text-capitalize">Billing Information</h1>
                     </div>
                        </div>
                    </div>
                    <div className="page_overlay"></div>                              
                </div>
        </div>
{/* Inner _age Banner End */}


{/*---Bill-info start----*/}

        <div className="bill_info-wrapper">

        { data.status===true && data.data.map((single_data,key) => ( 
            <div className="bill_info" key={key}>
                <div className="user_bill align-items-center">
                   <div className="d-flex align-items-center">
                    <div className="round">
                        <input type="checkbox" id={"checkbox"+key} onChange={()=>handleCheckBoxClick(single_data.id)} checked={single_data.is_default_address===1?"checked":""} />
                        <label className="m-0" htmlFor={"checkbox"+key}></label>
                      </div>
                     <h1 className="m-0">{single_data.fullname}</h1>
                   </div>
                   
                   <Button variant="primary" className=""  onClick={()=>handleShow(single_data)}><i className="las la-edit"></i></Button> 

                    
                </div>
                <div className="user_contact">
                    <div className="user_co-l">
                        <i className="las la-phone"></i>
                    </div>
                    <div className="user_co-r d-flex">
                        <h3 className="">+91-{single_data.mobile}</h3>
                    </div>  
                </div>
                <div className="user_contact">
                    <div className="user_co-l">
                        <i className="las la-envelope"></i>
                    </div>
                    <div className="user_co-r d-flex">
                        <h3 className="">{single_data.email}</h3>
                    </div>  
                </div>
                <div className="user_contact">
                    <div className="user_co-l">
                        <i className="las la-map-marker"></i>
                    </div>
                    <div className="user_co-r d-flex">
                        <h3 className="">{single_data.address_one}</h3>
                    </div>  
                </div>
               
                  
            </div>
            
            ))}
            <div className="con_address text-center">
                
                <Button variant="primary" className="btn b-blue" onClick={()=>handleShow({})}>Add New</Button>
                <button className="btn b-orange" type="submit" onClick={()=>orderPaymentRequest()} style={{ display: data.status===true ? "block" : "none" }}>Checkout</button>
            </div> 

        </div>

{/*------Bill-info-end--*/}


{/*---Bill-info-edit form-start--------*/}

<Modal show={show.status} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub p-0" >
              <Modal.Header closeButton>
                <Modal.Title><h3 className="m-0">Billing Address</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
               <div className="">
           <div className="edit_bill-info p-0">
         <form onSubmit={form_data.id?updateAddress:addAddress}>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Full Name</label>
                </div>
                <input type="hidden" name="billing_id" value={form_data.id}/>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    
                    <input type="text" name="fullname" className="form-control" placeholder="John Smith" onChange={changeHandler} value={form_data.fullname}/>
                    <span className="text-danger">{formErrors.fullname}</span>
                 

                </div>
            </div>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Contact No.</label>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    <input type="text" name="mobile" className="form-control" placeholder="8000074945" onChange={changeHandler} value={form_data.mobile} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                    <span className="text-danger">{formErrors.mobile}</span>
                </div>
            </div>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Email</label>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    <input type="text" name="email" className="form-control" placeholder="php@nividaweb.com" onChange={changeHandler} value={form_data.email}/>
                    <span className="text-danger">{formErrors.email}</span>
                </div>
            </div>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Address 1</label>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    <input type="text" name="address_one" className="form-control" placeholder="123, Building Name, Street Name, Landmark," onChange={changeHandler} value={form_data.address_one} />
                    <span className="text-danger">{formErrors.address_one}</span>
                </div>
            </div>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Address 2</label>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    <input type="text" name="address_two" className="form-control" placeholder="Locality, Area, City, State, 390 001, India" onChange={changeHandler} value={form_data.address_two}/>

                </div>
            </div>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Address 3</label>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    <input type="text" name="address_three" className="form-control" placeholder="Locality, Area, City, State, 390 001, India" onChange={changeHandler} value={form_data.address_three}/>

                </div>
            </div>
            <div className="row mb-xl-4 mb-md-2 mb-sm-2 mb-2">
                <div className="col-xl-3 col-md-4 col-sm-12 col-12 d-flex">
                    <label>Pincode</label>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-12">
                    <input type="text" name="pincode" className="form-control" placeholder="Pincode" onChange={changeHandler} value={form_data.pincode} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                    <span className="text-danger">{formErrors.pincode}</span>
                </div>
            </div>
            <button className="ch_sub-btn d-orange border-0 rounded-pill" type="submit">{form_data.id?"Change":"Add"}</button>
         </form>
           </div>
        </div>
                
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>




{/*---Bill-info-edit form-end--------*/}

          </div>
          
             <ProfileSideMenu />
             <Footer />
              </main>
             
            
            </>);
    }  
    

export default BillingInformation;