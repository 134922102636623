import React from "react";
import { NavLink,useNavigate } from 'react-router-dom'

export const Header= ()=>{
    window.scrollTo(0, 0)
    const _token=localStorage.getItem("_user_token");
    const cart_count=localStorage.getItem("cart_count");
    const navigate = useNavigate();
   

    function Logout(){
        localStorage.clear();
        navigate("/login");   

    }
return (
         
         <>
    <header className="top-header">
            <nav className="navbar custom-navbar py-0 navbar-expand-lg navbar-light">
                <div className="container-fluid">
                {((_token!=null)?<NavLink to="/dashboard" className="navbar-brand p-0"><img src="/images/Final-Logo.png" className="img-fluid" alt="" /></NavLink>:<NavLink to="/" className="navbar-brand p-0"><img src="/images/Final-Logo.png" className="img-fluid" alt="" /></NavLink>)}

                
                      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav main-navbar-nav m-auto">
                            <li className="nav-item dropdown">
                            {((_token!=null)?<NavLink to="/dashboard" className="nav-link">Home</NavLink>:<NavLink to="/" className="nav-link">Home</NavLink>)}
                            </li>
                            <li className="nav-item">
                                <NavLink to="/blog_list" className="nav-link">Blogs</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/contact" className="nav-link">Contact</NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/offer" className="nav-link">Offers</NavLink>

                            </li>
                         
                           
                           
                        </ul>
                        <div className="top-finder">
                            <div className="search-bar position-relative">
                                <input type="text" placeholder="what do you want to learn" className="form-control border-0" id="inputGroup" />
                                <i className="las la-search"></i>
                            </div>
                            <div className="cls-box d-flex">
                                <div className="ac-box position-relative">
                                    
                                    <NavLink to="/subscription_cart" className="d-block">
                                    <i className="las la-shopping-cart"></i>
                                    {cart_count?<span className=""><small>{cart_count}</small></span>:""}
                                    </NavLink>

                                </div>
                                <div className="ls-btn">
                                {((_token!=null)?<a href="#" className="" onClick={Logout}>Logout</a>:<NavLink to="/login" className="">Login/Signup</NavLink>)}    
                                
                                {/* <a href="/login">Login/Signup</a> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                
            </nav>
        </header>

          </>
        
         );

}
