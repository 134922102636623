import React, { useState }from "react";
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import axios from '../../general/axios.js';
import MarkAndReview from "./MarkAndReview";
export const OptionSingle= ({data,active_question,config,setActiveQuestion,setData,technical_aptitude_question_type,all_total_question})=>{
    
     let current_data=data.data[technical_aptitude_question_type][active_question];

     let api_token=localStorage.getItem('_user_token');
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
         }; 

      const [loader, setLoader] = useState(false);
      const option_array = {
        0:"A",
        1:"B",
        2:"C",
        3:"D",
        4:"E",
        5:"F",
        6:"G",
      }

      function add_single_type_option(option_id) {
        current_data.user_selected_answer=JSON.stringify(option_id);
        current_data.taken_time=localStorage.getItem('seconds');
        data.data[technical_aptitude_question_type][active_question] = current_data;
        setData({status:true,message:"",data:data.data});
      }

    function add_single_type_answer(question_id,question_type,chapter_id,subject_id,sr_number,event) {
        
        let is_skip=0;
        let user_selected_answer=null;
        if (current_data.user_selected_answer===null){
            is_skip=1;
            user_selected_answer=null;
        }else{
            is_skip=0;
            user_selected_answer=current_data.user_selected_answer;
        }
        
        current_data.is_skip=is_skip;
        current_data.is_true=0;

        current_data.taken_time=localStorage.getItem('seconds');
        data.data[technical_aptitude_question_type][active_question] = current_data;
        setData({status:true,message:"",data:data.data});
        
        add_single_question_answer(question_id,question_type,user_selected_answer,is_skip,chapter_id,subject_id);
}

function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
   setLoader(true);
    
    try {
        
      
        
        axios.post(APP_URL+"add_single_test_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
        .then(response=>{
           
            if (response.data.status===true) {
                updateCurrentPage(active_question+1);
             }else{
               
            }
            setLoader(false);   
            
        })
        .catch(error=>{
            setLoader(false);
           
            
        })

       
    
  } catch (e) { //code will jump here if there is a network problem
    setLoader(false);  
   
}
}


const updateCurrentPage=(updated_question,question_id)=>{

        
    if(updated_question>=0) {
        window.scrollTo(0, 0);
        let total_current_type_question=0;
        if (technical_aptitude_question_type===1) {
            total_current_type_question=all_total_question.technical_total;
        }else{
            total_current_type_question=all_total_question.general_total;
        }
                
        if (updated_question<total_current_type_question) {
            setActiveQuestion(updated_question);    
        }else{
            setActiveQuestion(0);    
        
        }
        
       /*  setTime("00:00:00");
        setSeconds(0); */
        
      
    }
    return null;
    
  }

  


       return (
            <>
           <div className="an_b-wrap pyqs_wrap mcq-solution">
       
       
       { current_data.get_option.map((option,option_key) => ( 
           <React.Fragment  key={option_key}>
          {(() => {
          
           
         
           if (current_data.user_selected_answer && current_data.user_selected_answer.length>0) {
                                          
               return (
                   <>
                    <div className={(JSON.parse(current_data.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n given_ans":"d-flex justify-content-between an_b-n sssssssss"} onClick={(event) => {add_single_type_option([option.id.toString()],current_data.sr_number)}}>
                    <div className="an_bl d-flex">
                       <div className="an_count me-3 d-flex rounded-circle ">
                           <span className="m-auto">{option_array[option_key]}</span>
                       </div>
                       <div className="an_dummy d-flex">
                       <MathJaxContext config={config} className="demo">
                            <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                       </MathJaxContext>
       
                           
                       </div>
                   </div>
                 
               </div>
                   
             <div className="nav_b d-flex justify-content-around" >
            <MarkAndReview data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={(event)=>add_single_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,current_data.sr_number,event)}>Save & Next</button>
    
           </div>
                   </>
                 
               )
           
           }else {
          
               return (
                    <>
                   <div className="d-flex justify-content-between an_b-n" onClick={(event) => {add_single_type_option([option.id.toString()],current_data.sr_number)}}>
                    <div className="an_bl d-flex">
                       <div className="an_count me-3 d-flex rounded-circle ">
                           <span className="m-auto">{option_array[option_key]}</span>
                       </div>
                       <div className="an_dummy d-flex">
                       <MathJaxContext config={config} className="demo">
                            <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                       </MathJaxContext>
       
                           
                       </div>
                   </div>
                 
               </div>

                <div className="nav_b d-flex justify-content-around" >
                <MarkAndReview data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
                <button className="btn ne_btn" type="button" onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
                <button className="btn ne_btn" type="button" onClick={(event)=>add_single_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,current_data.sr_number,event)}>Save & Next</button>

                </div>
                </>
               )
       
           }
       })()}
          
          </React.Fragment>
       ))}
           
       </div>


            </>
            
        );
   
      
    }  
    

export default OptionSingle;