import React from "react";
import { APP_URL,IMAGE_URL } from '../constants/global'
export const SelectGoalCategoryList= (data)=>{
   
    return (
    <>
        

        <li>
            <div className="exam_card d-flex">
                <div className="card_left d-flex">
                    {/* <img alt="" className="" src={IMAGE_URL+data.category.image}/> */}
                  <div className="exam_ard-img">
                   <img alt="" className="" src={data.category.image?IMAGE_URL+data.category.image:"/images/right-fy/lion-india.png"}/>
                   </div> 
                    <div className="d-flex ">
                        <p className="">{data.category.category_name}</p>
                    </div>
                
                </div>
                <div className="card_right">
                <div className="card_right-inner">
                    <img alt="" className="img-fluid" src="/images/add.svg" onClick={data.onClickBtn}/>
                </div>
                </div>
            </div>
        </li>
    </>
);

}
