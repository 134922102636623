import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';

export const PyqTopic= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    
    let pageDefault=location.state;

      
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 
      
      useEffect(() => { 
              
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_pyq_topic',{headers,params:{chapter_id:pageDefault.chapter_id}},[])
           .then(response=>{
            setLoader(false);
           
            setData({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
              });
             
              
            
             
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
        //console.log(e);
    }

       return () => {
            
        }

      },[pageDefault]);
      
        return (
        
            <>
            <main>
             <Header />
             {loader ? <Loader />: ""}

            {/* Inner _age Banner End */}
        <div className="page_banner-wrapper">
            <div className="page_baneer bill_banner">
                <div className="p_yq">
                    <div className="p_yq-left d-flex">
                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                            <span className="ae_heading text-start">{pageDefault.subject_name} </span>
                            <h3 className="text-uppercase text-left">{pageDefault.chapter_name}</h3>
                        </div>
                    </div>
                    <div className="p_yq-right justify-content-end">
                        <button className="btn text-white text-end">Pyq</button>
                    </div>
                </div>
                <div className="page_overlay"></div>
            </div>
        </div>
        {/* Inner _age Banner End */}

        {/*--chapter-box-start----*/}
        <div className="cp_box-wrapper">
            <div className="cp_box">
            { data.status===true && data.data.map((result,key) => ( 

                
                <div className="cp_card" key={key} >
                    {/* <div className="cp_l">
                        <div className="cp_l-inner">
                            <p>{ pageDefault.chapter_label.split('-')[0]}</p>
                            <h1>{ pageDefault.chapter_label.split('-')[1]}</h1>
                        </div>
                    </div> */}
                    <div className="cp_r">
                        <div className="cp_r-inner">
                            <div className="cp_rm">
                                <p>{result.name}</p>
                                <span>{result.total_given_pyq_answer}/{result.total_pyq_question} Pyq QS</span>
                            </div>
                            <div className="cp_grow">
                                <div className="bar_line-wrapper rounded-pill w-100">
                                    <div className="bar_fider" style={{width:Math.ceil(100*result.total_given_pyq_answer/result.total_pyq_question)+'%'}}>
                                        <div className="bar_line">
                                            <span>{result.total_pyq_question>=result.total_given_pyq_answer?Math.ceil(100*result.total_given_pyq_answer/result.total_pyq_question):0}%</span>
                                        </div>
                                        <div className="bar_positive"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="cp_more">
                                <div className="cp_ml">
                                    <div className="d-flex align-items-center">
                                        <i className="las la-check"></i>
                                        <div className="d-flex ">
                                            <span>Correct: {result.total_correct_pyq_answer}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="las la-times"></i>
                                        <div className="d-flex ">
                                            <span>Incorrect: {result.total_incorrect_pyq_answer}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cp_mr">
                                
                                    {result.is_start===1?<NavLink to="/pyq_question" className="" state={{topic_id:result.id,topic_name:result.name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}><img alt="" className="" src="/images/chapter/chapter2.png" /></NavLink>:""}
                                    
                                  
                                    
                                    {result.is_start===2?<NavLink to="/pyq_question" className="" state={{topic_id:result.id,topic_name:result.name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}><img alt="" className="" src="/images/resume.png" /></NavLink>:""}
                                    
                                    {result.is_start===3?<NavLink to="/pyq_question" className="" state={{topic_id:result.id,topic_name:result.name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}><img alt="" className="" src="/images/completed.png" /></NavLink>:""}
                                    
                                    {/*<NavLink to="/pyq_pdf" className="" state={{pdf:result.pdf_doc}}><img alt="" className=""  src="/images/chapter/chapter1.png" /></NavLink>*/}
                                    
                                    <a href={result.pdf_doc?IMAGE_URL+result.pdf_doc:""} target='_blank' rel='noopener noreferrer'><img alt="" className=""  src="/images/chapter/chapter1.png" /></a>
                                    
                                    <NavLink to="/pyq_topic_analysis" className="" state={{topic_id:result.id,topic_name:result.name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}><img alt="" className="" src="/images/chapter/chapter3.png" /></NavLink>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ))}

               
              
            </div>  
        </div>

        {/*-----chapter-box-end--*/}

        <ProfileSideMenu />
                 
          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PyqTopic;