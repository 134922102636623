import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';

import { APP_URL } from '../constants/global';
import {useLocation} from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { SEO } from './elements/SEO';
import {Loader} from './elements/Loader';
export const BlogDetails= ()=>{
    const [loader, setLoader] = useState(false);
   const [data, setData] = useState({
      status:false,
      message:"",
      data:null,
    }); 
    const location = useLocation();

     let blog_id_data = {};
     blog_id_data.blog_id =location.pathname.split('/')[2]; 
    let pageDefault=location.state!=null?location.state:blog_id_data;
    
    let api_token=localStorage.getItem('_user_token');

   useEffect(() => { 
     
      
      try {
           const headers = {
           "Content-Type": "application/json",
           Authorization: "Bearer " + api_token
           }; 
          setLoader(true);
     
          axios.get(APP_URL+'get_blog_details',{headers,params:{blog_id:pageDefault.blog_id}},[])
          .then(response=>{
           setLoader(false);
           
           setData({
               status: response.data.status,
               message: response.data.message,
               data: response.data.data,
             });
            
             
           
            
          })
          .catch(error=>{
        
           setData({
               status: false,
               message: "",
               data: null,
             });
          
          })
        setLoader(false);  
  
        } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
       setData({
           status: false,
           message: "",
           data: null,
         });
      
   }

      return () => {
           
       }

     },[]);

     if (data.status===true) {
      return (
        
         <>
         <div>
            <SEO
            title={data.data.meta_title+' - AbhyasKaro'}
            description={data.data.meta_description}
            keyword={data.data.meta_keyword}
            canonical={'https://abhyaskaro.com/blog_details/'+pageDefault.blog_id}
             />
            
            </div>
         <main>
          <Header />
          {loader ? <Loader />: ""}
          <div className="container mt-5">
             <div className="y_exam-heading">
             <h3 className="m-0">Blog Details</h3>
             </div>
             <div className="row mb-5 mt-3">
                 <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                     <div className="blog_details-wrap">
                       <img className="img-fluid" src={IMAGE_URL+data.data.image}  />
                       <h3 className="">{data.data.name}</h3>

                       <p>{data.data.short_description}</p>
                       
                       <div dangerouslySetInnerHTML={{__html: data.data.long_description}}></div>
                       
                      </div>
                 </div>
                 
             </div>
          </div>

          <Footer />
           </main>
          
         
         </>);
     }else{
      return (
        
         <>
         <main>
          <Header />
        

          <Footer />
           </main>
          
         
         </>);
     }
       
    }  
    

export default BlogDetails;