import { useState, useEffect } from 'react'

import React from "react";


export const Message= ({ variant, children }) =>{
   
    const [show, setShow] = useState(true)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
      
    }, 3000)

    return () => {
      clearTimeout(timeId)
     
    }
  }, [show]);

  // If show is false the component will return null and stop here

 /*  if (!show) {
    return null;
  } */

return (<div className={`alert alert-${variant}`}>
    <strong>{children}</strong>
    </div>);

}

