import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export const TestAnalysisTopicWise= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
   

    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
        
      }); 

      let pageDefault=location.state;

      

    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + api_token
    }; 
    useEffect(() => { 
        
        try {
      
            setLoader(true);
       
            axios.get(APP_URL+'get_test_summary',{headers,params:{test_id:pageDefault.test_id}},[])
            .then(response=>{
             setLoader(false);
            
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data: response.data.data,
               });
              
               
             
              
            })
            .catch(error=>{

                if (error.response.status===401) {
                    localStorage.clear();
                    navigate("/login");   
                }
                
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data: null,
               });
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data: null,
           });
         //console.log(e);
     }
 
        return () => {
             
         }
 
       },[pageDefault]);
    

       
    return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
              {/* Inner _age Banner End */}
              <div className="page_banner-wrapper">   
                                      <div className="page_baneer rounded-0">
                                          <div className="p_yq">
                                              <div className="p_yq-left d-flex">
                                              <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />

                                          <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                          <span className="ae_heading text-start">{pageDefault.topic_name}</span>
                                    <h3 className="text-uppercase text-left">{pageDefault.test_name}</h3>   
                                          </div>
                                              </div>                                            {/* <div className="p_yq-right justify-content-end">
                                                  <button className="btn text-white text-end">Practice</button>
                                              </div> */}
                                          </div>
                                          <div className="page_overlay rounded-0"></div>                              
                                      </div>
                              </div>
                      {/* Inner _age Banner End */}


                      {/*--Topic-start----*/}
                      <div className="topic_wrapper">
                          <div className="topic_box">
                              <nav>
                                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                  


                                  <button className="nav-link active" id="nav-t-wise-tab" data-bs-toggle="tab" data-bs-target="#nav-t-wise" type="button" role="tab" aria-controls="nav-t-wise" aria-selected="true">Topic Wise</button>


                                  <NavLink to="/test_analysis_question_wise" className="nav-link" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image,subscription_id:pageDefault.subscription_id}}>Question Wise</NavLink>
                                  
                                  
                                  <NavLink to="/test_analysis_competitive" className="nav-link" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image,subscription_id:pageDefault.subscription_id}}>Comparative</NavLink>
                                  


                                  </div>
                                </nav>
                                <div className="tab-content final_analysis" id="nav-tabContent">
                                  <div className="tab-pane fade show active" id="nav-t-wise" role="tabpanel" aria-labelledby="nav-t-wise-tab">
                                      <div className="t_w-card-inner m-0">
                                          <div className="t_w-card-box">
                                              <p className="mt-xxl-0 mt-xl-0 mt-md-0 mt-sm-0 mt-2 mb-0">Technical</p>
                                              <span>Score</span>
                                              <div className="mt-2 d-flex justify-content-center">
                                                  <h3 className="me-2 my-0">{data.status===true?(data.data.technical_correct_marks-data.data.technical_incorrect_marks).toFixed(2):0} /</h3>
                                                  <span className="half_score">{data.status===true?data.data.technical_total_marks.toFixed(2):0}</span>
                                              </div>
                                              <div className="score_card d-flex justify-content-around">
                                                  <div className="card_status correct">
                                                <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((data.data.technical_correct_question/data.data.technical_total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `#2ed138`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="green">{data.status===true?data.data.technical_correct_question:0} </p>
                                                    <br />
                                                    <span className="crt_data green">Correct</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="crt_total">{data.status===true?data.data.technical_correct_marks.toFixed(2):0} marks</span>
                                                    </div>
                                                      
                                              </div>

                                              	
                                               

                                             
                                                    
    

                                              <div className="card_status incorrect">

                                                    <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((data.data.technical_incorrect_question/data.data.technical_total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `orange`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="orange">{data.status===true?data.data.technical_incorrect_question:0} </p>
                                                    <br />
                                                    <span className="ict_data orange">  Incorrect</span>
                                                    </CircularProgressbarWithChildren>
                                                    <span className="ict_total">{data.status===true?data.data.technical_incorrect_marks.toFixed(2):0} marks</span>
                                                    </div>
                                                    
                                                            
                                                 
                                          </div>
                                          <div className="card_status skip">

                                                    
                                                

                                                <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?(((data.data.technical_total_question-(data.data.technical_correct_question+data.data.technical_incorrect_question))/data.data.technical_total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `grey`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="gray">{data.status===true?(data.data.technical_total_question-(data.data.technical_correct_question+data.data.technical_incorrect_question)):0} </p>
                                                    <br />
                                                    <span className="utm_data gray">Unattempted</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="utm_total">0 marks</span>
                                                    </div>

                                             
                                      </div>
                                              </div>
                                          </div>
                                          <div className="t_w-card-box">
                                              <p className="mt-xxl-0 mt-xl-0 mt-md-0 mt-sm-0 mt-2 mb-0">General Aptitude</p>
                                              <span>Score</span>
                                              <div className="mt-2 d-flex justify-content-center">
                                                  <h3 className="me-2 my-0">{data.status===true?(data.data.general_aptitude_correct_marks-data.data.general_aptitude_incorrect_marks).toFixed(2):0} /</h3>
                                                  <span className="half_score">{data.status===true?data.data.general_aptitude_total_marks.toFixed(2):0}</span>
                                              </div>
                                              <div className="score_card  d-flex justify-content-around">
                                                  <div className="card_status correct">

                                                 <div className="score_c-gr" >
                                                 <CircularProgressbarWithChildren value={data.status===true?((data.data.general_aptitude_correct_question/data.data.general_aptitude_total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `#2ed138`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                   <p className="green"> {data.status===true?data.data.general_aptitude_correct_question:0} </p>
                                                    <br />
                                                    <span className="crt_data green">Correct</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="crt_total">{data.status===true?data.data.general_aptitude_correct_marks.toFixed(2):0} marks</span>
                                                    
                                                    
                                                     
                                              </div>
                                              </div>

                                              <div className="card_status incorrect">



                                                <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((data.data.general_aptitude_incorrect_question/data.data.general_aptitude_total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `orange`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="orange">{data.status===true?data.data.general_aptitude_incorrect_question:0} </p>
                                                    <br />
                                                    <span className="ict_data orange">  Incorrect</span>
                                                    </CircularProgressbarWithChildren>
                                                    <span className="ict_total">{data.status===true?data.data.general_aptitude_incorrect_marks.toFixed(2):0}  marks</span>
                                                    
                                                    
                                                     
                                              </div>


                                              
                                                 
                                          </div>
                                          <div className="card_status skip">


                                          <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?(((data.data.general_aptitude_total_question-(data.data.general_aptitude_correct_question+data.data.general_aptitude_incorrect_question))/data.data.general_aptitude_total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `grey`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="gray">{data.status===true?(data.data.general_aptitude_total_question-(data.data.general_aptitude_correct_question+data.data.general_aptitude_incorrect_question)):0} </p>
                                                    <br />
                                                    <span className="utm_data gray">Unattempted</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="utm_total">0 marks</span>
                                                    
                                                    
                                                     
                                              </div>


                                             
                                      </div>
                                              </div>
                                          </div>
                                      </div>  
                                  </div>
                                  
                                  
                                </div>
                          </div>
                      </div>

                      {/*-----Topic-end--*/}


                      
            
                      <ProfileSideMenu />



             <Footer />
              </main>
             
            
            </>);
    }  
    

export default TestAnalysisTopicWise;