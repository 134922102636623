
import React, {useEffect,useState} from "react";
import {secondsToHms} from './AppHelper';

export const CounterTimeNew= (props)=>{
  
    
  
    const [seconds, setSeconds] = useState();
    
    

    useEffect(() => {
      if (props.taken_time===null) {
        setSeconds(0); 
        localStorage.setItem('seconds',0); 
      }else{
        setSeconds(parseInt(props.taken_time));  
        localStorage.setItem('seconds',props.taken_time);
      }
    
    }, [props]);
    
    useEffect(() => {
      const incrementCount = () => {
        setSeconds(parseInt(seconds) + 1);
        localStorage.setItem('seconds',seconds);
      };
      const timer = setTimeout(() => incrementCount(), 1000);
      return () => clearTimeout(timer);
    }, [seconds]);

    return (
      <div className="qm_timer rounded-pill d-flex">
         <i className="las la-stopwatch text-white"></i>
         <span className="text-white">{secondsToHms(seconds)}</span>
     </div>

);


}