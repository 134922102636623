import React from "react";
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import { useParams } from 'react-router-dom'



export const ThankYou= ()=>{
        
    localStorage.removeItem('cart_count'); 

    let {order_id}=useParams()   
    
    if (order_id == 0) {

        
        return (
        
            <>
            <main>
            <Header />
           
                
             <div className="congrets_wrapper">
                <div className="congrets_box">
                    <h5>Your order has been not placed</h5>
                    <h2>Try Again...</h2>
                    <h4>for any deduction contact us</h4>
                    <img alt="" className="" src="/images/congrets/gift.png" />

                </div>
             </div>

            
          
         
    
            <Footer />
        
            </main>
             
            
            </>
        );

    }else{
        
        return (
        
            <>
            <main>
            <Header />
           
                
             <div className="congrets_wrapper">
                <div className="congrets_box">
                    <h1>Congratulations!</h1>
                    <h5>Your order has been placed successfully</h5>
                    <h2>Enjoy your Subscription</h2>
                    <h1>Order Id : {order_id}</h1>
                    <img alt="" className="" src="/images/congrets/gift.png" />

                </div>
             </div>

    
            <Footer />
        
            </main>
             
            
            </>
        );
    }
      
    }  
    

export default ThankYou;