import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import {Loader} from '../../elements/Loader';
export const MarkAndReview= ({data,active_question,setData,technical_aptitude_question_type})=>{
    
     let current_data=data.data[technical_aptitude_question_type][active_question];
     let api_token=localStorage.getItem('_user_token');
     const [loader, setLoader] = useState(false);
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
     }; 
   
    
     function questionMarkReview(question_id,mark_and_review,event){
        setLoader(true);
        try {
                        
           
            current_data.mark_review=mark_and_review;
            current_data.taken_time=localStorage.getItem('seconds');
            data.data[technical_aptitude_question_type][active_question] = current_data;
            setData({status:true,message:"",data:data.data});

            axios.post(APP_URL+"test_question_mark_review",{question_id:question_id,mark_review:mark_and_review},{headers})
            .then(response=>{
               
                if (response.data.status===true) {
                   
                }else{
                    
                }
                setLoader(false);   
                
            })
            .catch(error=>{
                setLoader(false);
               
                
            })

           
        
      } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
       
    }

    }


       return (
        <>
        {loader ? <Loader />: ""}
        <button className="btn pr_btn" type="button" onClick={(event) => {questionMarkReview(current_data.id,(current_data.mark_review===1)?0:1, event)}}>Mark & Review</button>
        </>
        
            
        );
   
      
    }  
    

export default MarkAndReview;