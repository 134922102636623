

import {Header} from './elements/Header';
import {Footer} from "./elements/Footer";
import "../css/slick-theme.min.css";
import "../css/slick.min.css";
import Slider from "react-slick";
import { NavLink } from 'react-router-dom'
import { SEO } from './elements/SEO';

export const Home= ()=>{
   
    var blog_settings = {
        centerMode: false,
            infinite: false,
            speed: 300,
            slidesToShow: 2.5,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                  breakpoint: 990,
                  settings: {
                    slidesToShow: 1.5,
                  }
                },
                {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 1,
                    }
                  },
            ]
      };

      var settings = {
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 4000,
      };

      function goLogin() {
        window.location.href = '/login';
      }

      function blogDetail() {
        window.location.href = '/blog_list';
      }
     
      return (
        <>        
         <div>
            <SEO
                title='Premier Preparation for JEE, UPSC, NEET Exams : AbhyasKaro'
                description="Prepare for JEE, UPSC, and NEET exams with AbhyasKaro's comprehensive study resources and expert guidance. Ace your exams with top-notch materials and personalized learning. Start your journey to success today!"
                canonical='https://abhyaskaro.com/'
            />
        </div>
        <main>
    <Header />
    <div className="main-banner-wrapper">
        <div className="banner-one">
            <h1>A different way for education</h1>
            <p>Boost Your Success with Abhyas Karo's PYQs, Practice Questions & Test Series!</p>
            <div className="dl-btn">
                <button className="btn dark-btn" onClick={goLogin}>Get Started</button>                
                <button className="btn light-btn">Learn More</button>
            </div>
            <img alt="" className="tr-one" src="/images/Component39–1.png" />
            <img alt="" className="r-one" src="/images/Component38–1.png" />
        </div>
        <div className="banner-two">
            <img alt="" className="dot-r" src="/images/shap/dot-r.png" />
            <img alt="" className="br-shap" src="/images/shap/br-shap.svg" />
            <img alt="" className="c-orange" src="/images/shap/c-orange.png" />
            <div className="women-banner-wrapper">
                <div className="women-banner">
                    <img alt="GUJCET Previous Year Paper for Student" className="img-fluid" src="/images/women.png" />
                    <div className="alert-box">
                        <i className="lar la-bell"></i>
                        <p className="m-0">Get 20% off on <br/> every Subscription</p>
                    </div>
                    <div className="active-student">
                        <div className="d-flex">
                            <i className="las la-user-friends"></i>
                            <p>10K</p>
                        </div>
                        <p className="m-0">Total Active student take<br/> different practice Plans</p>

                    </div>
                    <div className="congrat">
                            <div className="d-flex">
                                <i className="las la-crown"></i> <h5>Congratulation</h5>
                            </div>
                        <p>Your Plan sucessfully Purchased</p>
                    </div>
                </div>
              
            </div>
            
        </div>
    </div>

    <section id="app-banner">
        <div className="container">
            <div className="app-banner-wrapper">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-md-12 col-12">
                        <div className="app-left">
                            <img alt="" className="app-left_arrow" src="/images/component411.png" />
                            <img alt="upsc previous year question paper" className="app-left_logo img-fluid" src="/images/group729.png" />
                            <h4>Get the Practicing App</h4>
                            <p>Elevate Learning on the Go with Abhyas Karo Exam Prep App!</p>
                            <div className="app-playstore d-flex">
                                {/*<a href="#" className=""><img alt="" className="img-fluid m-2 ml-0" src="/images/playstore-btn/apple-app.png" /></a>*/}
                                <a target="_blank" href="https://play.google.com/store/search?q=abhyasKaro&c=apps&hl=en-IN" className=""><img alt="" className="img-fluid m-2" src="/images/playstore-btn/android-app.png" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-09 col-12 d-flex">
                        <div className="app-right">
                            <img alt="Gate Previous Year Question Papers - Exam Preparation Resources" className="img-fluid" src="/images/app-view.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img alt="" className="watch-shap" src="/images/shap/watch.png" />
        <img alt="" className="r-b-orange" src="/images/shap/r-b-orange.png" />
    </section>
    
    
    {/* <GoogleLogin
        clientId="480490716781-odglqfr68h2pqgbu06tnfm3thmgp4sc7.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
      /> */}

    <section id="trending-plans">
        <div className="trending-plans">
            <div className="sec-header text-center">
                <h3>Our Trending <span> Practice Plans</span></h3>
                <p>Choose your course in our newest course and learn from our Instructor</p>
            </div>

            <div className="row justify-content-center">
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                    <div className="plan-card">
                        <img alt="neet previous year papers" className="" src="/images/plan-card/electronic.png" />
                        <h4 className="mb-0">NETWORK THEORY</h4>
                        <div className="review-bar">
                        <div className="review_right">
                                <div className="review_right-top d-flex">
                                    <div className="review_right-top-inner">
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                    </div>
                                    <p><span>200 +</span>Rating</p>
                                </div>
                                <div className="review_right-bottom d-flex justify-content-start align-items-center p-0">
                                    <h5>4.8</h5>
                                    <div className="d-flex">
                                        <i className="las la-star"></i>
                                        <i className="las la-star"></i>
                                        <i className="las la-star"></i>
                                        <i className="lar la-star"></i>
                                        <i className="lar la-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="review_left d-flex p-0">
                                <div className="review_left_inner d-flex m-auto w-100 justify-content-end">
                                    <img alt="" className="" src="/images/plan-card/user.png" />
                                    <p><span>10k +</span>Enrolled</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="gear_bg">
                            <div className="gear_bg-inner">
                                <img alt="jee main previous year question paper" className="" src="/images/plan-card/bg-gear.png" />
                            </div>
                        </div>
                        <div className="card_status">
                            <div className="card_status-box">
                                <small>Valid till</small>
                                <h3>06</h3>
                                <small><b>June, 23</b></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                    <div className="plan-card">
                        <img alt="CLASS 9 Test Paper" className="" src="/images/plan-card/electronic.png" />
                        <h4 className="mb-0">POWER SYSTEM</h4>
                        <div className="review-bar">
                            
                            <div className="review_right">
                                <div className="review_right-top d-flex">
                                    <div className="review_right-top-inner">
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                    </div>
                                    <p><span>200 +</span>Rating</p>
                                </div>
                                <div className="review_right-bottom d-flex justify-content-start align-items-center p-0">
                                    <h5>4.8</h5>
                                    <div className="d-flex">
                                        <i className="las la-star"></i>
                                        <i className="las la-star"></i>
                                        <i className="las la-star"></i>
                                        <i className="lar la-star"></i>
                                        <i className="lar la-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="review_left d-flex p-0">
                                <div className="review_left_inner d-flex m-auto w-100 justify-content-end">
                                    <img alt="" className="" src="/images/plan-card/user.png" />
                                    <p><span>10k +</span>Enrolled</p>
                                </div>
                            </div>
                        </div>
                        <div className="gear_bg">
                            <div className="gear_bg-inner">
                                <img alt="jee main previous year question paper" className="" src="/images/plan-card/bg-gear.png" />
                            </div>
                        </div>
                        <div className="card_status">
                            <div className="card_status-box">
                                <p>Start<br/> Demo</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                    <div className="plan-card">
                        <img alt="" className="" src="/images/plan-card/electronic.png" />
                        <h4 className="mb-0">ELCETRONIC NETWORK</h4>
                        <div className="review-bar">
                           
                            <div className="review_right">
                                <div className="review_right-top d-flex">
                                    <div className="review_right-top-inner">
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                        <img alt="" className="" src="/images/plan-card/user.png" />
                                    </div>
                                    <p><span>200 +</span>Rating</p>
                                </div>
                                <div className="review_right-bottom d-flex justify-content-start align-items-center p-0">
                                    <h5>4.8</h5>
                                    <div className="d-flex">
                                        <i className="las la-star"></i>
                                        <i className="las la-star"></i>
                                        <i className="las la-star"></i>
                                        <i className="lar la-star"></i>
                                        <i className="lar la-star"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="review_left d-flex p-0">
                                <div className="review_left_inner d-flex m-auto w-100 justify-content-end">
                                    <img alt="" className="" src="/images/plan-card/user.png" />
                                    <p><span>10k +</span>Enrolled</p>
                                </div>
                            </div>
                        </div>
                        <div className="gear_bg">
                            <div className="gear_bg-inner">
                                <img alt="jee main previous year question paper" className="" src="/images/plan-card/bg-gear.png" />
                            </div>
                        </div>
                        <div className="card_status">
                            <div className="card_status-box">
                                <p>Start<br/> Demo</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img alt="" className="tr-2" src="/images/shap/tr-2.png" />
        <img alt="" className="rb-yellow" src="/images/shap/r-b-yellow.png" />
    </section>



    <section id="experts">
        <div className="experts">
            <div className="row m-0">
                <div className="col-xxl-6 col-xl-6 col-md-12 col-12 d-flex">
                    <div className="experts_box">
                        <h1 className="sec-title">Guidance from the Expert Tutors.</h1>
                        <p>Personalized Learning Journeys Begin with Abhyas Karo's Tutors!</p>
                        <button className="btn dark-btn">
                            <h4 className="m-0">Want to read more?<b> Go here</b></h4>
                        </button>
                    </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-md-12 col-12 d-flex p-0">
                    <div className="experts_right">
                        <div className="experts_right-inner">
                            <div className="row m-0">
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="experts_card">
                                        <div>
                                            <img alt="Expert Speaker Sharing Insights at Abhyaskaro Conference" className="img-fluid" src="/images/experts/experts1.png" />
                                        </div>
                                        <div>
                                            <h5>Over 2K Practice Questions</h5>
                                            <p>2K+ Practice Questions for Exam Excellence: Explore Now!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="experts_card">
                                        <div>
                                            <img alt="GATE ECE Practice Questions" className="img-fluid" src="/images/experts/experts2.png" />
                                        </div>
                                        <div>
                                            <h5>Effective Practice Modules</h5>
                                            <p>Maximize Progress with Our Effective Practice Modules!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="experts_card">
                                        <div>
                                            <img alt="gujcet sample paper" className="img-fluid" src="/images/experts/experts3.png" />
                                        </div>
                                        <div>
                                            <h5>Topic & Subject-wise Practice Tests</h5>
                                            <p>Excel in exams and Boost Scores with Topic-wise Practice Tests!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <div className="experts_card">
                                        <div>
                                            <img alt="neet previous year question paper" className="img-fluid" src="/images/experts/experts4.png" />
                                        </div>
                                        <div>
                                            <h5>Analysis & Student Leaderboard</h5>
                                            <p>Track Progress and Gain Insights with Analysis & Student Leaderboard!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img alt="" className="sharp" src="/images/experts/experts5.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section id="ex-advanced">
        <div className="ex-advanced">
            <h1 className="sec-title">Experience Advanced Practice with:</h1>
            <div className="ex-click">
                <img alt="" className="img-fluid" src="/images/experience/right.svg" />
                <div className="d-flex">
                <p className="">Best Quality Questions & Solutions</p>
                </div>
            </div>
            <div className="ex-click">
                <img alt="" className="img-fluid" src="/images/experience/right.svg" />
                <div className="d-flex">
                <p className="">Detailed Performance Analysis</p>
                </div>
            </div>
            <div className="ex-click">
                <img alt="" className="img-fluid" src="/images/experience/right.svg" />
                <div className="d-flex">
                <p className="">Different Practice Test for you</p>
                </div>
            </div>
            <div className="ex-click">
                <img alt="" className="img-fluid" src="/images/experience/right.svg" />
                <div className="d-flex">
                <p className="">Previous year Question Papers</p>
                </div>
            </div>
        </div>
        <img alt="" className="wave-shap" src="/images/experience/wave.png" />
        <img alt="" className="c-orange" src="/images/shap/c-orange.png" />
        <img alt="" className="b-group" src="/images/b-group.png" />
        <img alt="" className="re-b-1" src="/images/shap/re-b-1.png" />
        <img alt="" className="watch2" src="/images/shap/watch2.png" />
    </section>



    <section id="counter">
        <div className="counter_box">
            <div className="counter_left">
                <div className="counter_left-inner d-flex">
                    <h1>100%</h1>
                    <p>Success Rate</p>
                </div>
                <p className="counter_left-details">
                    Join the Ranks of 100% Achievers: Your Success Story Starts Here!
                </p>
            </div>
            <div className="counter_right">
                <div className="counter_right-inner">
                    <div className="m-auto">
                        <h1>2K+</h1>
                        <p>Practice</p>
                        <span>Questions</span>
                    </div>
                </div>
                <div className="counter_right-inner">
                    <div className="m-auto">
                        <h1>1K+</h1>
                        <p>Students Practice</p>
                        <span>at Abhyas Karo</span>
                    </div>
                </div>
                <div className="counter_right-inner">
                    <div className="m-auto">
                        <h1>5 +</h1>
                        <p>Competitive Exam</p>
                        <span>Practice Tests</span>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section id="right-fy">
        <div className="right-fy">
            <h1>Choose the Competitive Practice Test Package</h1>
            {/*<p className="right-heading">There is place to find the preceptor job, browse wanted your jobs.</p>*/}
            <div className="right-exam">
                <div className="row m-0 justify-content-center">
                    <div className="col-xxl-4 col-xl 4 col-md-6 col-12">
                        <div className="asses-card">
                            <img alt="jee mains previous year paper" className="" src="/images/right-fy/jee.png" />
                            <h2>JEE</h2>
                            <p>Your JEE Triumph Awaits. Discover Our Ultimate Practice Test Package!</p>                            
                            <button className="btn" onClick={goLogin} type="">Get Started</button>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl 4 col-md-6 col-12">
                        <div className="asses-card">
                            <img alt="upsc previous year paper" className="" src="/images/right-fy/upsc.png" />
                            <h2>UPSC</h2>
                            <p>Ace UPSC with Precision. Discover Our Specialized Practice Test Package!</p>
                            <button className="btn" onClick={goLogin} type="">Get Started</button>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl 4 col-md-6 col-12">
                        <div className="asses-card">
                            <img alt="neet previous year question paper pdf" className="" src="/images/right-fy/jee.png" />
                            <h2>NEET</h2>
                            <p>Unlock NEET Mastery by diving into Our Expert Practice Test Package!</p>
                            <button className="btn" onClick={goLogin} type="">Get Started</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img alt="" className="c-orange" src="/images/shap/c-orange.png" />
        <img alt="" className="dot-r" src="/images/shap/dot-r.png" />
        <img alt="" className="br-shap" src="/images/shap/br-shap.svg" />
    </section>



    <section id="bloge">
        <div className="row m-0">
            <div className="col-xl-4 col-md-12 col-12">
                <div className="bloge_details">
                    <h1>Dive into Insights.</h1>
                    <p className="text-start">Explore Our Informative Blogs.</p>
                    <button className="btn dark-btn">
                        <h4  className="m-0">Want to read more?<b> <NavLink to="/blog_list">Go here</NavLink></b></h4>
                    </button>
                </div>
            </div>
            <div className="col-xl-8 col-md-12 col-12 p-0">
                <div className="bloge_slider">
                    
                    <Slider {...blog_settings}>
                               
                                         
                    <div className="item">
                            <div className="bloge-box">
                                <img alt="" className="img-fluid" src="/images/blog/blog1.jpg" />
                                <div className="blog-title">
                                    <h5>The Great challenge of global learning</h5>
                                    <div className="blog-date">
                                        <div className="">
                                        <p className="m-0">10</p>
                                        <span>April</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="bloge-box">
                                <img alt="" className="img-fluid" src="/images/blog/blog2.jpg" />
                                <div className="blog-title">
                                    <h5>Why we are different for overall world teach</h5>
                                    <div className="blog-date">
                                        <div className="">
                                            <p className="m-0">10</p>
                                          <span>April</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="bloge-box">
                                <img alt="" className="img-fluid" src="/images/blog/blog3.jpg" />
                                <div className="blog-title">
                                    <h5>The Great challenge of global learning</h5>
                                    <div className="blog-date">
                                        <div className="">
                                        <p className="m-0">10</p>
                                        <span>April</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="bloge-box">
                                <img alt="" className="img-fluid" src="/images/blog/blog3.jpg" />
                                <div className="blog-title">
                                    <h5>The Great challenge of global learning</h5>
                                    <div className="blog-date">
                                        <div className="">
                                        <p className="m-0">10</p>
                                        <span>April</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="bloge-box">
                                <img alt="" className="img-fluid" src="/images/blog/blog3.jpg" />
                                <div className="blog-title">
                                    <h5>The Great challenge of global learning</h5>
                                    <div className="blog-date">
                                        <div className="">
                                        <p className="m-0">10</p>
                                        <span>April</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                                </Slider>

                </div>
            </div>
        </div>
        
      
    </section>


    <section id="students-review">
        <div className="review-wrapper">
            <div className="review-heading ">
                <h1>
                    Real Success <br />Stories:
                </h1>
                <p>Discover Inspiring Reviews from Our Achievers!</p>
            </div>
            <div className="review-box ">
            <Slider {...settings}>
              <div className="item">
                          <div className="review-slider">
                              <img alt="" className="img-fluid" src="/images/reviews/student1.png" />
                              <div className="review-details position-relative">
                                  <p className="">Abhyas Karo has been my guiding light! Their topic-wise practice tests helped me ace my NEET exams. The detailed analysis provided after each test was a game-changer. I can confidently say, I owe my success to Abhyas Karo!</p>
                                  <div className="">
                                      <h3>- Dr. Nita Roy</h3>
                                      <p className="s-post"></p>
                                  </div>
                                  <img alt="" className="app-left_arrow" src="/images/component411.png" />
                              </div>
                          </div>
                         
                      </div>
                      
                       <div className="item">
                          <div className="review-slider">
                              <img alt="" className="img-fluid" src="/images/reviews/student1.png" />
                              <div className="review-details position-relative">
                                  <p className="">I can't thank Abhyas Karo enough for their JEE practice test pack. The variety of questions and the simulated exam environment truly prepared me. Thank you, Abhyas Karo!</p>
                                  <div className="">
                                      <h3>- Amit Kotadia</h3>
                                      <p className="s-post"></p>
                                  </div>
                                  <img alt="" className="app-left_arrow" src="/images/component411.png" />
                              </div>
                          </div>
                         
                      </div>
                </Slider>
            </div>
          
        </div>
        <img alt="" className="br-shap" src="/images/shap/br-shap.png" />
    </section>


    <section id="rg-bar">
        <div className="rg-bar-wrapper">
        <div className="rg-bar">
                <h1>Don't Wait, <span>Just Elevate</span>:</h1>
                <p>Enroll with Abhyas Karo for Excellence today!</p>
                <button className="btn" onClick={goLogin} type="">Get Started</button>
                <img alt="GATE CS Previous Year Question bank" className="br-shap" src="/images/shap/br-shap.svg" />
        </div>
        <img alt="GATE ECE Online Test Series" className="footer-img" src="/images/footer-img-1.png" />
    </div>
      
    </section>


        {/* <div className="sidebar_wrap" id="sidebar_wrap" >
            <div className="sidebar_top">
                <span id="exit" className="blue">&times;</span>
                <h1 className=" blue">Login</h1>
                <a href="" className="cr_account blue">or<span className="orange ms-2">Create Account</span></a>
                <div className="input-group mb-3 mt-4">
                    
                    <div className="dropdown input-group-text p-0" id="basic-addon1">
                        <button className="btn btn-secondary dropdown-toggle d-flex" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt="" className="" src="" />
                      <h4 className="m-0">+91 <i className="las la-angle-down"></i></h4>
                        </button>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="#">Action</a></li>
                          <li><a className="dropdown-item" href="#">Another action</a></li>
                          <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                      </div>
                    <input type="text" className="form-control" placeholder="Enter Mobile Number" aria-label="Username" aria-describedby="basic-addon1" />
                  </div>
                  <a href="" className="cn_email">Continue with email</a>
                  <button className="btn rounded-pill sub_btn" type="">Login</button>
                  
            </div>
            <div className="sidebar_bottom">
                <p className="m-0">Having trouble? Please contact help@Practicekiya.com for further support.</p>
            </div>
        </div> */}

    
        <Footer />

          </main>

        
        </>
      )
}
export default Home;