import React from "react";

import { NavLink,useNavigate } from 'react-router-dom';
const _token=localStorage.getItem("_user_token");
export const Footer= ()=>{
   
return (
         
         <>

<div className="footer_wrapper">
<div className="footer">
    <div className="row m-0 justify-content-start">
        <div className="col-xxl-4 col-xl-4 col-md-4 col-sm-6 col-12">
             {((_token!=null)?<NavLink className="d-block" to="/dashboard"> <img className="img-fluid footer_logo" src="/images/Final-Logo.png" /></NavLink>:<NavLink className="d-block" to="/"> <img className="img-fluid footer_logo" src="/images/Final-Logo.png" /></NavLink>)}
               
                <p className="mb-xxl-4 mb-xl-4 mb-md-2 mb-sm-2 mb-2">Welcome to Abhayshkaro, an educational platform that offers an innovative approach to learning through comprehensive question sets and detailed analysis.</p>
        </div>
        {/* <div className="col-xxl-2 col-xl-2 col-md-6 col-12 offset-xxl-1 offset-xl-1 d-flex"> */}
        <div className="col-xxl-2 col-xl-3 col-md-2 col-sm-6 col-12 d-flex">
            <div className="footer_link fl_saprate">
            <h3>LINKS</h3>
            {/* <a href="">Goals  </a>
            <a href=""> Event </a>
            <a href="">Gallery  </a>
            <a href="">Faqs </a>
            <a href="">  Teachers </a> */}
            <NavLink to="/about_us">About Us</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/privacy_policy">Privacy Policy</NavLink>
            <NavLink to="/terms_and_conditions">Terms and Conditions</NavLink>
            </div>
        </div>
        <div className="col-xxl-3 col-xl-2 col-md-2 col-sm-6 col-12 d-flex">
            <div className="footer_link fl_saprate">
            <h3>PRODUCTS</h3>
                <NavLink to="/dashboard">Practice </NavLink>
                <NavLink to="/dashboard">Test </NavLink>
                <NavLink to="/dashboard">PYQs</NavLink>
            </div>
        </div>
        <div className="col-xxl-3 col-xl-3 col-md-4 col-sm-6 col-12 d-flex">
        <div className=" footer_link ">
        <h3>CONTACT</h3>
                    <div className="d-flex">
                        <i className="las la-phone-volume"></i> <p><NavLink to="tel:+919023252952">+91 90232 52952</NavLink></p>
                    </div>
                    <div className="d-flex">
                        <i className="lar la-envelope"></i> <p className="m-0"><NavLink to="mailto:info@abhyaskaro.com">info@abhyaskaro.com</NavLink></p>
                    </div>
                    <div className="soc-btn d-flex justify-content-evenly">
                  <NavLink target="_blank" to="https://www.facebook.com/abhyaskaro/"> <i className="lab la-facebook"></i></NavLink>
                  <NavLink target="_blank" to="https://www.instagram.com/abhyaskaro/"> <i className="lab la-instagram"></i></NavLink>
                  <NavLink target="_blank" to="https://twitter.com/abhyaskaro"> <i className="lab la-twitter"></i></NavLink>
                  <NavLink target="_blank" to="https://www.youtube.com/@theabhyaskaro"> <i className="lab la-youtube"></i></NavLink>
                  {/*<a href=""> <i className="lab la-linkedin"></i></a>*/}
                </div>
                </div>
               
        </div>
        {/* <div className="col-xxl-3 col-xl-3 col-md-6 col-12 d-flex">
            <div className="footer_link text-center ">
            <h3 className="text-start text-xl-center text-md-center">OUR FEEDS</h3>
            <div className="feed-img">
                <a href=""><img className="img-fluid" src="/images/footer/feed1.png" /></a>
                <a href=""><img className="img-fluid" src="/images/footer/feed2.png" /></a>
                <a href=""><img className="img-fluid" src="/images/footer/feed3.png" /></a>
                <a href=""><img className="img-fluid" src="/images/footer/feed4.png" /></a>
            </div>
            </div>
        </div> */}
    </div>

</div>
</div>

          </>
        
         );

}