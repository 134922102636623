import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';

import { APP_URL } from '../constants/global'
import { useNavigate } from 'react-router-dom'


export const LoginWithAdmin= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
   
    function getCookie(cookieName) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
          let [key,value] = el.split('=');
          cookie[key.trim()] = value;
        })
        return cookie[cookieName];
      }
    let token=decodeURI(getCookie('admin_login_api_token'));
      
    useEffect(() => { 
        
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
        }; 
    
    setLoader(true);
    axios.post(APP_URL+"admin_login_student",{},{headers})
    .then(response=>{
        setLoader(false);   
        if (response.data.status===true) {
            localStorage.setItem('_user_token', response.data.api_token);   
            localStorage.setItem('_user_data', JSON.stringify(response.data.data));
            if (response.data.data.is_category_selected<=0) {
                navigate("/complete_profile");    
            }else{
                navigate("/dashboard");    
            }
            
        }else{
           
        }
        //localStorage.setItem('_token', response.data.token);
        //localStorage.setItem('_token', response.data.token);
        //localStorage.setItem('user', "Yamin Saiyed");
        //localStorage.removeItem('_token');
        
    })
    .catch(error=>{
        setLoader(false);
        if (error.response.status===401) {
  
            
            
        }
        
    })

     return () => {
          
      }

    },[]);

      return (
        <>
        <main>
         <Header />
         {loader ? <Loader />: ""}
        
          <Footer />
          </main>
         
        
        </>
      )
}
export default LoginWithAdmin;