/*const APP_URL = 'http://dev.abhyaskaro.local/api/v1/';
const IMAGE_URL = 'http://dev.abhyaskaro.local/storage/'; 
const MAIN_URL = 'http://dev.abhyaskaro.local/';*/ 
/* const APP_URL = 'http://13.232.124.116/backend/api/v1/';
const IMAGE_URL = 'http://13.232.124.116/backend/storage/';
const MAIN_URL = 'http://13.232.124.116/backend/'; */
const APP_URL = 'https://abhyaskaro.com/backend/api/v1/';
const IMAGE_URL = 'https://abhyaskaro.com/backend/storage/';
const MAIN_URL = 'https://abhyaskaro.com/backend/';
const themeDefault = 'dark';
const namesOfModes = ['dark', 'moonlight', 'eclipse', 'light'];

export { APP_URL, themeDefault, namesOfModes,IMAGE_URL ,MAIN_URL}