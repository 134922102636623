import React from "react";
import { NavLink } from 'react-router-dom';
export const ProfileSideMenu= ()=>{
   
return (
    <div className="side_bar">
    <ul>
    <li>
    <NavLink to="/dashboard"><i className="las la-home"></i><span>Home</span></NavLink></li>
    <li> <NavLink to="/offer"><i className="las la-percentage"></i><span>Offer</span></NavLink></li>
    <li><NavLink to="/order_list"><span><i className="las la-shopping-cart"></i><span>Order</span></span></NavLink> </li>
    <li><NavLink to="/my_plan"><span><i className="las la-tag"></i><span>My Plan</span></span></NavLink> </li>
    <li><NavLink to="/edit_profile"><span><i className="las la-user"></i><span>User</span></span></NavLink></li>
    </ul>
    </div>
);

}
