import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL,IMAGE_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';

export const SubjectBySubscription= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    
   let pageDefault=location.state;


    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
        
      }); 
      const navigate = useNavigate();
      let redirect_url="/";
        if (pageDefault.type==="practice") {
        redirect_url="/practice_chapter";
        }else if (pageDefault.type==="pyq") {
            redirect_url="/pyq_chapter";
        }else if (pageDefault.type==="test") {
            redirect_url="/test_topic_wise";
        }
      useEffect(() => { 
      
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_subject_by_subscription',{headers,params:pageDefault},[])
           .then(response=>{
            setLoader(false);
           
            setData({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
              }); 
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
        //console.log(e);
    }

       return () => {
            
        }

      },[pageDefault]);
      


        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
             <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                               
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex">
                                    <h1>{pageDefault.subscription_name}</h1>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">{pageDefault.type}</button>
                                    </div>
                                </div>
                              
                              
                                <div className="page_overlay"></div>
                               
                            </div>

                             <ProfileSideMenu />
                    </div>

    
             <div className="s_plan-wrapper">
            
             <h2 className="co_heading">Subscription Subject List</h2>
                <div className="s_plan">
                { data.status===true && data.data.map((subject,key) => ( 
                    <div className="plan-card valid" key={key}>
                        <img alt="" className="el_img" src={subject.image?IMAGE_URL+subject.image:"/images/plan-card/electronic.png"} />
                        <h4>{subject.subject_name}</h4>
                        <div className="review-bar">
                            <div className="review_left">
                                <div className="sub_box">
                                <NavLink to={redirect_url} className="g_btn" state={{subject_id:subject.subject_id,subject_name:subject.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:subject.image,subscription_id:pageDefault.subscription_id}}>Start Now</NavLink>
                                </div>
                            </div>
                           
                        </div>
                        <div className="gear_bg">
                            <div className="gear_bg-inner">
                                <img alt="" className="" src="/images/plan-card/bg-gear.png" />
                            </div>
                        </div>
                        
                        <div className="card_status">
                            <div className="card_status-box">                                
                                <NavLink to={redirect_url} className="" state={{subject_id:subject.subject_id,subject_name:subject.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:subject.image,subscription_id:pageDefault.subscription_id}}><p>Start <br/> Now</p></NavLink>
                            </div>
                        </div>
                        <NavLink to={redirect_url} className="full_link" state={{subject_id:subject.subject_id,subject_name:subject.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:subject.image,subscription_id:pageDefault.subscription_id}}></NavLink>
                    </div>
                    ))}

                </div>
            </div>

          




             <Footer />
              </main>
             
            
            </>);
    }  
    

export default SubjectBySubscription;