import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';

export const PyqSubjectAnalysis= ()=>{
    
    let user_data=JSON.parse(localStorage.getItem('_user_data'));
  
    let api_token=localStorage.getItem('_user_token');
    const [loader, setLoader] = useState(false);
    const location = useLocation();
   
    let pageDefault=location.state;

    
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
        current_user:null,
        top_three_user:null,
      }); 
      
      const navigate = useNavigate();
      
      useEffect(() => { 
              
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_pyq_leader_board',{headers,params:{topic_id:pageDefault.topic_id}},[])
           .then(response=>{
            setLoader(false);
            
              setData({
                status: response.data.status,
                message: response.data.message,
                data:response.data.data,
                current_user:response.data.current_user,
                top_three_user:response.data.top_three_user,
              });
             
             
            
             
           })
           .catch(error=>{

            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data:null,
                current_user:null,
                top_three_user:null,
              });
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data:null,
            current_user:null,
            top_three_user:null,
          });
        //console.log(e);
    }

       return () => {
            
        }

      },[pageDefault]);
      
     
        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}


                    {/* Inner _age Banner End */}
                    <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.chapter_name}   </span>
                                    <h3 className="text-uppercase text-left">{pageDefault.topic_name}</h3>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Pyq</button>
                                    </div>
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          
                            

                        <NavLink to="/pyq_topic_analysis" className="nav-link" state={{topic_id:pageDefault.topic_id,topic_name:pageDefault.topic_name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}>Analysis</NavLink>
                        
                         
                          <NavLink to="/pyq_topic_bookmark" className="nav-link" state={{topic_id:pageDefault.topic_id,topic_name:pageDefault.topic_name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}>saved</NavLink>
                          

                          <button className="nav-link active" id="nav-analysis-tab" data-bs-toggle="tab" data-bs-target="#nav-analysis" type="button" role="tab" aria-controls="nav-analysis" aria-selected="false">Leader Board</button>

                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        
                        <div className="tab-pane fade show active" id="nav-analysis" role="tabpanel" aria-labelledby="nav-analysis-tab">
                            
                        <div className="winner_heade">
                <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                        <div className="winner_stage-wrapper position-relative">
                            <img alt="" className="img-fluid" src="/images/winner/stage.png" />
                            <div className="winner_stage">
                                
                            { data.status===true && data.top_three_user.map((top_three_user_single,key) => ( 
                                
                                <div className={"text-center w_"+top_three_user_single.rank_no} key={key}>
                                    <span>{top_three_user_single.fullname}</span>
                                    <div className="w_img">
                                        <img alt="" className="img-fluid" src={top_three_user_single.image!=null?IMAGE_URL+top_three_user_single.image:"/images/user.png"} />
                                    </div>
                                    <div className="w_rank">
                                        <h5>{top_three_user_single.percentage!=null?parseFloat(top_three_user_single.percentage).toFixed(2):0}%</h5>
                                    </div>
                                </div>
                            ))}

                        {data.status===true && data.top_three_user.length===1?
                            <><div className={"text-center w_2"}>
                            <span>Demo User</span>
                            <div className="w_img">
                                <img alt="" className="img-fluid" src="/images/user.png" />
                            </div>
                            <div className="w_rank">
                                <h5>0.00%</h5>
                            </div>
                        </div><div className={"text-center w_3"}>
                            <span>Demo User</span>
                            <div className="w_img">
                                <img alt="" className="img-fluid" src="/images/user.png" />
                            </div>
                            <div className="w_rank">
                                <h5>0.00%</h5>
                            </div>
                        </div></>:""}

                        {data.status===true && data.top_three_user.length===2?
                            <><div className={"text-center w_3"}>
                            <span>Demo User</span>
                            <div className="w_img">
                                <img alt="" className="img-fluid" src="/images/user.png" />
                            </div>
                            <div className="w_rank">
                                <h5>0.00%</h5>
                            </div>
                        </div></>:""}



                               

                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-12 col-12 d-flex">
                        <div className="top_win m-auto">
                                
                        { data.status===true && data.data.map((result,key) => ( 
                              
                              <React.Fragment key={key}>
                                
                                {result.id===user_data.id?<div className="win_bar win_you w-100">
                                <div className="row justify-content-between">
                                    <div className="col-6">
                                        <div className="w_no d-flex">
                                                <span className="me-3">{result.rank_no}</span>
                                                <div className="w_no-img">
                                                <img alt="" className="img-fluid  " src={result.image!=null?IMAGE_URL+result.image:"/images/user.png"} />
                                               
                                                </div>
                                                <span>You</span>
                                        </div>
                                    </div>
                                    <div className="col-4 d-flex">
                                        <span className="win_hick">{result.percentage!=null?parseFloat(result.percentage).toFixed(2):0}%</span>
                                    </div>
                                </div>
                            </div>:<div className="win_bar">
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <div className="w_no d-flex">
                                            <span className="me-3">{result.rank_no}</span>
                                            <div className="w_no-img">
                                            <img alt="" className="img-fluid  " src={result.image!=null?IMAGE_URL+result.image:"/images/user.png"} />
                                           
                                            </div>
                                            <span>{result.fullname}</span>
                                    </div>
                                </div>
                                <div className="col-4 d-flex">
                                    <span className="win_hick">{result.percentage!=null?parseFloat(result.percentage).toFixed(2):0}%</span>
                                </div>
                            </div>
                        </div>}
                            </React.Fragment>   
                        ))}
                        
                               
                                
                        </div>
                    </div>
                </div>
        </div>
                      
                        </div>
                        
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


                            
  
            <ProfileSideMenu />

          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PyqSubjectAnalysis;