import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {finalDateFormat,getDateTimeFormat} from './elements/AppHelper';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
export const TestSubjectWise= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    
    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
        
      }); 
      let pageDefault=location.state;

    
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + api_token
    }; 

    useEffect(() => { 
        
        try {
      
            setLoader(true);
       
            axios.get(APP_URL+'get_test_by_subject',{headers,params:{subject_id:pageDefault.subject_id,page:1}},[])
            .then(response=>{
             setLoader(false);
            
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data: response.data.data,
               });
              
               
             
              
            })
            .catch(error=>{
                if (error.response.status===401) {
                    localStorage.clear();
                    navigate("/login");   
                }
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data: null,
               });
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data: null,
           });
         //console.log(e);
     }
 
        return () => {
             
         }
 
       },[pageDefault]);

       function activation_date_popup(activation_date_time){
        alert("Test will be lived on "+getDateTimeFormat(activation_date_time));
    }
       return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
            
             {/* Inner _age Banner End */}
             <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.subject_name} </span>
                                    
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle drop-d-left" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        FULL SUBJECT TEST
                                        </button>
                                        <ul className="dropdown-menu drop-d-inner" aria-labelledby="dropdownMenuButton1">
                                          <li><a className="dropdown-item active">full subject test</a></li>
                                          <li>
                                           <NavLink to="/test_topic_wise" className="dropdown-item" state={{subject_id:pageDefault.subject_id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:pageDefault.sub_category_image}}>topic wise test</NavLink>
                                          </li>
                                      
                                            
                                        </ul>
                                      </div>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Test</button>
                                    </div>
                                </div>
                                <div className="page_overlay "></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


           
           {/*--Subject-wise-sec-start----*/}

           <div className="sub_w-wrapper">
           { data.status===true && data.data.map((result,key) => ( 

            <div className="sw_card" key={key}>
                <h4>{result.test_name}</h4>
               
                <>
                        {(() => {
                           
                            if (result.test_status===1) {

                                if (result.can_start_test===1) {
                                    return (

                                        <NavLink to="/test_question" className="w-100 btn rounded-pill d-orange d-flex text-white align-items-center justify-content-between" state={{test_id:result.id,test_name:result.test_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image,description:result.description}}><span>Start Now</span> <i className="las la-chevron-circle-right"></i></NavLink>
                                        
                                        )        
                                }else{
                                    return (
                                        <>
                                        <div className="active_date m-auto">
                                        <span>Activation: {finalDateFormat(result.activation_date_time)}</span>
                                        </div>
                                        <button className="w-100 text-white  btn rounded-pill d-blue d-flex" onClick={()=>activation_date_popup(result.activation_date_time)}><span>Comming Soon</span><i className="las la-chevron-circle-right"></i></button>
                                        </>
                                        )        
                                }
                            

                            } else if (result.test_status===2) {
                            return (
                                <>
                               
                               <NavLink to="/test_question" className="w-100 btn rounded-pill gr-green d-flex text-white align-items-center justify-content-between" state={{test_id:result.id,test_name:result.test_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image,description:result.description}}><span>Resume</span><i className="las la-pause"></i></NavLink>


                                </>
                                
                            )
                            } else {
                            return (
                               <NavLink to="/test_score_card" className="w-100 btn rounded-pill gr-purple d-flex text-white align-items-center justify-content-between" state={{test_id:result.id,test_name:result.test_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image}}><span>Score Board</span><i className="las la-chevron-circle-right"></i></NavLink>
                            )
                            }
                        })()}
                        </>

                


                <div className="gear_bg w-100 position-absolute top-0 start-50 translate-middle">
                    <div className="gear_bg-inner">
                        <img alt="" className="img-fluid" src="/images/plan-card/bg-gear.png" />
                    </div>
                </div>
            </div>
            
            ))}
           
          
           </div>
           

            {/*-----Subject-wise-sec-end--*/}


            
  
            <ProfileSideMenu />
         
    


             <Footer />
              </main>
             
            
            </>);
    }  
    

export default TestSubjectWise;