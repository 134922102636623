import './App.css';

import "./css/bootstrap.min.css";
/* import "./css/owl.carousel.min.css";
import "./css/owl.theme.min.css"; */
import "./css/line-awesome.min.css";
import "./css/style.css";
import "./css/media.css";



import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {IsUser,IsGuest} from './components/general/AuthLogin';
import LoginWithGmail from './components/LoginWithGmail';
import LoginWithAdmin from './components/LoginWithAdmin';
import Login from './components/Login';
import Register from './components/Register';
import RegisterVerifyOtp from './components/RegisterVerifyOtp';
import RegisterVerifyOtpGmail from './components/RegisterVerifyOtpGmail';
import CompleteProfile from './components/CompleteProfile';
import SelectGoal from './components/SelectGoal';
import Dashboard from './components/Dashboard';
import Subscription from './components/Subscription';
import SubscriptionDetails from './components/SubscriptionDetails';
import SubjectBySubscription from './components/SubjectBySubscription';
import SubscriptionCart from './components/SubscriptionCart';
import BillingInformation from './components/BillingInformation';
import PracticeChapter from './components/PracticeChapter';
import PracticeTopic from './components/PracticeTopic';
import PracticeQuestion from './components/PracticeQuestion';
import PracticeSubjectAnalysis from './components/PracticeSubjectAnalysis';
import PracticeSubjectBookmark from './components/PracticeSubjectBookmark';
import ThankYou from './components/ThankYou';
import PracticeLeaderBoard from './components/PracticeLeaderBoard';
import PracticeTopicAnalysis from './components/PracticeTopicAnalysis';
import PracticeTopicBookmark from './components/PracticeTopicBookmark';
import Offer from './components/Offer';
import OrderList from './components/OrderList';
import MyPlan from './components/MyPlan';
import EditProfile from './components/EditProfile';
import EditGoal from './components/EditGoal';



import PyqChapter from './components/PyqChapter';
import PyqTopic from './components/PyqTopic';
import PyqQuestion from './components/PyqQuestion';
import PyqSubjectAnalysis from './components/PyqSubjectAnalysis';
import PyqSubjectBookmark from './components/PyqSubjectBookmark';
import PyqLeaderBoard from './components/PyqLeaderBoard';
import PyqTopicAnalysis from './components/PyqTopicAnalysis';
import PyqTopicBookmark from './components/PyqTopicBookmark';
import PyqPdf from './components/PyqPdf';


import TestTopicWise from './components/TestTopicWise';
import TestSubjectWise from './components/TestSubjectWise';
import TestBySingleTopic from './components/TestBySingleTopic';
import TestQuestion from './components/TestQuestion';
import TestSolution from './components/TestSolution';
import TestScoreCard from './components/TestScoreCard';
import TestAnalysisTopicWise from './components/TestAnalysisTopicWise';
import TestAnalysisQuestionWise from './components/TestAnalysisQuestionWise';
import TestAnalysisCompetitive from './components/TestAnalysisCompetitive';

import BlogList from './components/BlogList';
import BlogDetails from './components/BlogDetails';

import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import AboutUs from './components/AboutUs';
import Testimonials from './components/Testimonials';
import Home from './components/Home';

function App() {
  return (
    <BrowserRouter>
        <Routes>
        <Route exact path="/" element={<Home />} ></Route> 
        <Route exact path="/blog_list" element={<BlogList />} ></Route>
        <Route exact path="/blog_details/:seo_url" element={<BlogDetails />} ></Route>
        <Route exact path="/offer" element={<Offer />} ></Route>
        <Route exact path="/contact" element={<Contact />} ></Route>
        <Route exact path="/privacy_policy" element={<PrivacyPolicy />} ></Route>
        <Route exact path="/terms_and_conditions" element={<TermsAndConditions />} ></Route>
        <Route exact path="/about_us" element={<AboutUs />} ></Route>
        <Route exact path="/testimonials" element={<Testimonials />} ></Route>

         <Route exact element={<IsUser/>}>
          <Route exact path="/complete_profile" element={<CompleteProfile />} ></Route>
          <Route exact path="/select_goal" element={<SelectGoal />} ></Route>
          <Route exact path="/dashboard" element={<Dashboard />} ></Route>
          <Route exact path="/subscription" element={<Subscription />} ></Route>
          <Route exact path="/subscription_details" element={<SubscriptionDetails />} ></Route>
          <Route exact path="/subject_by_subscription" element={<SubjectBySubscription />} ></Route>
          <Route exact path="/practice_chapter" element={<PracticeChapter />} ></Route>
          <Route exact path="/subscription_cart" element={<SubscriptionCart />} ></Route>
          <Route exact path="/billing_information" element={<BillingInformation />} ></Route>
          <Route exact path="/practice_topic" element={<PracticeTopic />} ></Route>
          <Route exact path="/practice_question" element={<PracticeQuestion />} ></Route>
          <Route exact path="/practice_subject_analysis" element={<PracticeSubjectAnalysis />} ></Route>
          <Route exact path="/practice_subject_bookmark" element={<PracticeSubjectBookmark />} ></Route>
          <Route exact path="/thankyoufororder/:order_id" element={<ThankYou />} ></Route>
          <Route exact path="/practice_leader_board" element={<PracticeLeaderBoard />} ></Route>
          <Route exact path="/practice_topic_analysis" element={<PracticeTopicAnalysis />} ></Route>
          <Route exact path="/practice_topic_bookmark" element={<PracticeTopicBookmark />} ></Route>
          <Route exact path="/order_list" element={<OrderList />} ></Route>
          <Route exact path="/my_plan" element={<MyPlan />} ></Route>
          <Route exact path="/edit_profile" element={<EditProfile />} ></Route>
          <Route exact path="/edit_goal" element={<EditGoal />} ></Route>
          

          <Route exact path="/pyq_chapter" element={<PyqChapter />} ></Route>
          <Route exact path="/pyq_topic" element={<PyqTopic />} ></Route>
          <Route exact path="/pyq_question" element={<PyqQuestion />} ></Route>
          <Route exact path="/pyq_subject_analysis" element={<PyqSubjectAnalysis />} ></Route>
          <Route exact path="/pyq_subject_bookmark" element={<PyqSubjectBookmark />} ></Route>
          <Route exact path="/pyq_leader_board" element={<PyqLeaderBoard />} ></Route>
          <Route exact path="/pyq_topic_analysis" element={<PyqTopicAnalysis />} ></Route>
          <Route exact path="/pyq_topic_bookmark" element={<PyqTopicBookmark />} ></Route>
          <Route exact path="/pyq_pdf" element={<PyqPdf />} ></Route>

          <Route exact path="/test_topic_wise" element={<TestTopicWise />} ></Route>
          <Route exact path="/test_subject_wise" element={<TestSubjectWise />} ></Route>
          <Route exact path="/test_by_single_topic" element={<TestBySingleTopic />} ></Route>
          <Route exact path="/test_question" element={<TestQuestion />} ></Route>
          <Route exact path="/test_solution" element={<TestSolution />} ></Route>
          <Route exact path="/test_score_card" element={<TestScoreCard />} ></Route>
          <Route exact path="/test_analysis_topic_wise" element={<TestAnalysisTopicWise />} ></Route>
          <Route exact path="/test_analysis_question_wise" element={<TestAnalysisQuestionWise />} ></Route>
          <Route exact path="/test_analysis_competitive" element={<TestAnalysisCompetitive />} ></Route>

         </Route>
    
         <Route exact element={<IsGuest/>}>
         <Route exact path="/login_with_gmail" element={<LoginWithGmail />} ></Route>
         <Route exact path="/login_with_admin" element={<LoginWithAdmin />} ></Route>
         <Route exact path="/login" element={<Login />} ></Route>
         <Route exact path="/register" element={<Register />} ></Route>
         <Route exact path="/register_verify_otp" element={<RegisterVerifyOtp />} ></Route>
         <Route exact path="/register_verify_otp_gmail" element={<RegisterVerifyOtpGmail />} ></Route>
         </Route>
         
        </Routes>
      </BrowserRouter>
  );
}

export default App;
