import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';

import { APP_URL} from '../constants/global';
import {NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { SEO } from './elements/SEO';
export const BlogList= ()=>{
  
   const [data, setData] = useState({
      status:false,
      message:"",
      data:null,
    }); 
    let api_token=localStorage.getItem('_user_token');
    
    useEffect(() => { 
      try {
       const headers = {
           "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
           }; 

          axios.get(APP_URL+'get_blog_list',{headers},[])
          .then(response=>{              
           setData({
               status: response.data.status,
               message: response.data.message,
               data: response.data.data,
             });

          })
          .catch(error=>{
         
           setData({
               status: false,
               message: "",
               data: null,
             });
          
          })
 
  
        } catch (e) { //code will jump here if there is a network problem
         
       setData({
           status: false,
           message: "",
           data: null,
         });
   }

      return () => {
           
       }

     },[]);
 
       return (
      
            <>
            <div>
            <SEO
            title='Mastering JEE, UPSC, and NEET: Expert Tips and Insights - AbhyasKaro'
            description='Navigate the complexities of JEE, UPSC, and NEET with precision. Explore our AbhyasKaro blog for expert advice, study strategies, and latest updates to excel in your exams. Unlock success today!'
            canonical='https://abhyaskaro.com/blog_list'
             />
            </div>
            <main>
             <Header />
                          
         
           <div className="container mt-5">
            <div className="y_exam-heading">
            <h3 className="m-0">Blog List</h3>
            </div>

           <div className="row mb-5">
           {data.status===true ? (
            data.data.map((result,key) => (
                <div className="col-xl-4 col-md-6 col-sm-12 col-12" key={key}>
                    <div className="blog_box">
                       <div className="blog_img">
                       <img alt={result.name} className="img-fluid" src={IMAGE_URL+result.image} />
                       </div>
                       <div className="blod_title">
                          <h3>{result.name}</h3>

                          <NavLink to={`/blog_details/${result.seo_url}`} className="d-block text-end" state={{blog_id:result.seo_url}}>Read More...</NavLink>

                       </div>
                    </div>
                </div>
            )) 
           ) : (
               <p>Blog data not available. </p> 
           )}
            </div>
           </div>
             <Footer />
              </main>
             
            
            </>);
    }  
    

export default BlogList;