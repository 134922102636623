import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL} from '../constants/global';
import {NavLink } from 'react-router-dom';
import { SEO } from './elements/SEO';
export const Offer= ()=>{

    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 

     const [loader, setLoader] = useState(false);
     let api_token=localStorage.getItem('_user_token');

    useEffect(() => { 
       
              
        try {
         const headers = {
             "Content-Type": "application/json",
             Authorization: "Bearer " + api_token
             }; 
            setLoader(true);
       
            axios.get(APP_URL+'get_coupon_code_list',{headers},[])
            .then(response=>{
             setLoader(false);
             
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data: response.data.data,
               });
              
               
             
              
            })
            .catch(error=>{
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data: null,
               });
            
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data: null,
           });
        
     }
 
        return () => {
             
         }
 
       },[]);


       return (

        
            <>
           <div>
<SEO
title='Exclusive Offers on JEE, UPSC, NEET Courses - AbhyasKaro'
description='Unlock the best deals at AbhyasKaro! Dive deep into our special offers tailored for JEE, UPSC, and NEET aspirants. Turn your dreams into reality with the right guidance.'
canonical='https://abhyaskaro.com/offer'
 />
</div>
            <main>
             <Header />             
                  {loader ? <Loader />: ""}     
                  {/* Inner _age Banner End */}
                  <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                        <img alt="" className="img-fluid" src="/images/pyq/offers.png" />
                                 <div className="d-flex">
                                    <h1 className="text-capitalize">Offers</h1>
                                 </div>
                                    </div>
                                </div>
                                <div className="page_overlay"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*---offers-card start----*/}

            <div className="offer_card-wrapper">
                
            { data.status===true && data.data.map((result,key) => ( 

                <div className="offer_card" key={key}>
                    <div className="offers_card-left">
                        <h1>{result.discount_type===2?result.discount_value+"%":"Rs."+result.discount_value} </h1>
                    </div>
                    <div className="offers_card-right">
                        <h4>{result.name}</h4>
                        
                        {api_token && api_token.length>0?<NavLink to="/subscription_cart" className="" state={{offer_coupon_code:result.code}}>Redeem</NavLink>:""}

                      
                    </div>
                    <div className="offer_lr"></div>
                    <div className="offer_rr"> </div>
                </div>
            ))}
               
                
              
            </div>

            {/*------offers-card-end--*/}
            
            {api_token && api_token.length>0?<ProfileSideMenu />:""}
            

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default Offer;