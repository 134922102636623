import React, { useState ,useEffect}from "react";
import axios from '../../general/axios.js';
import { APP_URL,MAIN_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import {CounterTimeNew} from '../../elements/CounterTimeNew';
import {BookMark} from './BookMark';
import {Report} from './Report';
import {RightSideBar} from './RightSideBar';
import QuestionOption from "./QuestionOption";
import { useNavigate} from 'react-router-dom';
import {TestCounterTime} from '../../elements/TestCounterTime';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const GetQuestionList= ({setLoader,pageDefault})=>{
    
    
    let api_token=localStorage.getItem('_user_token');
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + api_token
        }; 
    const navigate = useNavigate();    
    const [technical_aptitude_question_type, setTechnicalAptitudeQuestionType] = useState(2);
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
        }); 

    const [all_total_question, setTotalQuestion] = useState({
        total_question: 0,
        technical_total: 0,
        general_total: 0,
        });
    
    const [active_question, setActiveQuestion] = useState(0);
    
    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
          packages: { "[+]": ["html"] },
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"]
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"]
          ]
        }
      };
           
      const [calcshow, setCalcShow] = useState({status:false});
      const handleCalcClose = () => setCalcShow({status:false});
      const handleCalcShow=(question_id)=>{
          setCalcShow({status:true})
      }

      function change_general_aptitude_technical(question_type) {
        setTechnicalAptitudeQuestionType(question_type);
        setActiveQuestion(0);
      
      }

      useEffect(() => { 
        
        /* document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        }); */
        
       try {
        
           setLoader(true);
      
           axios.get(APP_URL+'get_test_question',{headers,params:{test_id:pageDefault.test_id}},[])
           .then(response=>{
            setLoader(false);

            let technical_total=0;
            let general_total=0;
            
            let data_t=[];
            let data_g=[];
            response.data.data.map(function(child_data,key){
               
                if (child_data.question_type===1) {
                    data_t.push(child_data);
                    technical_total++;
                }else{
                    data_g.push(child_data);
                    general_total++;
                }
            })
            let data_yns=[];
            data_yns[1]=data_t;
            data_yns[2]=data_g;
                        
               setTotalQuestion({
                total_question: technical_total+general_total,
                technical_total: technical_total,
                general_total: general_total,
              });

            setData({
                status: response.data.status,
                message: response.data.message,
                data: data_yns,
              });
             
            
             
           })
           .catch(error=>{

            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
          
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
        
    }

       return () => {
        
        }

      },[pageDefault]);




if (data.status===true && data.data[technical_aptitude_question_type].length>0) {
      

  
return (
<>
{/*--Subject-wise-mcq-sec-start----*/}

<div className="sw_a-wrap">


<div className="sw_a-header d-flex justify-content-between">
    <div className="sw_h-left d-flex align-items-end">
        <button className={technical_aptitude_question_type===2?"btn rounded-pill active":"btn rounded-pill"} onClick={(event) => {change_general_aptitude_technical(2)}}>General Aptitude</button>
        <button className={technical_aptitude_question_type===1?"btn rounded-pill active":"btn rounded-pill"} onClick={(event) => {change_general_aptitude_technical(1)}}>Technical</button>
        {data.status===true?<TestCounterTime remaining_time={data.data[technical_aptitude_question_type][0].remaining_time} test_id={pageDefault.test_id} topic_name={pageDefault.topic_name} test_name={pageDefault.test_name} sub_category_image={pageDefault.sub_category_image}/>:""}
       
    </div>
   
</div>


    <div className="qm_wrapper li_test m-0  " >
   
    <div className="qm_board-wrap no-select">
        <div className="qm_board ">

            
            <div className="h-100">

          

                                            
            
            <div className="qm_bar d-flex mt-3">
                <div className="qm_n-wrap me-2">
                    <div className="qm_n rounded-circle d-flex">
                        <span className="m-auto">{data.data[technical_aptitude_question_type][active_question].sr_number}</span>
                    </div>
                </div>
             
                <MathJaxContext config={config} className="demo ">
                    <MathJax dynamic={true}><p dangerouslySetInnerHTML={{__html: data.data[technical_aptitude_question_type][active_question].question}}></p></MathJax>
                </MathJaxContext>
                
                
            </div>
                            <div className="    count-data-b d-flex align-items-center justify-content-end">
            <div className="sw_h-right d-flex">
                    <div className="r_marks ">
                        Marks : <span className="r_up green  me-2">+{data.data[technical_aptitude_question_type][active_question].positive_mark}</span><span className="r_down orange">-{data.data[technical_aptitude_question_type][active_question].negetive_mark}</span>
                        
                    </div>
                     <div className="r_marks ">
                     Type : <span className="r_up green  me-2">{data.data[technical_aptitude_question_type][active_question].type===1?"MCQ":""}{data.data[technical_aptitude_question_type][active_question].type===2?"MSQ":""}{data.data[technical_aptitude_question_type][active_question].type===3?"NAT":""}</span>
                        
                    </div>
                  
                </div>

                <div className="qm_b-icon d-flex">

                <BookMark data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>

             

                <Report data={data} active_question={active_question} setData={setData}  technical_aptitude_question_type={technical_aptitude_question_type}/>


                </div>
                </div>

                <div className="qm_cb-wrap d-flex justify-content-between">
                
                <Button className="qm_cal  m-auto"  onClick={()=>handleCalcShow("")}><i className="las la-calculator"></i>
                       <span className="">Use Calculator</span></Button> 

                   
               </div>

                <div style={{display: "none"}}>
                   <CounterTimeNew taken_time={data.data[technical_aptitude_question_type][active_question].taken_time}/>
                </div>
            <div className=" mcq-solution">
                

             <QuestionOption data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type} all_total_question={all_total_question}/>


      
          
            </div>
          {/* <TestQuestionButton user_selected_answer={result.user_selected_answer} question_type={result.type} onClickBtnPrev={()=>updateCurrentPage(active_question-1)} onClickMarkAnsReview={(event) => {questionMarkReview(result.id,(result.mark_review===1)?0:1,result.sr_number, event)}} onSingleTypeSaveNext={(event) => {add_single_type_answer(result.id,result.type,result.chapter_id,result.subject_id,result.sr_number,event)}} onSubmitNumeric={(event) => {add_numeric_type_answer(result.id,result.type,result.chapter_id,result.subject_id,result.sr_number,event)}} onSubmitMultiple={(event) => {add_multiple_type_answer(result.id,result.type,result.chapter_id,result.subject_id,result.sr_number,event)}}/> */}
         
            </div>
            

        </div>
    </div>

    <RightSideBar data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData} pageDefault={pageDefault} navigate={navigate} technical_aptitude_question_type={technical_aptitude_question_type}/>
</div>
</div>

{/*-----Subject-wise-mcq-sec-end--*/}


            
            
            


         
          <Modal show={calcshow.status} onHide={handleCalcClose} size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub demo-data-m" >
          
              <Modal.Body className="p-0">
             
                 <iframe width="100%" height="100%" src={MAIN_URL+"public/calc/calculator.html"} title=""></iframe>
             
              </Modal.Body>
            
            
            </Modal>


    </>
    );

}else{

    return(<>
    
<div className="sw_a-wrap">
<div className="sw_a-header d-flex justify-content-between">
    <div className="sw_h-left d-flex align-items-end">
        <button className={technical_aptitude_question_type===2?"btn rounded-pill active":"btn rounded-pill"} onClick={(event) => {change_general_aptitude_technical(2)}}>General Aptitude</button>
        <button className={technical_aptitude_question_type===1?"btn rounded-pill active":"btn rounded-pill"} onClick={(event) => {change_general_aptitude_technical(1)}}>Technical</button>
        
       
    </div>
   
</div>


    <div className="qm_wrapper li_test m-0  " >
   
    <div className="qm_board-wrap no-select">
        <div className="qm_board ">

            
            <div className="h-100">

          

            <div className=" mcq-solution">
              No Data Found
           </div>
         
         
            </div>
            

        </div>
    </div>

   

</div>
</div>
     
    </>);
}    
 
      
    }  
    

export default GetQuestionList;