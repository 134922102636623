import React, { useState ,useEffect,memo} from "react";
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import axios from '../../general/axios.js';
import InfiniteScroll from "react-infinite-scroll-component";
import {Loader} from '../../elements/Loader';
const TabQuestionList= ({data,active_question,setActiveQuestion,config,technical_aptitude_question_type,setData,pageDefault,countertwo})=>{
  
    console.log("TabQuestionList");
    const [loader, setLoader] = useState(false);
    
     let api_token=localStorage.getItem('_user_token');
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
     }; 
     const [state, setState] = useState({
        status:false,
        breweries: [],
        pageNumber: 1,
        items: 10,
        hasMore: true
      });

      function fetchData(is_page_increase=1){

        try {
            axios.get(APP_URL+'get_all_test_question',{headers,params:{test_id:pageDefault.test_id,page:state.pageNumber,per_page:state.items,question_type:technical_aptitude_question_type}},[])
            .then(response=>{

              

              /*   let data_t=[];
                let data_g=[];

                


                response.data.data.map(function(child_data,key){
                
                    if (child_data.question_type===1) {
                        data_t.push(child_data);
                    }else{
                        data_g.push(child_data);
                    }
                })
                let data_yns=[];
                data_yns[1]=data_t;
                data_yns[2]=data_g; */
              /*   console.log(is_page_increase);
                console.log(response.data.total+"   "+response.data.per_page);
                if (is_page_increase===1 || response.data.total>=response.data.per_page) {

                    setState({
                        status:true,
                        //updating data
                        breweries: [...state.breweries, ...response.data.data],
                        //updating page numbers
                        pageNumber: state.pageNumber + 1,
                        items: state.items,
                      });  

                }else{

                    setState({
                        status:true,
                        //updating data
                        breweries: [...response.data.data],
                        //updating page numbers
                        pageNumber: 1,
                        items: state.items,
                      });
                } */
                
                setState({
                    status:true,
                    //updating data
                    breweries: [...state.breweries, ...response.data.data],
                    //updating page numbers
                    pageNumber: state.pageNumber + 1,
                    items: state.items,
                  });  


   
               
            })
            .catch(error=>{
                
            })
           } catch (e) { //code will jump here if there is a network problem
      
     }

    }
   /*   useEffect(() => { 
        fetchData();
     return () => {
      
    }},[]); */

    useEffect(() => {   
        if (countertwo===1) {
            fetchData(0);  
        }
        
     return () => {
      
    }

    },[countertwo,technical_aptitude_question_type]);


    function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
       
        setLoader(true);
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
            }; 
            
            axios.post(APP_URL+"add_single_test_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
            .then(response=>{
              /* if (response.data.status===true) {
                    
                 }else{
                  
                } */
                setLoader(false);   
                
            })
            .catch(error=>{
                
                setLoader(false);
               
                
            })
    
           
        
      } catch (e) { //code will jump here if there is a network problem
        console.log("error");
        setLoader(false);  
       
    }
    }

    const updateCurrentPage=(updated_question)=>{

       

        if (data.data[technical_aptitude_question_type][active_question].user_selected_answer && data.data[technical_aptitude_question_type][active_question].user_selected_answer.length>0)
        {
            if (data.data[technical_aptitude_question_type].length===updated_question || updated_question===-1) {
                setActiveQuestion(0); 
            }else{
                setActiveQuestion(updated_question);    
            }
    
        }else{
            
             data.data[technical_aptitude_question_type][active_question].is_skip=1;
             data.data[technical_aptitude_question_type][active_question].taken_time=localStorage.getItem('seconds');
             
             add_single_question_answer(data.data[technical_aptitude_question_type][active_question].id,data.data[technical_aptitude_question_type][active_question].type,null,1,data.data[technical_aptitude_question_type][active_question].chapter_id,data.data[technical_aptitude_question_type][active_question].subject_id);
             setData({status:true,message:"",data:data.data});
            if (data.data[technical_aptitude_question_type].length===updated_question || updated_question===-1) {
                setActiveQuestion(0); 
            }else{
                setActiveQuestion(updated_question);    
            }
           
        }
    
    
    
            
        return null;
        }

    
    
    return (
        <>
         {loader ? <Loader />: ""}
        <div className="tab-pane fade " id="bookmark" role="tabpanel" aria-labelledby="bookmark-tab">
        <div className="q_book-list d-block ">
            
            

        <div
        id="scrollableDiv"
        style={{
            height: 550,
            overflow: 'auto',
            display: 'flex',
        }}
        >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
            dataLength={state.breweries.length}
            next={fetchData}
            //To put endMessage and loader to the top.
            //inverse={true} //
            hasMore={true}
            // loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
            endMessage=""
        >
            
         {state.breweries.map((result,key) => (
                      
        <React.Fragment key={key}>

         <div>
         {(() => {
               if ((result.user_selected_answer && result.user_selected_answer.length>0) && result.mark_review===1) { //answer diya + mark kiya
                return ( <div className="bs_bar-o" onClick={()=>updateCurrentPage(key)}>
                <div className="d-flex">
                    <div className="bs_c-wrap">
                        <div className="bs_c d-flex rounded-circle mark-done answer-done bl-blue">
                            
                    {/* <ul className="filter-menu d-flex justify-content-between p-0 mt-4">
                    <li className="d-flex m-review " data-target="m-review"><span className="bl-blue position-relative "></span></li>
                    </ul> */}
                    {/* ul to be remove after code done */}

                            <span className="m-auto">{result.sr_number}</span>
                            {(result.is_bookmark===1)? <label className="bookmark active">
                <input className="bookmark__toogle" type="checkbox" />
                <div className="bookmark__stroke"></div>
                <div className="bookmark__fill"></div>
                </label>:""}
                        </div>
                    </div>
                  
                    <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                    </MathJaxContext>
                    
                </div>
    
               

            </div>)
            }else if(!result.user_selected_answer && result.mark_review===1){ // only mark
            return ( <div className="bs_bar-r" onClick={()=>updateCurrentPage(key)}>
            <div className="d-flex">
                <div className="bs_c-wrap">
                    <div className="bs_c d-flex rounded-circle mark-done ">
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                    </div>
                </div>
              
                <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                    </MathJaxContext>
                    
            </div>
           
        </div>)
            }else if(result.user_selected_answer && result.user_selected_answer.length>0 && (result.mark_review===null || result.mark_review===0)){
            return ( <div className="bs_bar-w" onClick={()=>updateCurrentPage(key)}>
            <div className="d-flex">
                <div className="bs_c-wrap">
                    <div className="bs_c d-flex rounded-circle answer-done bl-blue">
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                    </div>
                </div>
              
                <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                    </MathJaxContext>
                    
            </div>
           
        </div>)
            }else if(!result.user_selected_answer && (result.mark_review===null || result.mark_review===0) && (result.is_bookmark===null || result.is_bookmark===0)){
            return ( <div className="bs_bar" onClick={()=>updateCurrentPage(key)}>
            <div className="d-flex">
                <div className="bs_c-wrap">
                    <div className="bs_c d-flex rounded-circle nothingdid">
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                    </div>
                </div>
              
                <MathJaxContext config={config} className="demo">
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                    </MathJaxContext>
                    
            </div>
           
        </div>)
            }else{
                return ( <div className="bs_bar" onClick={()=>updateCurrentPage(key)}>
                <div className="d-flex">
                    <div className="bs_c-wrap">
                        <div className="bs_c d-flex rounded-circle">
                            <span className="m-auto">{result.sr_number}</span>
                            {(result.is_bookmark===1)? <label className="bookmark active">
    <input className="bookmark__toogle" type="checkbox" />
    <div className="bookmark__stroke"></div>
    <div className="bookmark__fill"></div>
    </label>:""}
                        </div>
                    </div>
                  
                    <MathJaxContext config={config} className="demo" >
                        <MathJax dynamic={true}><p className="m-0" dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                    </MathJaxContext>

                 
                </div>
              
            </div>)
            }


        })()}
                </div>                
        

        
                                
        </React.Fragment>
        ))}

        </InfiniteScroll>
        </div>

        

           
          
           



        </div>
    </div>
    </>
        
            
        );

 }
     
   
      
    
    

export default memo(TabQuestionList);