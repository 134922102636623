import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import {secondsToHms} from '../../elements/AppHelper';
import axios from '../../general/axios.js';
export const OptionMulti= ({data,active_question,config,setActiveQuestion,setData})=>{
    
     let current_data=data.data[active_question];

      let api_token=localStorage.getItem('_user_token');
     
      const option_array = {
        0:"A",
        1:"B",
        2:"C",
        3:"D",
        4:"E",
        5:"F",
        6:"G",
      }

      const[rightansweranimation,setRightAnswerAnimation]=useState(false);
      const[wrongansweranimation,setWrongAnswersAnimation]=useState(false);
     
      let multiple_answer=[];
      
      function multiple_answer_select_only(option_id,option_key,event) {
    
        if (event.currentTarget.classList.contains("given_ans")) {
            event.currentTarget.classList.remove('given_ans');
            multiple_answer=multiple_answer.filter(function(e) { return e !== option_id.toString() });
        }else{
            multiple_answer.push(option_id.toString());
            event.currentTarget.classList.add('given_ans');
        }
        
        
    }

      function add_multiple_type_answer(question_id,question_type,chapter_id,subject_id,event) {
    
        if (multiple_answer && multiple_answer.length<=0) {
            alert("Please select answer");
            return null;
        }
        
        let tempdata=[];
        let temp_multiple_answer=[];
        let is_true=0;
        


        current_data.user_selected_answer=JSON.stringify(multiple_answer);

        current_data.get_option.map(function(option1,option_key1){
                 
            if (option1.is_true===1) {
                temp_multiple_answer.push(option1.id.toString());    
            }
        });
        
        if (JSON.stringify(multiple_answer.sort()) === JSON.stringify(temp_multiple_answer.sort())) {
            is_true=1;
        }

        current_data.is_true=is_true;
        current_data.is_skip=0;
        current_data.taken_time=localStorage.getItem('seconds');
        if (current_data.get_practice_answer!=null) {
            current_data.get_practice_answer.average_time=(parseFloat(current_data.get_practice_answer.average_time)+localStorage.getItem('seconds'))/current_data.get_practice_answer.total_solved;
            current_data.get_practice_answer.total_solved=current_data.get_practice_answer.total_solved+1;
        }
        if (is_true===1) {
            setRightAnswerAnimation(true)
            setTimeout(() => setRightAnswerAnimation(false), 2000);     
            
        }else{
            setWrongAnswersAnimation(true); 
            setTimeout(() => setWrongAnswersAnimation(false), 2000);     
            
        }
        data.data[active_question] = current_data;
        setData({status:true,message:"",data:data.data});
        add_single_question_answer(question_id,question_type,JSON.stringify(multiple_answer),0,chapter_id,subject_id);
        
        return null;
    }
    function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
       
       
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
            }; 
            
            axios.post(APP_URL+"add_single_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
            .then(response=>{
                /* if (response.data.status===true) {
                    
                 }else{
                  
                } */
               
                
            })
            .catch(error=>{
                
              
               
                
            })

           
        
      } catch (e) { //code will jump here if there is a network problem
        
        
       
    }
    }
    

    const updateCurrentPage=(updated_question,question_id,is_skip=1)=>{

       
    if (current_data.user_selected_answer && current_data.user_selected_answer.length>0)
    {
       
        if (data.data.length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }

    }else{
        
        current_data.is_skip=1;
        current_data.taken_time=localStorage.getItem('seconds');
        data.data[active_question] = current_data;
        add_single_question_answer(current_data.id,current_data.type,null,1,current_data.chapter_id,current_data.subject_id);
        setData({status:true,message:"",data:data.data});
        if (data.data.length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }
       
    }
    return null;
    }

    if (current_data.user_selected_answer && current_data.user_selected_answer.length>0)
    {
    }else{
        let multiSelectElements = document.getElementsByClassName('yaminsaiyedmulti');
        for(let i=0; multiSelectElements[i]; ++i){
            if(multiSelectElements[i].classList.contains("given_ans")){
                multiSelectElements[i].classList.remove('given_ans');
                
            }
        }
    }
       return (
            <>
            <div className="an_b-wrap pyqs_wrap mcq-solution">
                    { current_data.get_option.map((option,option_key) => ( 
                       
                       <React.Fragment  key={option_key}>
                       {(() => {
                        if (current_data.user_selected_answer && current_data.user_selected_answer.length>0) {
                        
                            if (option.is_true===1) {
                                return (
                            
                                    <div className="d-flex justify-content-between an_b-n right_ans">
        
                                    <div className="an_bl d-flex">
                                        <div className="an_count me-3 d-flex rounded-circle ">
                                            <span className="m-auto">{option_array[option_key]}</span>
                                        </div>
                                        <div className="an_dummy d-flex">
                                        <MathJaxContext config={config} className="demo">
                                             <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                        </MathJaxContext>
                                        </div>

                                        {(JSON.parse(current_data.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                            <span>Your Answer</span>
                                        </div>:""}


                                    </div>
                                  
                                </div>
                                )
                            
                            }else{
                               
                                 return (
                                <div className={(JSON.parse(current_data.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n wrong_ans":"d-flex justify-content-between an_b-n"}>
        
                                    <div className="an_bl d-flex">
                                        <div className="an_count me-3 d-flex rounded-circle ">
                                            <span className="m-auto">{option_array[option_key]}</span>
                                        </div>
                                        <div className="an_dummy d-flex">
                                        <MathJaxContext config={config} className="demo">
                                             <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                        </MathJaxContext>
        
                                        
                                        </div>
                                        {(JSON.parse(current_data.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                            <span>Your Answer</span>
                                        </div>:""}

                                    </div>
                                  
                                </div>
                                )
                            }
                        
                        }else {
                        return (
                            <div className="d-flex justify-content-between an_b-n yaminsaiyedmulti" onClick={(event) => {multiple_answer_select_only(option.id,option_key,event)}}>
                             <div className="an_bl d-flex">
                                <div className="an_count me-3 d-flex rounded-circle">
                                    <span className="m-auto">{option_array[option_key]}</span>
                                </div>
                                <div className="an_dummy d-flex">
                                <MathJaxContext config={config} className="demo">
                                     <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                </MathJaxContext>

                                    
                                </div>
                            </div>
                          
                        </div>
                        )
                        }
                    })()}
                       
                       </React.Fragment>
                    ))}
                        
                    </div>


                    {(current_data.user_selected_answer && current_data.user_selected_answer.length>0)?<><div className="ay_time-wrap d-flex justify-content-center">
                       <button className="btn d-flex yt-btn mx-2">
                           <img alt="" className="img-fluid" src="/images/avg-time1.png" />
                           <div className="ay_time d-grid">
                               <span>Your Time</span>
                               <span>{secondsToHms(current_data.taken_time)}</span>
                           </div>
                       </button>
                       <button className="btn d-flex at-btn mx-2">
                           <img alt="" className="img-fluid" src="/images/avg-time2.png" />
                           <div className="ay_time d-grid">
                               <span>Avg.Time</span>
                               <span>{current_data.get_practice_answer?secondsToHms(current_data.get_practice_answer.average_time):secondsToHms(0)}</span>
                           </div>
                       </button>
                       
                       {current_data.video && current_data.video.length>0?<button className="btn d-flex at-btn mx-2">
                          <a className="d-flex align-items-center" href={current_data.video} target="_blank">
                           <img alt="" className="img-fluid" src="/images/video.png" />
                           <div className="ay_time d-grid">
                               <span>Video <br />Link</span>
                           </div>
                           </a>
                       </button>:""}
                     </div>
                   <div className="com_sol-box">
                       <h3 className="blue">Solution</h3>
                       <div className="com_sol-box-inner">
                            <MathJaxContext config={config} className="demo">
                                <MathJax dynamic={true}><p className="gray" dangerouslySetInnerHTML={{__html: current_data.solution}}></p></MathJax>
                           </MathJaxContext>

                           
                       </div>
                   </div><div className="nav_b d-flex justify-content-around" >
            <button className="btn pr_btn" type="button" onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question+1,current_data.id)}>Next</button>
    
           </div></>:<div className="nav_b d-flex justify-content-around" >
            <button className="btn pr_btn" type="button" onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question+1,current_data.id)}>Next</button>
            <button className="btn ne_btn" type="button"  onClick={(event)=>add_multiple_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,event)}>Submit</button>
    
           </div>}
           
           <div className="show_q-r" style={{ display: rightansweranimation ? "block" : "none" }}>
                <div className=" show_q-r-inner">
                <img alt="" className="img-fluid" src="/images/Correct-Answer.gif" />
                </div>
                </div>

                <div className="show_q-r" style={{ display: wrongansweranimation ? "block" : "none" }}>
                     <div className=" show_q-r-inner wrong_qut">
                    <img alt="" className="img-fluid" src="/images/error-img.gif" />
                    </div>
                </div>


            </>
            
        );
   
      
    }  
    

export default OptionMulti;