import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import {Loader} from '../../elements/Loader';
export const BookMark= ({data,active_question,setData})=>{
    
     let current_data=data.data[active_question];
     let api_token=localStorage.getItem('_user_token');
     const [loader, setLoader] = useState(false);
     const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + api_token
     }; 
   
     function questionBookmark(question_id, event){
        
        let multiSelectElements = document.getElementsByClassName('yaminsaiyedmulti');
        for(let i=0; multiSelectElements[i]; ++i){
            if(multiSelectElements[i].classList.contains("given_ans")){
                multiSelectElements[i].classList.remove('given_ans');
                
            }
        }
        setLoader(true);
        

        try {
           
            if (event.currentTarget.classList.contains("active")) {
                current_data.is_bookmark=0;
                current_data.taken_time=localStorage.getItem('seconds');
                event.currentTarget.classList.remove('active');
            }else{
                current_data.is_bookmark=1;
                current_data.taken_time=localStorage.getItem('seconds');
                event.currentTarget.classList.add('active');
            }
            data.data[active_question] = current_data;
            setData({status:true,message:"",data:data.data});
          
            axios.post(APP_URL+"practice_question_bookmark",{question_id:question_id},{headers})
            .then(response=>{
               
                if (response.data.status===true) {
                   
                }else{
                   
                }
                setLoader(false);   
                
            })
            .catch(error=>{
                setLoader(false);
               
                
            })

           
        
      } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
       
    }

    }


       return (
        <>
        {loader ? <Loader />: ""}
        <div className={current_data.is_bookmark===1?"bookmark active":"bookmark"}  onClick={(event) => {questionBookmark(current_data.id,event)}}>
        <input className="bookmark__toogle" type="checkbox"/>
        <div className="bookmark__stroke"></div>
        <div className="bookmark__fill"></div>
        </div>
        </>
        
            
        );
   
      
    }  
    

export default BookMark;