import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import {Loader} from '../../elements/Loader';
export const TabQuestionNumbers= ({data,active_question,setActiveQuestion,setData})=>{
let api_token=localStorage.getItem('_user_token');
const [selectedfilter,setSelectedFilter]=useState([]);
const [loader, setLoader] = useState(false);
function apply_filter() {

    
    let checkedValue = []; 
    let inputElements = document.getElementsByClassName('form-check-input');
    for(let i=0; inputElements[i]; ++i){
        if(inputElements[i].checked){
            checkedValue.push(inputElements[i].value);
            
        }
    }
    setSelectedFilter(checkedValue);
    let multiSelectElements = document.getElementsByClassName('yaminsaiyedmulti');
        for(let i=0; multiSelectElements[i]; ++i){
            if(multiSelectElements[i].classList.contains("given_ans")){
                multiSelectElements[i].classList.remove('given_ans');
                
            }
    }
} 
function clear_filter() {
    
    setSelectedFilter([]);
   
    let inputElements = document.getElementsByClassName('form-check-input');
    for(let i=0; inputElements[i]; ++i){
        if(inputElements[i].checked){
            inputElements[i].checked=false;
            
        }
    }
    
    let multiSelectElements = document.getElementsByClassName('yaminsaiyedmulti');
        for(let i=0; multiSelectElements[i]; ++i){
            if(multiSelectElements[i].classList.contains("given_ans")){
                multiSelectElements[i].classList.remove('given_ans');
                
            }
    }
    
    
    
}

function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
       
    setLoader(true);
    try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
        }; 
        
        axios.post(APP_URL+"add_single_pyq_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
        .then(response=>{
            /* if (response.data.status===true) {
                
             }else{
              
            } */
            setLoader(false);   
            
        })
        .catch(error=>{
            
            setLoader(false);
           
            
        })

       
    
  } catch (e) { //code will jump here if there is a network problem
    console.log("error");
    setLoader(false);  
   
}
}


const updateCurrentPage=(updated_question)=>{


    if (data.data[active_question].user_selected_answer && data.data[active_question].user_selected_answer.length>0)
    {
      
        if (data.data.length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }

    }else{
        
        data.data[active_question].is_skip=1;
        data.data[active_question].taken_time=localStorage.getItem('seconds');
         add_single_question_answer(data.data[active_question].id,data.data[active_question].type,null,1,data.data[active_question].chapter_id,data.data[active_question].subject_id);
        setData({status:true,message:"",data:data.data});
        if (data.data.length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }
       
    }



        
    return null;
    }


    
    return (
        <>
        {loader ? <Loader />: ""}
        <div className="tab-pane fade show active " id="questions" role="tabpanel" aria-labelledby="questions-tab">
         {/* <ul className="filter-menu d-flex justify-content-start p-0 mt-4 align-items-center"> */}
        <ul className="filter-menu ">
            <li className="d-flex right " data-target="right" ><input type="checkbox" name="right" value="data_filter.is_true===1" className="form-check-input"/><span className="bl-green"></span></li>
            
            <li className="d-flex" data-target="review"><input type="checkbox" name="wrong" value="data_filter.is_true===0"  className="form-check-input"/><span className="position-relative q_book-w"></span></li>

            <li className="d-flex" data-target="review"><input type="checkbox" name="wrong" value="data_filter.is_skip===1"  className="form-check-input"/><span className="position-relative q_book-s"></span></li>
          
            <li className="d-flex s-next " data-target="s-next" ><input type="checkbox" name="bookmark" value="data_filter.is_bookmark===1"  className="form-check-input"/><span className="position-relative "> <label className="bookmark active">
            <input className="bookmark__toogle" type="checkbox" />
            <div className="bookmark__stroke"></div>
            <div className="bookmark__fill"></div>
            </label></span></li>
            
            <li className="d-flex  bg-transparent" data-target="skip"><input type="checkbox" name="nd" value="(!data_filter.user_selected_answer && (data_filter.is_bookmark===null || data_filter.is_bookmark===0) && (data_filter.is_skip===null || data_filter.is_skip===0))"  className="form-check-input"/><span className="skip"></span></li>

            <li className="d-flex" data-target="all"><button data-bs-toggle="tooltip" data-bs-placement="top" title="Filter" className="filter_d-tre" onClick={(event) => {apply_filter(event)}}><i className="las la-filter"></i></button><button data-bs-toggle="tooltip" data-bs-placement="top" title="Clear" className="filter_c-tre"  onClick={(event) => {clear_filter(event)}}><i className="las la-times"></i></button></li>
            
        </ul>

            <div className="q_book-list re ">
            { data.status===true && data.data.filter(data_filter => {
    
            if (selectedfilter.length>0) {
                
                if (eval(selectedfilter.join([' || ']))) {
                    return true;
                }
                
                
                
            }else{
                return true;
            }
            

            }).map((result,key) => ( 
                  <React.Fragment key={key}>
                 {(() => {
                    if(result.is_skip===1) {
                        return (<div className="q_book-box d-flex m-auto rounded-circle q_book-s" key={key} onClick={()=>updateCurrentPage(key)}>
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
            <input className="bookmark__toogle" type="checkbox" />
            <div className="bookmark__stroke"></div>
            <div className="bookmark__fill"></div>
            </label>:""}
                    </div>)
                    }else if(result.is_true===1){
                    return (<div className="q_book-box d-flex m-auto rounded-circle q_book-r" key={key}  onClick={()=>updateCurrentPage(key)}>
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
            <input className="bookmark__toogle" type="checkbox" />
            <div className="bookmark__stroke"></div>
            <div className="bookmark__fill"></div>
            </label>:""}
                    </div>)
                    }else if(result.is_true===0){
                    return (<div className="q_book-box d-flex m-auto rounded-circle q_book-w" key={key}  onClick={()=>updateCurrentPage(key)}>
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
            <input className="bookmark__toogle" type="checkbox" />
            <div className="bookmark__stroke"></div>
            <div className="bookmark__fill"></div>
            </label>:""}
                        
                    </div>)
                    }else{
                        return (<div className="q_book-box d-flex m-auto rounded-circle q_book-d" key={key} onClick={()=>updateCurrentPage(key)}>
                        <span className="m-auto">{result.sr_number}</span>
                        {(result.is_bookmark===1)? <label className="bookmark active">
            <input className="bookmark__toogle" type="checkbox" />
            <div className="bookmark__stroke"></div>
            <div className="bookmark__fill"></div>
            </label>:""}
                        
                    </div>)
                    }
                })()}
                 
                 </React.Fragment>
            ))}
           
            </div>
        </div>
        </>
        
            
        );
   
      
    }  
    

export default TabQuestionNumbers;