import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Message} from './elements/Message';
import {Loader} from './elements/Loader';
import { APP_URL} from '../constants/global'
import { useNavigate,NavLink } from "react-router-dom";


export const LoginWithGmail= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    function getCookie(cookieName) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
          let [key,value] = el.split('=');
          cookie[key.trim()] = value;
        })
        return cookie[cookieName];
      }
    let gmail_firstname=decodeURI(getCookie('gmail_firstname'));
    let gmail_lastname=decodeURI(getCookie('gmail_lastname'));
    let gmail_email=decodeURI(getCookie('gmail_email'));

    
    const [login_data, setLoginData] = useState({
        mobile: "",
        is_login:0,
        firstname: gmail_firstname,
        lastname: gmail_lastname,
        email: gmail_email.replace("YNSYNS", "@")
      });
      
      const [formErrors, setFormErrors] = useState({});
      const [flashShow, setFlashShow] = useState("");

      const changeHandler = (e) => {

        const name = e.target.name;
        const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

       
        
        setLoginData({
            ...login_data,
            [name]: value
          })
      }


     function send_otp(){
        
       
        const headers = {
             "Content-Type": "application/json",
             //Authorization: "Bearer " + token
           };
        
         setLoader(true);
         axios.post(APP_URL+'send_otp',login_data,{headers})
         .then(response=>{
            setLoader(false);
           
             if (response.data.status===true) {
                navigate("/register_verify_otp_gmail",{ state: { login_data: login_data} });
             }else{
                 setFlashShow(<Message variant="danger" children={response.data.message}/>);
             }
             
             
         })
         .catch(error=>{
            setLoader(false);
             if (error.response.status===422) {
                 setFlashShow(<Message variant="danger" children={error.response.data.message}/>);
                 
             }
         })
 
     }
     
     const sendOtp = (e) => {
         e.preventDefault();
         if (send_otp_validate(login_data)===true) {
            
             send_otp();
         }        
 
     }
     const send_otp_validate = (values) => {
        const errors = {};
       

        if (!values.firstname) {
            errors.firstname = "firstname is required!";
        }
        if (!values.lastname) {
            errors.lastname = "lastname is required!";
        }
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!values.email) {
            errors.email = "email is required!";
        }else if(!regEmail.test(values.email)){
            errors.email = "email address is invalid";
        }

        if (!values.mobile) {
            errors.mobile = "mobile is required!";
            
        }else if (values.mobile.length!==10){
            errors.mobile = "invalid mobile";
        }
        
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };
    return (
        <><main>
         <Header />
         {loader ? <Loader />: ""}
         <div className="signup_wrap justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top">
                <div className="signup_progress">
                    <div className="progress_box done_box">
                        <span>1</span>
                    </div>
                    <div className="progress_box">
                        <span>2</span>
                    </div>
                    <div className="progress_box">
                        <span>3</span>
                    </div>
                    <div className="progress_box">
                        <span>4</span>
                    </div>
                </div>
                <div className="signup_body">
               
                         {flashShow}
                        
                         {/* Enter-Mobilenumber-start */}

                         <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-5">
                            <h4>Register Via Gmail</h4>
                            <p>Fill-Up Your Personal Information</p>
                            <form onSubmit={sendOtp}>
                                <div className="er_box">                                
                                <input type="text" name="firstname" placeholder="First Name" className="form-control border-0" onChange={changeHandler} value={login_data.firstname ? login_data.firstname : ""}/>
                                <span className="text-danger">{formErrors.firstname}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="lastname" placeholder="Last Name" className="form-control border-0" onChange={changeHandler} value={login_data.lastname ? login_data.lastname : ""}/>
                                <span className="text-danger">{formErrors.lastname}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="email" placeholder="Email Address" className="form-control border-0" onChange={changeHandler} value={login_data.email ? login_data.email : ""} readOnly/>
                                <span className="text-danger">{formErrors.email}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="mobile" placeholder="Mobile Number" className="form-control border-0" onChange={changeHandler} value={login_data.mobile} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                    <span className="text-danger">{formErrors.mobile}</span>
                                </div>
                                <div className="signup_bottom text-center my-4">
                                     <button className="btn" type="submit ">Next</button>
                                     <NavLink to="/register" className="et_pro">Back</NavLink>
                                </div>
                            </form>
                        </div>

                                
                         {/* Enter-Mobilenumber-end */}

                                              

                </div>
            </div>
            
        </div>

         <Footer />
          </main>
         
        
        </>);

}
export default LoginWithGmail;