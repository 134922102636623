import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {finalDateFormat} from './elements/AppHelper';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';

export const TestScoreCard= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
   
    const [data, setData] = useState({
        status:null,
        message:"",
        data: null,
        
      }); 
      let pageDefault=location.state;
      
          
   
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + api_token
    }; 

    function load_data() {
        try {
      
            setLoader(true);
            axios.get(APP_URL+'get_test_score_card_topic_wise',{headers,params:{test_id:pageDefault.test_id,page:1}},[])
            .then(response=>{
             setLoader(false);
            
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data: response.data.data,
               });
              
               
             
              
            })
            .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data: null,
               });
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data: null,
           });
         //console.log(e);
     }
    }
    useEffect(() => { 
        
        load_data();
        return () => {
             
         }
 
       },[pageDefault]);
    
   
       function refresh_data(){
        load_data();
       }
       if (data.status===null) {
        return null;
       }
       if (data.status===true) {
        
        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
            
           {/* Inner _age Banner End */}
           <div className="page_banner-wrapper final-result">   
                            <div className="page_baneer">
                               
                                <div className="p_yq d-flex">
                                    <div className="p_yq-left d-flex position-relative">
                                       <div className="r_score-wrap d-flex">
                                        <div className="r_score m-auto text-center">
                                            <div className="score">
                                                <h1 className="m-0 blue">{data.status===true?data.data.rank_no:"0"}</h1>
                                            </div>
                                            <img alt="" className="img-fluid " src="/images/winner/result.png" />
                                        </div> 
                                       </div>
                                       <button className="rounded-circle resresh_data" type="button" onClick={()=>refresh_data()}><i className="las la-sync text-white"></i></button>
                                    </div>
                                    <div className="p_yq-right">
                                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">

                                            <h3 className=" text-left fw-normal">{pageDefault.topic_name}</h3>
                                            <h1 className="m-0 text-end text-white">{pageDefault.test_name}</h1>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="page_overlay"></div> */}
                               
                            </div>

                          
                     

                            <ProfileSideMenu />
                    </div>

           {/* Inner _age Banner End */}


           {/*---final-result start----*/}

           <div className="result_c-wrap">

            <div className="t_s-card">
                <div className="t_s-card-header d-flex justify-content-between">
                    <div className="">
                        <h3 className="m-0">Test Score Card</h3>
                        <p><span className="orange"><b>{data.status===true?data.data.total_student_attempted:"0"} Student</b></span> Attempted the Test</p>
                    </div>
                    <div className="d-flex ">
                        <span className="fw-bold r_date">{data.status===true?finalDateFormat(data.data.completion_date):"0"}</span>
                    </div>
                </div>
                <div className="t_s-board position-relative">
                    <div className="d-flex">
                        <div className="m-auto">
                            <p>Total Marks</p>
                            <div className="d-flex justify-content-center">
                                <h2 className="">{data.status===true?data.data.total_marks.toFixed(2):"0"}</h2>
                                <img alt="" className="img-fluid" src="/images/right.png" />
                            </div>
                          
                        </div>

                    </div>
                    <div className="d-flex">
                       <div className="m-auto">
                        <p>Total Question</p>
                        <div className="d-flex justify-content-center">
                            <h2 className="">{data.status===true?data.data.total_question:"0"}</h2>
                            <img alt="" className="img-fluid" src="/images/quation.png" />
                        </div>
                       </div>
               
                    </div>
                    <div className="d-flex">
                       <div className="m-auto">
                        <p>Duration in Min</p>
                        <div className="d-flex justify-content-center">
                            <h2 className="">{data.status===true?data.data.total_time.toFixed(2):0}</h2>
                            <img alt="" className="img-fluid" src="/images/timer_1.png" />
                        </div>
                       </div>
                   
                    </div>
                    <img alt="" className="img-fluid scrore_img" src="/images/winner/score.png" />
                </div>
                <span>Practice makes Perfect</span>
            </div>
<div className="">
    <div className="t_s-card-footer d-flex justify-content-center"> 
      
      <NavLink to="/test_analysis_topic_wise" className="border-0 rounded-pill mx-2 d-purple" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image}}>Analysis</NavLink>

      
        <NavLink to="/test_solution" className="border-0 rounded-pill mx-2 d-orange" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image}}>Solution</NavLink>
        
    </div>
</div>
            


           </div>

           {/*------final-result-end--*/}
  
           
           <Footer />
              </main>
             
            
            </>);

       }else{

        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
            
           {/* Inner _age Banner End */}
           <div className="page_banner-wrapper final-result">   
                            <div className="page_baneer">
                               
                                <div className="p_yq d-flex">
                                    <div className="p_yq-left d-flex position-relative">
                                       <div className="r_score-wrap d-flex">
                                        <div className="r_score m-auto text-center">
                                            <div className="score">
                                                <h1 className="m-0 blue">{data.status===true?data.data.rank_no:"0"}</h1>
                                            </div>
                                            <img alt="" className="img-fluid " src="/images/winner/result.png" />
                                        </div> 
                                       </div>
                                       <button className="rounded-circle resresh_data" type="button" onClick={()=>refresh_data()}><i className="las la-sync text-white"></i></button>
                                    </div>
                                    <div className="p_yq-right">
                                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">

                                            <h3 className=" text-left fw-normal">{pageDefault.topic_name}</h3>
                                            <h1 className="m-0 text-end text-white">{pageDefault.test_name}</h1>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="page_overlay"></div> */}
                               
                            </div>

                          
                     

                            <ProfileSideMenu />
                    </div>

          

           <div className="result_c-wrap">

            <div className="t_s-card">
                
            <div className="t_s-card-header d-flex justify-content-between">
                    <div className="">
                        <h3 className="m-0">Test Score Card</h3>
                        <p></p>
                    </div>
                  
                </div>

                <h1>Your test submission is under process. If your result is not displayed within 1 minute, click on the below refresh button.</h1>
                
            </div>
            <div className="">
                <div className="t_s-card-footer d-flex justify-content-center"> 
                
                <button className="border-0 rounded-pill mx-2 d-orange" type="button" onClick={()=>refresh_data()}>Refresh<i className="las la-sync text-white"></i></button>

                
                
                    
                </div>
            </div>
                        


           </div>

          
             <Footer />
              </main>
             
            
            </>);

       }
      
    }  
    

export default TestScoreCard;