import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Message} from './elements/Message';
import {Loader} from './elements/Loader';
import { APP_URL } from '../constants/global'
import { useNavigate,useLocation } from 'react-router-dom';


export const RegisterVerifyOtp= ()=>{
    const navigate = useNavigate();
    const {state} = useLocation();
    const [loader, setLoader] = useState(false);
    const [verify_data, setVerifyData] = useState({
        mobile: state.mobile,
        otp: "",
      });
    
    const [formErrors, setFormErrors] = useState({});
    const [flashShow, setFlashShow] = useState("");
    const changeHandlerVerify = (e) => {
       
        setVerifyData({
        otp: e.target.value,
        mobile: verify_data.mobile,
        });
    }

    function login_register_otp_verified() {
        
        const headers = {
            "Content-Type": "application/json",
            //Authorization: "Bearer " + token
          };
        
        setLoader(true);
        axios.post(APP_URL+'register_verify_otp',verify_data,{headers})
        .then(response=>{
            setLoader(false);       
            if (response.data.status===true) {
                localStorage.setItem('_user_token', response.data.api_token);   
                localStorage.setItem('_user_data', JSON.stringify(response.data.data));
                navigate("/complete_profile");

            }else{
                setFlashShow(<Message variant="danger" children={response.data.message}/>);
            }
            //localStorage.setItem('_token', response.data.token);
            //localStorage.setItem('_token', response.data.token);
            //localStorage.setItem('user', "Yamin Saiyed");
            //localStorage.removeItem('_token');
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {

                setFlashShow(<Message variant="danger" children={error.response.data.message}/>);
                
            }
            
        })

    }


      const otpVerified = (e) => {
        e.preventDefault();

        if (verify_otp_validate(verify_data)===true) {
            login_register_otp_verified();  
        }    
    }

    const verify_otp_validate = (values) => {
        const errors = {};
       
        if (!values.otp) {
            errors.otp = "otp is required!";
        }else if (values.otp.length!==6){
            errors.otp = "invalid otp";
        }        
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };

    return (
        <><main>
         <Header />
         {loader ? <Loader />: ""}
         <div className="signup_wrap justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top">
                <div className="signup_progress">
                    <div className="progress_box done_box">
                        <span>1</span>
                    </div>
                    <div className="progress_box">
                        <span>2</span>
                    </div>
                    <div className="progress_box">
                        <span>3</span>
                    </div>
                    <div className="progress_box">
                        <span>4</span>
                    </div>
                </div>
                <div className="signup_body">


                         {/* Enter-OTP-start */}

                            <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-5">
                                {flashShow}
                                <h4>Verify OTP</h4>
                                <p>we've sent it on <span><b>{verify_data.mobile}</b></span></p>
                                <form onSubmit={otpVerified}>

                                
                         


                                    <input type="text" name="otp" placeholder="Enter OTP " className="form-control border-0" onChange={changeHandlerVerify} value={verify_data.otp ? verify_data.otp : ""}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                    <span className="text-danger">{formErrors.otp}</span>
                                    <div className="signup_bottom text-center my-4">
              
                                        <button className="btn  " type="submit ">Next</button>
                                    </div>
                                </form>
                                  <p>Having Issue? Write to us on <a href="mailto:info@abhyaskaro.com">info@abhyaskaro.com</a></p>
                            </div>

                         {/* Enter-OTP-end */}

                       

                </div>
            </div>
           
        </div>

         <Footer />
          </main>
         
        
        </>);

}
export default RegisterVerifyOtp;