import React from 'react';
import { Helmet } from 'react-helmet';


export const SEO= ({title, description, name, type,canonical,keyword})=>{
   
return (
    <div>
    <Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<meta name='description' content={description} />
<meta name='keywords' content={keyword} />
<link rel="canonical" href={canonical} />
{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
<meta property="og:type" content={type} />
<meta property="og:title" content={title} />
<meta property="og:description" content={description} />
<script>
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1035168737593922');
                fbq('track', 'PageView');                
            `}
            </script>
            <noscript>
            {` <img height="1" width="1" style="display:none"
                src="https://www.facebook.com/tr?id=1035168737593922&ev=PageView&noscript=1"
            />`}</noscript>
{ /* End Facebook tags */ }
</Helmet>

</div>
);

}
