import React from "react";

export const BookMarkSmile= (props)=>{
   
    if (props.is_true===1) {

        return (
            <img alt="" className="img-fluid" src="/images/r-smile.png" />
        );

    }else if (props.is_true===0){

        return (
            <img alt="" className="img-fluid" src="/images/w-smile.png" />
        );

    }else{

        return (
            <img alt="" className="img-fluid" src="/images/g-smile.png" />
        );

    }


}
