import React, { useState}from "react";
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import axios from '../../general/axios.js';
import MarkAndReview from "./MarkAndReview";
export const OptionMulti= ({data,active_question,config,setActiveQuestion,setData,technical_aptitude_question_type,all_total_question})=>{
    
     let current_data=data.data[technical_aptitude_question_type][active_question];

      let api_token=localStorage.getItem('_user_token');
      const [loader, setLoader] = useState(false);
      const option_array = {
        0:"A",
        1:"B",
        2:"C",
        3:"D",
        4:"E",
        5:"F",
        6:"G",
      }
     
      let multiple_answer=[];
      
      function multiple_answer_select_only(option_id,event) {
    
        let multiple_answer=current_data.user_selected_answer?JSON.parse(current_data.user_selected_answer):[];
                
        const index = multiple_answer.indexOf(option_id.toString());
        if (index > -1) { // only splice array when item is found
            multiple_answer.splice(index, 1); // 2nd parameter means remove one item only
        }else{
           
            multiple_answer.push(option_id.toString());
        }
   
        current_data.user_selected_answer=JSON.stringify(multiple_answer);
        current_data.taken_time=localStorage.getItem('seconds');
        data.data[technical_aptitude_question_type][active_question] = current_data;
        setData({status:true,message:"",data:data.data});
    }
    

   
    function add_multiple_type_answer(question_id,question_type,chapter_id,subject_id,sr_number,event) {
    
        let user_selected_answer=null;
        let is_skip=null;
        if (current_data.user_selected_answer && JSON.parse(current_data.user_selected_answer).length>0) {
            is_skip=0; 
            user_selected_answer=current_data.user_selected_answer;
        }else{
            user_selected_answer=null;
            is_skip=1; 
        }
        current_data.is_skip=is_skip;
        data.data[technical_aptitude_question_type][active_question] = current_data;
        setData({status:true,message:"",data:data.data});

        add_single_question_answer(question_id,question_type,user_selected_answer,is_skip,chapter_id,subject_id);
        return null;
    }
    function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
       
        setLoader(true);
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
            }; 
            
            axios.post(APP_URL+"add_single_test_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
            .then(response=>{

                updateCurrentPage(active_question+1);
                /* if (response.data.status===true) {
                    
                 }else{
                  
                } */
                setLoader(false);   
                
            })
            .catch(error=>{
                
                setLoader(false);
               
                
            })

           
        
      } catch (e) { //code will jump here if there is a network problem
        console.log("error");
        setLoader(false);  
       
    }
    }
    

    const updateCurrentPage=(updated_question,question_id)=>{

        
        if(updated_question>=0) {
            window.scrollTo(0, 0);
            let total_current_type_question=0;
            if (technical_aptitude_question_type===1) {
                total_current_type_question=all_total_question.technical_total;
            }else{
                total_current_type_question=all_total_question.general_total;
            }
                    
            if (updated_question<total_current_type_question) {
                setActiveQuestion(updated_question);    
            }else{
                setActiveQuestion(0);    
            
            }
            
           /*  setTime("00:00:00");
            setSeconds(0); */
            
          
        }
        return null;
        
      }

 
return (
<>
<div className="an_b-wrap pyqs_wrap mcq-solution">
{ current_data.get_option.map((option,option_key) => ( 
   
   <React.Fragment  key={option_key}>
   {(() => {
    if (current_data.user_selected_answer && current_data.user_selected_answer.length>0) {
    
        return (
            <>
           
            <div className={(JSON.parse(current_data.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n given_ans":"d-flex justify-content-between an_b-n"}  onClick={(event) => {multiple_answer_select_only(option.id,current_data.sr_number,option_key,event)}}>
             <div className="an_bl d-flex">
                <div className="an_count me-3 d-flex rounded-circle ">
                    <span className="m-auto">{option_array[option_key]}</span>
                </div>
                <div className="an_dummy d-flex">
                <MathJaxContext config={config} className="demo">
                     <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                </MathJaxContext>

                    
                </div>
            </div>
          
        </div>

        <div className="nav_b d-flex justify-content-around" >
            <MarkAndReview data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={(event) => {add_multiple_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,current_data.sr_number,event)}}>Save & Next</button>
    
           </div>

        </>
        )
    
    }else {
   
        return (
            <>
            
            <div className="d-flex justify-content-between an_b-n" onClick={(event) => {multiple_answer_select_only(option.id,current_data.sr_number,option_key,event)}}>
             <div className="an_bl d-flex">
                <div className="an_count me-3 d-flex rounded-circle ">
                    <span className="m-auto">{option_array[option_key]}</span>
                </div>
                <div className="an_dummy d-flex">
                <MathJaxContext config={config} className="demo">
                     <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                </MathJaxContext>

                    
                </div>
            </div>
          
            </div>

            <div className="nav_b d-flex justify-content-around" >
            <MarkAndReview data={data} active_question={active_question} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type}/>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={(event) => {add_multiple_type_answer(current_data.id,current_data.type,current_data.chapter_id,current_data.subject_id,current_data.sr_number,event)}}>Save & Next</button>
    
           </div>
            </>
            
        )

    }

    

})()}
   
   </React.Fragment>
))}
    
</div>


                   


            </>
            
        );
   
      
    }  
    

export default OptionMulti;

