import {Header} from './elements/Header';
import {Footer} from './elements/Footer';




export const PrivacyPolicy= ()=>{
   
    return (
        <><main>
         <Header />
        
         <div className="container mt-5 px-5 prvacy_p">
            <div className="y_exam-heading">
              <h3 className="m-0">PrivacyPolicy</h3>
              </div>
  
              <div className="pp_details mb-5">
                
        <p>Visitors' personal information is kept strictly confidential. In light of the foregoing, we have drafted this policy to show our users that we value and respect their privacy. </p>
        <h3>Privacy Policy Covers:</h3>
        <p>All of the information we learn about you from your use of our website and services will be handled according to the terms of this Privacy Policy. This policy also governs how we handle the personal data of any third parties that provide us with such data.</p>
        <p>Organisations that we do not control, or whose employees or managers we do not directly supervise, are exempt from this policy's jurisdiction.</p>
        
        <h3>Information We Collect and Use:</h3>
      
        <p>When you register with us, use our services, or simply browse our website, we collect information that can identify you.</p>

<p>When you sign up with us, we require information such as your email address, job title, name, industry, zip code, as well as interests. After signing up for our services and logging in, your identity will be revealed to us.</p>

<p>We use this data in three ways: to tailor what you see, to provide the services you've requested, and to get in touch with you about those services.</p>

<p>Your browser sends information (such as your IP address, cookie data, as well as your requested page) to our servers, and our servers automatically record this information. There will be no personal identification or account linking done with this data.</p>
<h3>Information Disclosure:</h3>
<p>No one will be able to rent or buy your personal information from us.</p>
<h3>Privacy Policy Changes:</h3>
<p>This privacy statement may be updated as and when needed. You can be assured that no matter what, you will always have access to the most up-to-date version of our privacy policy, which details the data we collect, how we could use that data, and with whom we might share it. Our homepage will feature a notice if we make any changes to our statement of the privacy policy. In the event that you disagree with our new policies, you are free to no longer use our services.</p>

<h3>Have a question or comment?</h3>
<p>Fill out the enquiry form or drop us a line if you have any questions or comments.</p>
            </div>
  
            
           </div>


         <Footer />
          </main>
         
        
        </>);

}
export default PrivacyPolicy;