import React from "react";
import OptionSingle from "./OptionSingle";
import OptionMulti from "./OptionMulti";
import OptionNumeric from "./OptionNumeric";
export const QuestionOption= ({data,active_question,config,setActiveQuestion,setData,technical_aptitude_question_type,all_total_question})=>{
    
            
    if (data.data[technical_aptitude_question_type][active_question].type===1) {
        
        return (<OptionSingle data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type} all_total_question={all_total_question}/>);

    }else if (data.data[technical_aptitude_question_type][active_question].type===2){

        return (<OptionMulti data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData} technical_aptitude_question_type={technical_aptitude_question_type} all_total_question={all_total_question}/>);
    
    }else if (data.data[technical_aptitude_question_type][active_question].type===3){
        
        return (<OptionNumeric data={data} active_question={active_question} config={config} setActiveQuestion={setActiveQuestion} setData={setData}  technical_aptitude_question_type={technical_aptitude_question_type} all_total_question={all_total_question}/>);
    }
 
 
 
      
    }  
    

export default QuestionOption;