import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
//import { Document, Page } from '../node_modules/react-pdf/dist/esm/index.webpack5';
export const PyqTopic= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
   /* const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }*/
    
    let pageDefault=location.state;

     // console.log(pageDefault.pdf);
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 
      
      useEffect(() => { 
              
       let PDF = IMAGE_URL+pageDefault.pdf;

       return () => {
            
        }

      },[pageDefault]);
      
        return (
        
            <>
            <main>
             <Header />
             {loader ? <Loader />: ""}

<div>
      {/*<Document file="{PDF}" onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>*/}
    </div>



        <ProfileSideMenu />
                 
          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PyqTopic;