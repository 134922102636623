import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {secondsToHms} from './elements/AppHelper';

import { APP_URL} from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { Chart } from "react-google-charts";

export const PracticeSubjectAnalysis= ()=>{
    
      
    let api_token=localStorage.getItem('_user_token');
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    let pageDefault=location.state;
   
    const navigate = useNavigate();
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
        chapter_breakdown:[
            ["Task", "Hours per Day"],
            ["Completed", 0],
            ["Ongoing", 0],
            ["Unseen", 0],
          ],
        question_breakdown:[
            ["Task", "Hours per Day"],
            ["Correct", 0],
            ["Incorrect", 0],
            ["Skipped", 0],
            ["Unseen", 0],
          ],
        time_breakdown:[
            ["Task", "Hours per Day"],
            ["Correct", 0],
            ["Incorrect", 0],
            ["Skipped", 0],
          ],
      }); 
      
      
      
      useEffect(() => { 
              
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_subject_analysis',{headers,params:{subject_id:pageDefault.subject_id}},[])
           .then(response=>{
            setLoader(false);
            
            
            let chapter_breakdown = [
                ["Task", "Hours per Day"],
                ["Completed", response.data.chapter_breakdown.total_completed_topic],
                ["Ongoing", response.data.chapter_breakdown.total_ongoing_topic],
                ["Unseen", response.data.chapter_breakdown.total_unseen_topic],
              ];
            
              let question_breakdown = [
                ["Task", "Hours per Day"],
                ["Correct", response.data.question_breakdown.total_correct],
                ["Incorrect", response.data.question_breakdown.total_incorrect],
                ["Skipped", response.data.question_breakdown.total_skip],
                ["Unseen", response.data.question_breakdown.total_unseen],
              ];
              
              let time_breakdown = [
                ["Task", "Hours per Day"],
                ["Correct", response.data.question_breakdown.total_correct_time],
                ["Incorrect", response.data.question_breakdown.total_incorrect_time],
                ["Skipped", response.data.question_breakdown.total_skip_time],
              ];
           
            setData({
                status: response.data.status,
                message: response.data.message,
                data:response.data,
                chapter_breakdown: chapter_breakdown,
                question_breakdown: question_breakdown,
                time_breakdown: time_breakdown,
              });
             
             
            
             
           })
           .catch(error=>{
            if (error.response.status===401) {
              localStorage.clear();
              navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data:null,
                 chapter_breakdown:[
                    ["Task", "Hours per Day"],
                    ["Completed", 0],
                    ["Ongoing", 0],
                    ["Unseen", 0],
                  ],
                question_breakdown:[
                    ["Task", "Hours per Day"],
                    ["Correct", 0],
                    ["Incorrect", 0],
                    ["Skipped", 0],
                    ["Unseen", 0],
                  ],
                time_breakdown:[
                    ["Task", "Hours per Day"],
                    ["Correct", 0],
                    ["Incorrect", 0],
                    ["Skipped", 0],
                  ],
              });
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data:null,
            chapter_breakdown:[
                ["Task", "Hours per Day"],
                ["Completed", 0],
                ["Ongoing", 0],
                ["Unseen", 0],
              ],
            question_breakdown:[
                ["Task", "Hours per Day"],
                ["Correct", 0],
                ["Incorrect", 0],
                ["Skipped", 0],
                ["Unseen", 0],
              ],
            time_breakdown:[
                ["Task", "Hours per Day"],
                ["Correct", 0],
                ["Incorrect", 0],
                ["Skipped", 0],
              ],
          });
        //console.log(e);
    }

       return () => {
            
        }

      },[pageDefault]);
      
      
        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}


                    {/* Inner _age Banner End */}
                    <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.sub_category_name}   </span>
                                    <h3 className="text-uppercase text-left">{pageDefault.subject_name}</h3>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Practice</button>
                                    </div>
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          
                          
                          <NavLink to="/practice_chapter" className="nav-link" state={{subject_id:pageDefault.subject_id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:pageDefault.sub_category_image,subscription_id:pageDefault.subscription_id}}>Home</NavLink>
                        
                          <button className="nav-link active" id="nav-analysis-tab" data-bs-toggle="tab" data-bs-target="#nav-analysis" type="button" role="tab" aria-controls="nav-analysis" aria-selected="false">analysis</button>
                          
                          <NavLink to="/practice_subject_bookmark" className="nav-link" state={{subject_id:pageDefault.subject_id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:pageDefault.sub_category_image,subscription_id:pageDefault.subscription_id}}>saved</NavLink>

                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        
                        <div className="tab-pane fade show active" id="nav-analysis" role="tabpanel" aria-labelledby="nav-analysis-tab">
                          <div className="row">
                            <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 col-12">
                               <div className="row an_chart">
                                <div className="col-md-12">
                                    <h3><img alt="" className="img-fluid" src="/images/cb.png" />Topic Breakdown</h3>
                                </div>
                            <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                            <Chart
                            chartType="PieChart"
                            data={data.chapter_breakdown}
                            options={{
                                legend: 'none',
                                pieHole: 0.4,
                                is3D: false,
                                pieSliceText: 'value',
                                slices: {
                                    0: { color: "green" },
                                    1: { color: "orange" },
                                    2: { color: "grey" },
                                  },
                              }}
                            width={"100%"}
                            height={"200px"}
                            />

                            </div>
                          <div className="col-xl-6 col-md-6 col-sm-6 col-6 d-flex">
                          <div className=" m-auto chart_details" >
                          <p>Total Topic: <span>{data.status===true?data.data.chapter_breakdown.total_topic:0}</span></p>
                            
                             <p>Completed: <span>{data.status===true?data.data.chapter_breakdown.total_completed_topic:0}</span></p>
                            
                            <p>Ongoing: <span>{data.status===true?data.data.chapter_breakdown.total_ongoing_topic:0}</span></p>
                            
                            <p>Unseen: <span>{data.status===true?data.data.chapter_breakdown.total_unseen_topic:0}</span></p>
                          </div>
                          </div>


                               </div>
                            </div>
                            <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 col-12">
                            <div className="row an_chart">
                            <div className="col-md-12">
                                    <h3><img alt="" className="img-fluid" src="/images/qb.png" />Question Breakdown</h3>
                                </div>
                               <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                            <Chart
                            chartType="PieChart"
                            data={data.question_breakdown}
                            options={{
                                legend: 'none',
                                pieHole: 0.4,
                                is3D: false,
                                pieSliceText: 'value',
                                slices: {
                                    0: { color: "green" },
                                    1: { color: "orange" },
                                    2: { color: "grey" },
                                  },
                              }}
                            width={"100%"}
                            height={"200px"}
                            />
                              </div>
                               
                               <div className="col-xl-6 col-md-6 col-sm-6 col-6 d-flex">
                           <div className="m-auto chart_details">
                           <p>Total Questions: <span>{data.status===true?data.data.question_breakdown.total_question:0}</span></p>
                            
                             <p>Correct: <span>{data.status===true?data.data.question_breakdown.total_correct:0}</span></p>
                            
                            <p>Incorrect: <span>{data.status===true?data.data.question_breakdown.total_incorrect:0}</span></p>
                            
                            <p>Skipped: <span>{data.status===true?data.data.question_breakdown.total_skip:0}</span></p>
                            <p>Unseen: <span>{data.status===true?data.data.question_breakdown.total_unseen:0}</span></p>
                           </div>
                           </div>
                           </div>


                            </div>
                         



                          <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-12 col-12">
                               <div className="row an_chart">
                               <div className="col-md-12">
                                    <h3><img alt="" className="img-fluid" src="/images/tb.png" />Time Breakdown</h3>
                                </div>
                               <div className="col-xl-6 col-md-6 col-sm-6 col-6">
                            <Chart
                            
                            chartType="PieChart"
                            data={data.time_breakdown}
                            options={{
                                legend: 'none',
                                pieHole: 0.4,
                                is3D: false,
                                slices: {
                                    0: { color: "green" },
                                    1: { color: "orange" },
                                    2: { color: "grey" },
                                  },
                              }}
                            width={"100%"}
                            height={"200px"}
                           
                            />
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-6 col-6 d-flex">
                           <div className="m-auto chart_details">
                           <p>Total Spent: <span>{data.status===true?secondsToHms(data.data.question_breakdown.total_correct_time+data.data.question_breakdown.total_incorrect_time+data.data.question_breakdown.total_skip_time):0}</span></p>
                            
                             <p>Correct: <span>{data.status===true?secondsToHms(data.data.question_breakdown.total_correct_time):0}</span></p>
                            
                            <p>Incorrect: <span>{data.status===true?secondsToHms(data.data.question_breakdown.total_incorrect_time):0}</span></p>
                            
                            <p>Skipped: <span>{data.status===true?secondsToHms(data.data.question_breakdown.total_skip_time):0}</span></p>
                           </div>
                                  </div>
                                  </div>
                                  </div>

                                  </div>


                            <div className="bar_progress-wrapper">
                                <div className="bar_progress">
                                    <h5>Progress</h5>
                                    <div className="bar_line-wrapper" >
                                      
                                            <div className="bar_fider" style={{width:(data.status===true?data.data.question_breakdown.progress.toFixed(2)+"%":0+"%")}}>
                                                <div className="bar_line">
                                                <span>{data.status===true?data.data.question_breakdown.progress.toFixed(2):0}%</span>
                                            </div>
                                            <div className="bar_positive">

                                            </div>
                               
                                        </div>

                                    </div>
                                    
                                </div>
                                <div className="bar_progress">
                                    <h5>Accuracy</h5>
                                    <div className="bar_line-wrapper">
                                      
                                            <div className="bar_fider" style={{width:(data.status===true?data.data.question_breakdown.accuracy.toFixed(2)+"%":0+"%")}}>
                                                <div className="bar_line">
                                                
                                                <span>{data.status===true?data.data.question_breakdown.accuracy.toFixed(2):0}%</span>
                                            </div>
                          
                                        </div>

                                    </div>
                                    
                                </div>
                                <div className="bar_progress d-flex">
                                    <h5>Speed</h5>
                                    <h4>{data.status===true?data.data.question_breakdown.perhour_question.toFixed(2):0} Q/Hr</h4>
                                    
                                </div>
                            </div>
                        </div>
                        
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


                            
  
            <ProfileSideMenu />

          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PracticeSubjectAnalysis;