import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const PracticeChapter= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    
    const [show, setShow] = useState(false); //Popup Lck Code
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let pageDefault=location.state;

    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 
      
      useEffect(() => { 
              
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_chapter',{headers,params:{subject_id:pageDefault.subject_id}},[])
           .then(response=>{
            setLoader(false);
          
            setData({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
              }); 
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
            
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
       
    }

       return () => {
            
        }

      },[pageDefault]);
      

        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
             <Modal centered show={show} onHide={handleClose} keyboard={false} >
                <Modal.Header closeButton>
                  <Modal.Title>Locked!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 This section is locked, buy this subscription to unlock.
                </Modal.Body>
                <Modal.Footer>                  
                  <Button className="btn rounded-pill" style={{backgroundColor: "#214180"}} ><NavLink to="/subscription_details" style={{color: "#fff"}} state={{subscription_id:pageDefault.subscription_id}}>Buy Now</NavLink></Button>
                </Modal.Footer>
              </Modal>

                    {/* Inner _age Banner End */}
                    <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                        <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.sub_category_name}   </span>
                                    <h3 className="text-uppercase text-left">{pageDefault.subject_name}</h3>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Practice</button>
                                    </div>
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
                          <NavLink to="/practice_subject_analysis" className="nav-link" state={{subject_id:pageDefault.subject_id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:pageDefault.sub_category_image}}>Analysis</NavLink>
                          <NavLink to="/practice_subject_bookmark" className="nav-link" state={{subject_id:pageDefault.subject_id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:pageDefault.sub_category_image}}>Saved</NavLink>
                        </div>
                      </nav>
                      <div className="tab-content mb-5" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className="s_plan">
                            { data.status===true && data.data.map((chapter_list,key) => (
                                chapter_list.is_free == 0 ? (
                                    <div className="plan-card disable-plan running"  onClick={handleShow} key={key}>
                                    
                                      <h4>{chapter_list.label}</h4>
                                      <span>{chapter_list.name}</span>
                                      <div className="review-bar">
                                          <div className="review_left">
                                                  <h6>Topic Analysis</h6>
                                                  <div className="topic_data-wrapper">
                                                      <div className="topic_data">
                                                          <span>Completed</span>
                                                          <p>{chapter_list.total_completed_topic}</p>
                                                      </div>
                                                      <div className="topic_data">
                                                          <span>On Going</span>
                                                          <p>{chapter_list.total_ongoing_topic}</p>
                                                      </div>
                                                      <div className="topic_data">
                                                          <span>Not Started</span>
                                                          <p>{(chapter_list.total_topic-(chapter_list.total_completed_topic+chapter_list.total_ongoing_topic))}</p>
                                                      </div>
                                                      <div className="topic_data">
                                                          <span>Total</span>
                                                          <p>{chapter_list.total_topic}</p>
                                                      </div>
                                                  </div>
                                                 
                                          </div>
                                          <div className="review_right">
                                                  <button className="btn rounded-pill" style={{cursor: "no-drop",backgroundColor: "#214180"}}  type="">Locked</button>
                                          </div>
                                      </div>
                                      <div className="gear_bg">
                                          <div className="gear_bg-inner">
                                              <img alt="" className="" src="/images/plan-card/bg-gear.png" />
                                          </div>
                                      </div>
                                      <div className="card_status">
                                         
                                      <div style={{ width: 100, height: 100 }}>
                                                 <CircularProgressbarWithChildren value={data.status===true?Math.round(100*chapter_list.total_completed_topic/chapter_list.total_topic):0} styles={{
                                                          // Customize the root svg element
                                                          root: {},
                                                          // Customize the path, i.e. the "completed progress"
                                                          path: {
                                                          // Path color
                                                          stroke: `blue`,
                                                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                          strokeLinecap: 'butt',
                                                          // Customize transition animation
                                                          transition: 'stroke-dashoffset 0.5s ease 0s',
                                                          // Rotate the path
                                                          transform: 'rotate(0.0turn)',
                                                          transformOrigin: 'center center',
                                                          },
                                                          // Customize the circle behind the path, i.e. the "total progress"
                                                          trail: {
                                                          // Trail color
                                                          stroke: '#d6d6d6',
                                                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                          strokeLinecap: 'butt',
                                                          // Rotate the trail
                                                          transform: 'rotate(0.25turn)',
                                                          transformOrigin: 'center center',
                                                          },
                                                          // Customize the text
                                                          text: {
                                                          // Text color
                                                          fill: '#000000',
                                                          // Text size
                                                          fontSize: '16px',
                                                          },
                                                          // Customize background - only used when the `background` prop is true
                                                          background: {
                                                          fill: '#3e98c7',
                                                          },
                                                      }}>
                                                       <div className="inside-circle blue fw-bold"> {Math.round(100*chapter_list.total_completed_topic/chapter_list.total_topic)}% </div>
                                                      </CircularProgressbarWithChildren>
                                                     
                                                      </div>
  
                                      </div>
                                      
                                  </div>
                                   ) : 
                                <div className="plan-card  running" key={key}>
                                  
                                    <h4>{chapter_list.label}</h4>
                                    <span>{chapter_list.name}</span>
                                    <div className="review-bar">
                                        <div className="review_left">
                                                <h6>Topic Analysis</h6>
                                                <div className="topic_data-wrapper">
                                                    <div className="topic_data">
                                                        <span>Completed</span>
                                                        <p>{chapter_list.total_completed_topic}</p>
                                                    </div>
                                                    <div className="topic_data">
                                                        <span>On Going</span>
                                                        <p>{chapter_list.total_ongoing_topic}</p>
                                                    </div>
                                                    <div className="topic_data">
                                                        <span>Not Started</span>
                                                        <p>{(chapter_list.total_topic-(chapter_list.total_completed_topic+chapter_list.total_ongoing_topic))}</p>
                                                    </div>
                                                    <div className="topic_data">
                                                        <span>Total</span>
                                                        <p>{chapter_list.total_topic}</p>
                                                    </div>
                                                </div>
                                               
                                        </div>
                                        <div className="review_right">
                                                <button className="btn rounded-pill" type="">Start Now</button>
                                        </div>
                                    </div>
                                    <div className="gear_bg">
                                        <div className="gear_bg-inner">
                                            <img alt="" className="" src="/images/plan-card/bg-gear.png" />
                                        </div>
                                    </div>
                                    <div className="card_status">
                                       
                                    <div style={{ width: 100, height: 100 }}>
                                               <CircularProgressbarWithChildren value={data.status===true?Math.round(100*chapter_list.total_completed_topic/chapter_list.total_topic):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `blue`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                     <div className="inside-circle blue fw-bold"> {Math.round(100*chapter_list.total_completed_topic/chapter_list.total_topic)}% </div>
                                                    </CircularProgressbarWithChildren>
                                                   
                                                    </div>

                                           
                                    </div>
                                    <NavLink to="/practice_topic" className="chap_btn" state={{chapter_id:chapter_list.id,subject_name:pageDefault.subject_name,chapter_name:chapter_list.name,chapter_label:chapter_list.label,sub_category_image:pageDefault.sub_category_image}}></NavLink>
                                </div>

                            ))}
                            
                            </div>
                        </div>
                       
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


                            
  
            <ProfileSideMenu />

          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PracticeChapter;