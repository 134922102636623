import React, { useState}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import { APP_URL} from '../constants/global'
import { useNavigate } from 'react-router-dom';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { SEO } from './elements/SEO';
export const Contact= ()=>{
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    
    
    let api_token=localStorage.getItem('_user_token');
    
    const [contact_data, setContactData] = useState({});
      
       
      const changeHandler = (e) => {

        const name = e.target.name;
        const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

       
        
        setContactData({
            ...contact_data,
            [name]: value
          })
      }


      

      function update_profile() {
        
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
          }; 
          
        setLoader(true);
        axios.post(APP_URL+'add_contact_inquiry',contact_data,{headers})
        .then(response=>{
            setLoader(false);
            if (response.data.status===true) {
                if (api_token && api_token.length>0) {
                    navigate("/dashboard");    
                }else{
                    navigate("/");    
                }
                
            }else{
               
            }
           
            
        })
        .catch(error=>{
            setLoader(false);
            if (error.response.status===401) {

               
                
            }
            
        })

    }

      const updateProfile = (e) => {
        e.preventDefault();

        if (validate(contact_data)===true) {
            update_profile();  
        }    
     }

      const validate = (values) => {
        const errors = {};
       
        if (!values.name) {
            errors.name = "Please enter your name.";
        }
        
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!values.email) {
            errors.email = "Please enter your email address.";
        }else if(!regEmail.test(values.email)){
            errors.email = "Email address is invalid";
        }

        if (!values.mobile) {
            errors.mobile = "Please enter your mobile number.";
        } else if (values.mobile.length!==10){
            errors.mobile = "Mobile number should be 10 digit";
        }   
        if (!values.comment) {
            errors.comment = "Please enter comment.";
        }   
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        }else{
            return false;
        }
        
      };

    return (
        <>
        <div>
<SEO
title='Contact Abhyaskaro: Your Path to Success in JEE, UPSC, and NEET Exams'
description='Get in touch with Abhyaskaro to excel in your JEE, UPSC, and NEET exams. Our expert guidance and comprehensive resources will help you achieve your academic goals. Contact us now to start your journey towards success.'
canonical='https://abhyaskaro.com/contact'
 />
</div>
        <main>
         <Header />
         {loader ? <Loader />: ""}
         <div className="signup_wrap h-100 justify-content-lg-start justify-content-md-start justify-content-sm-between justify-content-between">
            <div className="signup_top">
                
                <div className="signup_body">


                         {/* Complete-profile-start */}

                         <div className="signup-text text-xl-center text-lg-center text-md-center text-sm-center text-start my-xl-5 my-md-3 my-0">
                            <h4 className="blue fw-bold">Contact Inquiry</h4>
                            <p>Fill-Up Your Personal Information</p>
                            <form onSubmit={updateProfile}>
                                <div className="er_box">                                
                                <input type="text" name="name" placeholder="Name" className="form-control border-0" onChange={changeHandler} value={contact_data.name ? contact_data.name : ""}/>
                                <span className="text-danger contact-error">{formErrors.name}</span>
                                </div>
                                <div className="er_box">    
                                <input type="text" name="email" placeholder="Email Address" className="form-control border-0" onChange={changeHandler} value={contact_data.email ? contact_data.email : ""}/>
                                <span className="text-danger contact-error">{formErrors.email}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="mobile" placeholder="Mobile Number" className="form-control border-0" onChange={changeHandler} value={contact_data.mobile ? contact_data.mobile : ""}  onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} />
                                <span className="text-danger contact-error">{formErrors.mobile}</span>
                                </div>
                                <div className="er_box">
                                <input type="text" name="comment" placeholder="Comment" className="form-control border-0" onChange={changeHandler} value={contact_data.comment ? contact_data.comment : ""}/>
                                <span className="text-danger contact-error">{formErrors.comment}</span>
                                </div>

                                <div className="signup_bottom text-center my-4">
                                     <button className="btn" type="submit ">Next</button>
                                </div>
                            </form>
                        </div>

                         {/* Complete-profile-end */}

                      





                </div>
            </div>
            
        </div>
        {api_token && api_token.length>0?<ProfileSideMenu />:""}
         <Footer />
          </main>
         
        
        </>);

}
export default Contact;