import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {finalDateFormat,getDateTimeFormat} from './elements/AppHelper';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';


export const TestTopicWise= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    
    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
        
      }); 
    
    let pageDefault=location.state;

    
    
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + api_token
    }; 
    
    function activation_date_popup(activation_date_time){
        alert("Test will be lived on "+getDateTimeFormat(activation_date_time));
    }
    useEffect(() => { 
        
        try {
      
            setLoader(true);
       
            axios.get(APP_URL+'get_test_bytopiclist',{headers,params:{subject_id:pageDefault.subject_id,page:1}},[])
            .then(response=>{
             setLoader(false);
            
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data: response.data.data,
               });
              
               
             
              
            })
            .catch(error=>{
            
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data: null,
               });
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data: null,
           });
         //console.log(e);
     }
 
        return () => {
             
         }
 
       },[pageDefault]);

   
       return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
            
             {/* Inner _age Banner End */}
             <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex align-items-center">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-3 ms-sm-3 ms-xl-3">
                                    <span className="ae_heading text-start">{pageDefault.subject_name} </span>
                                    
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle drop-d-left" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        TOPIC WISE TEST
                                        </button>
                                        <ul className="dropdown-menu drop-d-inner" aria-labelledby="dropdownMenuButton1">
                                          <li><a className="dropdown-item active">topic wise test</a></li>
                                          <li>
                                          <NavLink to="/test_subject_wise" className="dropdown-item text-white" state={{subject_id:pageDefault.subject_id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,sub_category_image:pageDefault.sub_category_image}}>subject wise test</NavLink>
                                          </li>
                                      
                                            
                                        </ul>
                                      </div>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Test</button>
                                    </div>
                                </div>
                                <div className="page_overlay "></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-wise-sec-start----*/}

            <div className="topic_w-wrapper tw_list">
            { data.status===true && data.data.map((result,key) => ( 
                <div className="tw_card mb-0" key={key}>
                    <div className="tw_c-left">
                        <h4>{result.topic_name}</h4>
                        
                        <a target="_blank" href={result.pdf_doc?IMAGE_URL+result.pdf_doc:""} className=" btn rounded-pill gr-blue d-flex"><span>Revise</span><i className="las la-chevron-circle-right"></i></a>
                        
                        <div className="m_q-bar" dangerouslySetInnerHTML={{__html: result.description}}></div>

                          
                    </div>
                    <div className="tw_c-center d-none">
                        
                        {/* <div className="m-auto w-100">
                            <h5>Syllabus</h5>
                        <div className="syllab_wrap">
                            <ul className="m-0">
                                <li> SFG & Block Diagram</li>
                                <li> TimeDomain Analysis</li>
                                <li> Root Locus Diagram</li>
                                <li> SFG </li>
                                <li> TimeDomain</li>
                                <li> Root Locus Diagram</li>
                               
                            </ul>
                        </div>
                        </div> */}
                        
                    </div>
                    <div className="tw_c-right">
                    { result.get_test.map((test_list,childkey) => ( 
                         <React.Fragment key={childkey}>
                        {(() => {
                           
                            if (test_list.test_status===1) {
                            return (
                                <div className="test_bar d-block text-end justify-content-xl-end justify-content-md-end justify-content-end" key={key}>
                                <span className="tw_cr-test">{test_list.test_name}</span>
                                {test_list.can_start_test===1?  <NavLink to="/test_question" className="btn rounded-pill justify-content-between d-orange d-flex text-white" state={{test_id:test_list.id,test_name:test_list.test_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image,test_status:test_list.test_status,description:test_list.description}}>Start Now <i className="las la-chevron-circle-right"></i></NavLink>:<button className="btn rounded-pill justify-content-between d-blue d-flex" type="button" onClick={()=>activation_date_popup(test_list.activation_date_time)}><span>{finalDateFormat(test_list.activation_date_time)}</span><i className="las la-chevron-circle-right"></i></button>}
                                
                                
                            </div>
                            )
                            } else if (test_list.test_status===2) {
                            return (
                                <div className="test_bar d-block text-end justify-content-xl-end justify-content-md-end justify-content-end">
                                <span className="tw_cr-test">{test_list.test_name}</span>
                                <NavLink to="/test_question" className="btn rounded-pill justify-content-between gr-green d-flex text-white" state={{test_id:test_list.id,test_name:test_list.test_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image,description:test_list.description}}>Resume<i className="las la-chevron-circle-right"></i></NavLink>

                            </div>
                            )
                            } else {
                            return (
                                <div className="test_bar d-block text-end justify-content-xl-end justify-content-md-end justify-content-end">
                                <span className="tw_cr-test">{test_list.test_name}</span>
                              
                                <NavLink to="/test_score_card" className="btn rounded-pill justify-content-between gr-purple d-flex text-white" state={{test_id:test_list.id,test_name:test_list.test_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image}}>Score Board <i className="las la-chevron-circle-right"></i></NavLink>

                            </div>
                            )
                            }
                        })()}
                        </React.Fragment>
                      
                    ))}
                    
                    <NavLink to="/test_by_single_topic" className="tw_v-all btn rounded-pill justify-content-between align-items-center w-100 gr-blue d-flex text-white" state={{topic_id:result.id,subject_name:pageDefault.subject_name,sub_category_name:pageDefault.sub_category_name,topic_name:result.topic_name,sub_category_image:pageDefault.sub_category_image}}>View All <i className="las la-chevron-circle-right"></i></NavLink>
                        
                        
                    </div>
                    <div className="gear_bg">
                        <div className="gear_bg-inner">
                            <img alt="" className="" src="/images/plan-card/bg-gear.png" />
                        </div>
                    </div>
                </div>
                  ))}
                  
            </div>
           

            {/*-----Topic-wise-sec-end--*/}


            
  
            <ProfileSideMenu />
         
    


             <Footer />
              </main>
             
            
            </>);
    }  
    

export default TestTopicWise;