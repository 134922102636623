import React, { useState }from "react";
import { APP_URL} from '../../../constants/global';
import axios from '../../general/axios.js';
import {Loader} from '../../elements/Loader';
export const TabQuestionNumbers= ({data,active_question,setActiveQuestion,setData,technical_aptitude_question_type})=>{
let api_token=localStorage.getItem('_user_token');
const [loader, setLoader] = useState(false);
const [filter_state, setFilterState] = useState("all");



function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
       
    setLoader(true);
    try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
        }; 
        
        axios.post(APP_URL+"add_single_test_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
        .then(response=>{
          /* if (response.data.status===true) {
                
             }else{
              
            } */
            setLoader(false);   
            
        })
        .catch(error=>{
            
            setLoader(false);
           
            
        })

       
    
  } catch (e) { //code will jump here if there is a network problem
    console.log("error");
    setLoader(false);  
   
}
}



const updateCurrentPage=(updated_question)=>{

   console.log(updated_question);

    if (data.data[technical_aptitude_question_type][active_question].user_selected_answer && data.data[technical_aptitude_question_type][active_question].user_selected_answer.length>0)
    {
        if (data.data[technical_aptitude_question_type].length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }

    }else{
        
         data.data[technical_aptitude_question_type][active_question].is_skip=1;
         data.data[technical_aptitude_question_type][active_question].taken_time=localStorage.getItem('seconds');
         
         add_single_question_answer(data.data[technical_aptitude_question_type][active_question].id,data.data[technical_aptitude_question_type][active_question].type,null,1,data.data[technical_aptitude_question_type][active_question].chapter_id,data.data[technical_aptitude_question_type][active_question].subject_id);
         setData({status:true,message:"",data:data.data});
        if (data.data[technical_aptitude_question_type].length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }
       
    }



        
    return null;
    }
    return (
        <>
        {loader ? <Loader />: ""}
        <div className="tab-pane fade show active " id="questions" role="tabpanel" aria-labelledby="questions-tab">

    <ul className="filter-menu filter-menu-test d-flex justify-content-start p-0 mt-4 align-items-center">
        <li className="  d-flex all_btn " data-target="all" onClick={()=>setFilterState("all")}>All</li>
        
        <li className=" justify-content-end d-flex right " data-target="right" onClick={()=>setFilterState("oa")}><span className="bl-blue"></span><p></p></li>
            {/* oa = only answer */}
        <li className=" justify-content-end d-flex review " data-target="review" onClick={()=>setFilterState("na-m")} ><span className="position-relative "></span><p></p></li>
        {/* na-m = no answer-mark */}
            
        <li className=" justify-content-end d-flex am_mark " data-target="m-review" onClick={()=>setFilterState("a-m")}><span className="bl-blue position-relative "></span><p></p></li>
        {/* a-m = answer-mark */}
        <li className=" justify-content-end d-flex s-next " data-target="s-next" onClick={()=>setFilterState("bm")}><span className="position-relative "> <label className="bookmark active">
        <input className="bookmark__toogle" type="checkbox" />
        <div className="bookmark__stroke"></div>
        <div className="bookmark__fill"></div>
        </label></span><p></p></li>
          {/* b-m = bookmark */}
        <li className=" justify-content-end d-flex  bg-transparent" data-target="skip" onClick={()=>setFilterState("nd")}><span className="skip"></span><p></p></li>
         {/* nd = nothing did */}
    </ul>
                        
        <div className="q_book-list re ">
        { data.status===true && data.data[technical_aptitude_question_type].filter(data_filter => {
                     
                            if (filter_state==="all"){
                                
                                return true 
                            }else if (filter_state==="a-m") {
                                
                            if ((data_filter.user_selected_answer && data_filter.user_selected_answer.length>0) && data_filter.mark_review===1) {
                                return true 
                            }

                            }else if (filter_state==="na-m"){
                               
                                if(!data_filter.user_selected_answer && data_filter.mark_review===1){
                                    return true
                                }
                                
                            }else if (filter_state==="oa"){
                               
                                if (data_filter.user_selected_answer && data_filter.user_selected_answer.length>0 && (data_filter.mark_review===null || data_filter.mark_review===0) && (data_filter.is_bookmark===null || data_filter.is_bookmark===0)) {
                                    return true 
                                }
                            }else if (filter_state==="bm"){
                               
                                if (data_filter.is_bookmark===1) {
                                    return true 
                                }
                            }else if (filter_state==="nd"){
                                
                                if(!data_filter.user_selected_answer && (data_filter.mark_review===null || data_filter.mark_review===0) && (data_filter.is_bookmark===null || data_filter.is_bookmark===0)){
                                    return true 
                                }
                                
                                
                            }

                            return false;
                     
                        

                        }).map((result,key) => (
              <React.Fragment key={key}>
             {(() => {
                if ((result.user_selected_answer && result.user_selected_answer.length>0) && result.mark_review===1) { //answer diya + mark kiya
                    return (<div className="q_book-box d-flex m-auto mark-done answer-done  rounded-circle bl-blue" key={key} onClick={()=>updateCurrentPage(key)}>
                        
                       <span className="bl-blue position-relative "></span>
                        {/* ul to be remove after code done */}
                    <span className="m-auto">{result.sr_number}</span>
                    {(result.is_bookmark===1)? <label className="bookmark active">
        <input className="bookmark__toogle" type="checkbox" />
        <div className="bookmark__stroke"></div>
        <div className="bookmark__fill"></div>
        </label>:""}
                </div>)
                }else if(!result.user_selected_answer && result.mark_review===1){ // only mark
                return (<div className="q_book-box d-flex m-auto rounded-circle mark-done" key={key}  onClick={()=>updateCurrentPage(key)}>
                    <span className="m-auto">{result.sr_number}</span>
                    {(result.is_bookmark===1)? <label className="bookmark active">
        <input className="bookmark__toogle" type="checkbox" />
        <div className="bookmark__stroke"></div>
        <div className="bookmark__fill"></div>
        </label>:""}
                </div>)
                }else if(result.is_skip===1){
                return (<div className="q_book-box d-flex m-auto rounded-circle b-gray" key={key}  onClick={()=>updateCurrentPage(key)}>
                    <span className="m-auto">{result.sr_number}</span>
                    {(result.is_bookmark===1)? <label className="bookmark active">
        <input className="bookmark__toogle" type="checkbox" />
        <div className="bookmark__stroke"></div>
        <div className="bookmark__fill"></div>
        </label>:""}
                    
                </div>)
                }else if(result.user_selected_answer && result.user_selected_answer.length>0 && (result.mark_review===null || result.mark_review===0)){
                    return (<div className="q_book-box d-flex m-auto rounded-circle answer-done bl-blue" key={key} onClick={()=>updateCurrentPage(key)}>
                    <span className="m-auto">{result.sr_number}</span>
                    {(result.is_bookmark===1)? <label className="bookmark active">
        <input className="bookmark__toogle" type="checkbox" />
        <div className="bookmark__stroke"></div>
        <div className="bookmark__fill"></div>
        </label>:""}
                    
                </div>)
                }else if(!result.user_selected_answer && (result.mark_review===null || result.mark_review===0) && (result.is_bookmark===null || result.is_bookmark===0)){
                    return (<div className="q_book-box d-flex m-auto rounded-circle nothingdid" key={key} onClick={()=>updateCurrentPage(key)}>
                    <span className="m-auto">{result.sr_number}</span>
                    {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                
                            </div>)
                }else{
                    return (<div className="q_book-box d-flex m-auto rounded-circle" key={key} onClick={()=>updateCurrentPage(key)}>
                    <span className="m-auto">{result.sr_number}</span>
                    {(result.is_bookmark===1)? <label className="bookmark active">
                    <input className="bookmark__toogle" type="checkbox" />
                    <div className="bookmark__stroke"></div>
                    <div className="bookmark__fill"></div>
                    </label>:""}
                                
                            </div>)
                }
            })()}
             
             </React.Fragment>
        ))}
       
        </div>
    </div>
        </>
        
            
        );
   
      
    }  
    

export default TabQuestionNumbers;