import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {secondsToHms} from './elements/AppHelper';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { Chart } from "react-google-charts";

export const PracticeTopicAnalysis= ()=>{
    
      
    let api_token=localStorage.getItem('_user_token');
    const [loader, setLoader] = useState(false);
    const location = useLocation();
   
    let pageDefault=location.state;
    
    const navigate = useNavigate();
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
        progress:[
            ["Task", "Hours per Day"],
            ["Correct", 0],
            ["Incorrect", 0],
            ["Unseen", 0],
          ],
        accuracy:[
            ["Task", "Hours per Day"],
            ["Correct", 0],
            ["Incorrect", 0],
          ],
        speed:[
            ["Task", "Hours per Day"],
            ["Correct", 0],
            ["Incorrect", 0],
            ["Skip", 0],
          ],
      }); 
      
      
      
      useEffect(() => { 
              
        try {
         const headers = {
             "Content-Type": "application/json",
             Authorization: "Bearer " + api_token
             }; 
            setLoader(true);
       
            axios.get(APP_URL+'get_topic_analysis',{headers,params:{topic_id:pageDefault.topic_id}},[])
            .then(response=>{
             setLoader(false);
             
             let progress = [
                 ["Task", "Hours per Day"],
                 ["Correct", response.data.data.total_correct],
                 ["Incorrect", response.data.data.total_incorrect],
                 ["Unseen", response.data.data.total_question-(response.data.data.total_correct+response.data.data.total_incorrect+response.data.data.total_skip)],
               ];
             
               let accuracy = [
                 ["Task", "Hours per Day"],
                 ["Correct", response.data.data.total_correct],
                 ["Incorrect", response.data.data.total_incorrect],
               ];
               
               let speed = [
                 ["Task", "Hours per Day"],
                 ["Correct", response.data.data.total_correct_time],
                 ["Incorrect", response.data.data.total_incorrect_time],
                 ["Skip", response.data.data.total_skip_time],
               ];
            
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data:response.data.data,
                 progress: progress,
                 accuracy: accuracy,
                 speed: speed,
               });
              
              
             
              
            })
            .catch(error=>{
            
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data:null,
                 progress:[
                     ["Task", "Hours per Day"],
                     ["Correct", 0],
                     ["Incorrect", 0],
                     ["Unseen", 0],
                   ],
                 accuracy:[
                     ["Task", "Hours per Day"],
                     ["Correct", 0],
                     ["Incorrect", 0],
                   ],
                 speed:[
                     ["Task", "Hours per Day"],
                     ["Correct", 0],
                     ["Incorrect", 0],
                     ["Skip", 0],
                   ],
               });
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data:null,
             progress:[
                 ["Task", "Hours per Day"],
                 ["Correct", 0],
                 ["Incorrect", 0],
                 ["Unseen", 0],
               ],
             accuracy:[
                 ["Task", "Hours per Day"],
                 ["Correct", 0],
                 ["Incorrect", 0],
               ],
             speed:[
                 ["Task", "Hours per Day"],
                 ["Correct", 0],
                 ["Incorrect", 0],
                 ["Skip", 0],
               ],
           });
         //console.log(e);
     }
 
        return () => {
             
         }
 
       },[pageDefault]);
       
     
      
        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}


                    {/* Inner _age Banner End */}
                    <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.chapter_name}   </span>
                                    <h3 className="text-uppercase text-left">{pageDefault.topic_name}</h3>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Practice</button>
                                    </div>
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          
                          
                        <button className="nav-link active" id="nav-analysis-tab" data-bs-toggle="tab" data-bs-target="#nav-analysis" type="button" role="tab" aria-controls="nav-analysis" aria-selected="false">analysis</button>
                        
                         <NavLink to="/practice_topic_bookmark" className="nav-link" state={{topic_id:pageDefault.topic_id,topic_name:pageDefault.topic_name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}>saved</NavLink>

                         <NavLink to="/practice_leader_board" className="nav-link" state={{topic_id:pageDefault.topic_id,topic_name:pageDefault.topic_name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}>Leader Board</NavLink>
                         
                        
                                                  
                     

                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        
                        <div className="tab-pane fade show active" id="nav-analysis" role="tabpanel" aria-labelledby="nav-analysis-tab">
                            
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                            <div className="row an_chart">
                                <div className="col-md-12">
                                    <h3><img alt="" className="img-fluid" src="/images/cb.png" />Progress</h3>
                                </div>
                            <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                            <Chart
                            chartType="PieChart"
                            data={data.progress}
                            options={{
                              legend: 'none',
                                pieHole: 0.4,
                                is3D: false,
                                pieSliceText: 'value',
                                slices: {
                                    0: { color: "green" },
                                    1: { color: "orange" },
                                    2: { color: "grey" },
                                  },
                              }}
                            width={"100%"}
                            height={"200px"}
                            />
                            </div>
                            <div className="col-xl-6 col-md-6 col-sm-12 col-12 d-flex">
                              <div className="m-auto chart_details">
                            <p>Total Questions: <span>{data.status===true?data.data.total_question:0}</span></p>
                            
                             <p>Correct: <span>{data.status===true?data.data.total_correct:0}</span></p>
                            
                            <p>Incorrect: <span>{data.status===true?data.data.total_incorrect:0}</span></p>
                            
                            <p>Unseen: <span>{data.status===true?data.data.total_question-(data.data.total_correct+data.data.total_incorrect+data.data.total_skip):0}</span></p>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                              <div className="row an_chart">
                                <div className="col-md-12">
                                    <h3><img alt="" className="img-fluid" src="/images/qb.png" />Accuracy</h3>
                                </div>
                            <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                            <Chart
                            chartType="PieChart"
                            data={data.accuracy}
                            options={{
                                // title: "Accuracy",
                                legend: 'none',
                                pieHole: 0.4,
                                is3D: false,
                                pieSliceText: 'value',
                                slices: {
                                    0: { color: "green" },
                                    1: { color: "orange" },
                                    2: { color: "grey" },
                                  },
                              }}
                            width={"100%"}
                            height={"200px"}
                            />
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-12 col-12 d-flex">
                              <div className="m-auto chart_details">
                            <p>Total Questions: <span>{data.status===true?data.data.total_correct+data.data.total_incorrect:0}</span></p>
                            
                            <p>Correct: <span>{data.status===true?data.data.total_correct:0}</span></p>
                            
                            <p>Incorrect: <span>{data.status===true?data.data.total_incorrect:0}</span></p>
                           </div>
                              </div>
                            </div>
                            </div>






                            <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                              <div className="row an_chart">
                                <div className="col-md-12">
                                    <h3><img alt="" className="img-fluid" src="/images/tb.png" />Speed</h3>
                                </div>
                            <div className="col-xl-6 col-md-6 col-sm-12 col-12">
                            <Chart
                            chartType="PieChart"
                           data={data.speed}
                            options={{
                                // title: "Time Breakdown",
                                legend: 'none',
                                pieHole: 0.4,
                                is3D: false,
                                pieSliceText: 'value',
                                slices: {
                                    0: { color: "green" },
                                    1: { color: "orange" },
                                    2: { color: "grey" },
                                  },
                              }}
                            width={"100%"}
                            height={"200px"}
                            />
                              </div>
                              <div className="col-xl-6 col-md-6 col-sm-12 col-12 d-flex">
                              <div className="m-auto chart_details">
                              <p>Total Spent: <span>{data.status===true?secondsToHms(data.data.total_correct_time+data.data.total_incorrect_time+data.data.total_skip_time):0}</span></p>
                            
                            <p>Correct: <span>{data.status===true?secondsToHms(data.data.total_correct_time):0}</span></p>
                           
                           <p>Incorrect: <span>{data.status===true?secondsToHms(data.data.total_incorrect_time):0}</span></p>
                           
                           <p>Skip: <span>{data.status===true?secondsToHms(data.data.total_skip_time):0}</span></p>
                           </div>
                              </div>
                            </div>
                            </div>
                              </div>


                          



                           

                           

                          
                        </div>
                        
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


                            
  
            <ProfileSideMenu />

          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PracticeTopicAnalysis;