import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {BookMarkSmile} from './elements/BookMarkSmile';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
export const PracticeSubjectBookmark= ()=>{
  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"]
      ]
    }
  };
  const option_array = {
    0:"A",
    1:"B",
    2:"C",
    3:"D",
  }
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();

    let pageDefault=location.state;
        
    const [data, setData] = useState({
        status:false,
        message:"",
        data:null,
      }); 
      
      useEffect(() => { 
              
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_bookmark_question',{headers,params:{topic_id:pageDefault.topic_id}},[])
           .then(response=>{
            setLoader(false);
           
            setData({
                status: response.data.status,
                message: response.data.message,
                data: response.data.data,
              });
             
              
            
             
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                data: null,
              });
            //console.log(error);
           })
  
   
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            data: null,
          });
        //console.log(e);
    }

       return () => {
            
        }

      },[pageDefault]);
      

        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}


                    {/* Inner _age Banner End */}
                    <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.chapter_name}   </span>
                                    <h3 className="text-uppercase text-left">{pageDefault.topic_name}</h3>
                                 </div>
                                    </div>
                                    <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Practice</button>
                                    </div>
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          
                          
                         

                          <NavLink to="/practice_topic_analysis" className="nav-link" state={{topic_id:pageDefault.topic_id,topic_name:pageDefault.topic_name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}>Analysis</NavLink>

                          <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Saved</button>

                          <NavLink to="/practice_leader_board" className="nav-link" state={{topic_id:pageDefault.topic_id,topic_name:pageDefault.topic_name,chapter_name:pageDefault.chapter_name,sub_category_image:pageDefault.sub_category_image}}>Leader Board</NavLink>

                        
                          

                       

                         

                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        
                        <div className="tab-pane fade show active" id="nav-saved" role="tabpanel" aria-labelledby="nav-saved-tab">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                
                                
                            { data.status===true && data.data.map((result,key) => ( 
                                <div className="accordion-item" key={key}>
                                  <h2 className="accordion-header" id={"flush-headingOne"+key}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne"+key} aria-expanded="false" aria-controls={"flush-collapseOne"+key}>
                                    <div className="n_text position-relative">
                                    
                                     <span className="m-auto ">{key+1}
                                        
                                     </span> 
                                     <BookMarkSmile is_true={result.is_true}/>
                                     
                                   </div>
                                     <div className="ah_head"> 
                                             
                                      <MathJaxContext config={config} className="demo ">
                                          <MathJax dynamic={true}><p dangerouslySetInnerHTML={{__html: result.question}}></p></MathJax>
                                      </MathJaxContext>
                                     </div>
                                     <i className="las la-caret-down"></i>
                                    </button>
                                  </h2>
                                  <div id={"flush-collapseOne"+key} className="accordion-collapse collapse" aria-labelledby={"flush-headingOne"+key} data-bs-parent="#accordionFlushExample">

                                    <div className="accordion-body">
                                    {result.type===1?<div className="an_b-wrap pyqs_wrap mcq-solution">
                                      { result.get_option.map((option,option_key) => ( 
                                          <React.Fragment  key={option_key}>
                                        {(() => {
                                          
                                          /* if (result.user_selected_answer==="[]") {
                                              result.user_selected_answer=null;
                                          } */
                                      
                                          
                                          if (result.user_selected_answer && result.user_selected_answer.length>0) {
                                              
                                              if (option.is_true===1) {
                                                  return (
                                              
                                                      <div className="d-flex justify-content-between an_b-n right_ans">
                          
                                                      <div className="an_bl d-flex">
                                                          <div className="an_count me-3 d-flex rounded-circle ">
                                                              <span className="m-auto">{option_array[option_key]}</span>
                                                          </div>
                                                          <div className="an_dummy d-flex">
                                                          <MathJaxContext config={config} className="demo">
                                                              <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                                          </MathJaxContext>
                          
                                                              
                                                          </div>
                                                      </div>
                                                    
                                                  </div>
                                                  )
                                              
                                              }else{
                                                
                                                  return (
                                                  <div className={(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n wrong_ans":"d-flex justify-content-between an_b-n"}>
                          
                                                      <div className="an_bl d-flex">
                                                          <div className="an_count me-3 d-flex rounded-circle ">
                                                              <span className="m-auto">{option_array[option_key]}</span>
                                                          </div>
                                                          <div className="an_dummy d-flex">
                                                          <MathJaxContext config={config} className="demo">
                                                              <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                                          </MathJaxContext>
                          
                                                              
                                                          </div>
                                                          {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                                            <span>Your Answer</span>
                                                         </div>:""}
                                                      </div>
                                                    
                                                  </div>
                                                  )
                                              }
                                          
                                          }else {
                                          return (
                                              <div className={result.user_selected_answer && (JSON.parse(result.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n right_ans":"d-flex justify-content-between an_b-n"}>
                                              <div className="an_bl d-flex">
                                                  <div className="an_count me-3 d-flex rounded-circle ">
                                                      <span className="m-auto">{option_array[option_key]}</span>
                                                  </div>
                                                  <div className="an_dummy d-flex">
                                                  <MathJaxContext config={config} className="demo">
                                                      <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                                  </MathJaxContext>

                                                      
                                                  </div>
                                                  {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                                            <span>Your Answer</span>
                                                         </div>:""}
                                              </div>
                                            
                                          </div>
                                          )
                                          }
                                      })()}
                                        
                                        </React.Fragment>
                                      ))}
                                          
                                      </div>:""}


                                      {result.type===2?<div className="an_b-wrap pyqs_wrap mcq-solution">
                                        { result.get_option.map((option,option_key) => ( 
                                          
                                          <React.Fragment  key={option_key}>
                                          {(() => {
                                            if (result.user_selected_answer && result.user_selected_answer.length>0) {
                                            
                                                if (option.is_true===1) {
                                                    return (
                                                
                                                        <div className="d-flex justify-content-between an_b-n right_ans">
                            
                                                        <div className="an_bl d-flex">
                                                            <div className="an_count me-3 d-flex rounded-circle ">
                                                                <span className="m-auto">{option_array[option_key]}</span>
                                                            </div>
                                                            <div className="an_dummy d-flex">
                                                            <MathJaxContext config={config} className="demo">
                                                                <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                                            </MathJaxContext>
                            
                                                                
                                                            </div>
                                                            {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                                            <span>Your Answer</span>
                                                         </div>:""}
                                                        </div>
                                                      
                                                    </div>
                                                    )
                                                
                                                }else{
                                                  
                                                    return (
                                                    <div className={(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?"d-flex justify-content-between an_b-n wrong_ans":"d-flex justify-content-between an_b-n"}>
                            
                                                        <div className="an_bl d-flex">
                                                            <div className="an_count me-3 d-flex rounded-circle ">
                                                                <span className="m-auto">{option_array[option_key]}</span>
                                                            </div>
                                                            <div className="an_dummy d-flex">
                                                            <MathJaxContext config={config} className="demo">
                                                                <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                                            </MathJaxContext>
                            
                                                                
                                                            </div>
                                                            {(JSON.parse(result.user_selected_answer).includes(option.id.toString()))?<div className="an_br an_br-position d-flex">
                                                            <span>Your Answer</span>
                                                         </div>:""}
                                                        </div>
                                                      
                                                    </div>
                                                    )
                                                }
                                            
                                            }else {
                                            return (
                                                <div className="d-flex justify-content-between an_b-n ">
                                                <div className="an_bl d-flex">
                                                    <div className="an_count me-3 d-flex rounded-circle">
                                                        <span className="m-auto">{option_array[option_key]}</span>
                                                    </div>
                                                    <div className="an_dummy d-flex">
                                                    <MathJaxContext config={config} className="demo">
                                                        <MathJax dynamic={true}><span className="" dangerouslySetInnerHTML={{__html: option.option}}></span></MathJax>
                                                    </MathJaxContext>

                                                        
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            )
                                            }
                                        })()}
                                          
                                          </React.Fragment>
                                        ))}
                                            
                                        </div>:""}


                                        {result.type===3?<div className="an_b-wrap">
                                              {/* nat-solution.html */}
                                              { result.get_option.map((option,option_key) => ( 
                                        
                                        <React.Fragment  key={option_key}>
                                        {(() => {
                                          if (result.user_selected_answer && result.user_selected_answer.length>0) {
                                              
                                              let user_selected_answer_val=JSON.parse(result.user_selected_answer);

                                              if (result.is_true===1) {

                                              
                                                
                                                  return (
                                                      <div className="an_b-wrap nat-solution">
                                                    
                                                          <div className="nat_sol-bar nat_s-r my-1">
                                                              <div className="mb-2 d-flex">
                                                                  <span className="green fw-bold">Your Answer Is Correct</span>
                                                              </div>
                                                              <h1 className="text-dark m-0">{user_selected_answer_val[0]}</h1>
                                                          </div>   
                                                      
                                                      
                                                      </div>
                                                  )
                                              
                                              }else{
                                                
                                                  return (

                                                      <div className="an_b-wrap nat-solution">
                                                          <div className="nat_sol-bar nat_s-w my-1">
                                                              <div className="mb-2 d-flex">
                                                                  <span className="red fw-bold">Your Answer</span>
                                                              </div>
                                                              <h1 className="text-dark m-0 ">{user_selected_answer_val[0]}</h1>
                                                          </div>
                                                          <div className="nat_sol-bar nat_s-r my-1">
                                                              <div className="mb-2 d-flex">
                                                                  <span className="green fw-bold">Correct Answer</span>
                                                              </div>
                                                              <h1 className="text-dark m-0">Between {option.option_min} to {option.option_max}</h1>
                                                              </div>   
                                                          
                                                          
                                                          </div>

                                                    
                                                  )
                                              }
                                          
                                          }else {
                                          return (
                                              <div className="nat_ans"> 
                                                      <label>Your Answer</label>
                                                        Not Given
                                                      </div>
                                          )
                                          }
                                      })()}
                                        
                                        </React.Fragment>
                                      ))}

                                              

                                                
                                            
                                          </div>:""}

                                     
                                      
                                      </div>
                                  </div>
                                </div>
                            ))}

                               
                                
                              </div>
                        </div>
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


                            
  
            <ProfileSideMenu />

          

             <Footer />
              </main>
             
            
            </>);
    }  
    

export default PracticeSubjectBookmark;