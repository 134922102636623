import React, { useState ,useRef}from "react";
import { APP_URL} from '../../../constants/global';
import { MathJax, MathJaxContext } from "better-react-mathjax";
import {secondsToHms} from '../../elements/AppHelper';
import axios from '../../general/axios.js';
export const OptionSingle= ({data,active_question,config,setActiveQuestion,setData})=>{
    
     let current_data=data.data[active_question];

     const numeric_answer = useRef(null);
     const changeHandler= (e) => {
        numeric_answer.current.value = e.target.value;
     }

      let api_token=localStorage.getItem('_user_token');
     

      const[rightansweranimation,setRightAnswerAnimation]=useState(false);
      const[wrongansweranimation,setWrongAnswersAnimation]=useState(false);
     
   


      function add_numeric_type_answer(question_id,question_type,is_skip,chapter_id,subject_id,event) {
        
       
        if (numeric_answer.current.value.length<=0) {
            return null;
        }
        
        
        if (current_data.get_option[0]['option_min']<=numeric_answer.current.value && current_data.get_option[0]['option_max']>=numeric_answer.current.value) {
            current_data.is_true=1;
         }else{
            current_data.is_true=0;
        }
        current_data.is_skip=0;
        current_data.user_selected_answer=JSON.stringify([numeric_answer.current.value]);
        current_data.taken_time=localStorage.getItem('seconds');

        if (current_data.get_practice_answer!=null) {
            current_data.get_practice_answer.average_time=(parseFloat(current_data.get_practice_answer.average_time)+current_data.taken_time)/current_data.get_practice_answer.total_solved;
            current_data.get_practice_answer.total_solved=current_data.get_practice_answer.total_solved+1;
        }

        data.data[active_question] = current_data;
        setData({status:true,message:"",data:data.data});
        
        if (current_data.is_true===1) {
                setRightAnswerAnimation(true)
                setTimeout(() => setRightAnswerAnimation(false), 2000);     
                
            }else{
                setWrongAnswersAnimation(true); 
                setTimeout(() => setWrongAnswersAnimation(false), 2000);     
                
            }
          
    add_single_question_answer(question_id,question_type,JSON.stringify([numeric_answer.current.value]),is_skip,chapter_id,subject_id);
}


    function add_single_question_answer(question_id,question_type,answered_option,is_skip,chapter_id,subject_id){
     
       
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
            }; 
            
            axios.post(APP_URL+"add_single_question_answer",{question_id:question_id,question_type:question_type,answered_option:answered_option,taken_time:localStorage.getItem('seconds'),is_skip:is_skip,chapter_id:chapter_id,subject_id:subject_id},{headers})
            .then(response=>{
                /* if (response.data.status===true) {
                    
                 }else{
                  
                } */
              
                
            })
            .catch(error=>{
                
             
               
                
            })

           
        
      } catch (e) { //code will jump here if there is a network problem
        console.log("error");
       
       
    }
    }
    

    const updateCurrentPage=(updated_question,question_id,is_skip=1)=>{

    let numericTextElements = document.getElementsByClassName('numeric_class_for_clear');
    for(let i=0; numericTextElements[i]; ++i){
        numericTextElements[i].value="";
    }   
    if (current_data.user_selected_answer && current_data.user_selected_answer.length>0)
    {
      
        if (data.data.length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }

    }else{
        
        current_data.is_skip=1;
        current_data.taken_time=localStorage.getItem('seconds');
        data.data[active_question] = current_data;
        add_single_question_answer(current_data.id,current_data.type,null,1,current_data.chapter_id,current_data.subject_id);
        setData({status:true,message:"",data:data.data});
        if (data.data.length===updated_question || updated_question===-1) {
            setActiveQuestion(0); 
        }else{
            setActiveQuestion(updated_question);    
        }
       
    }
    return null;
    }

       return (
            <>
            <div className="an_b-wrap">
                            {/* nat-solution.html */}
                            { current_data.get_option.map((option,option_key) => ( 
                       
                       <React.Fragment  key={option_key}>
                       {(() => {
                        if (current_data.user_selected_answer && current_data.user_selected_answer.length>0) {
                            
                            let user_selected_answer_val=JSON.parse(current_data.user_selected_answer);

                            if (current_data.is_true===1) {

                            
                              
                                return (
                                    <div className="an_b-wrap nat-solution">
                                   
                                        <div className="nat_sol-bar nat_s-r my-1">
                                            <div className="mb-2 d-flex">
                                                <span className="green fw-bold">Your Answer Is Correct</span>
                                            </div>
                                            <h1 className="text-dark m-0">{user_selected_answer_val[0]}</h1>
                                        </div>   
                                    
                                    
                                    </div>
                                )
                            
                            }else{
                              
                                 return (

                                    <div className="an_b-wrap nat-solution">
                                        <div className="nat_sol-bar nat_s-w my-1">
                                            <div className="mb-2 d-flex">
                                                <span className="red fw-bold">Enter numeric answer</span>
                                            </div>
                                            <h1 className="text-dark m-0 ">{user_selected_answer_val[0]}</h1>
                                        </div>
                                        <div className="nat_sol-bar nat_s-r my-1">
                                            <div className="mb-2 d-flex">
                                                <span className="green fw-bold">Range</span>
                                            </div>
                                            <h1 className="text-dark m-0">Between {option.option_min} to {option.option_max}</h1>
                                            </div>   
                                        
                                        
                                        </div>

                                   
                                )
                            }
                        
                        }else {
                        return (
                            <div className="nat_ans"> 
                                    <label>Enter numeric answer</label>
                                    <input type="text" name="numeric_answer" className="form-control numeric_class_for_clear"  ref={numeric_answer}  placeholder="Your Answer" onKeyPress={(event) => {if (!/^[-\d]*\.?[\d]{0,2}$/.test(event.key)) {event.preventDefault();}}} onChange={changeHandler}/>
                                    </div>
                        )
                        }
                    })()}
                       
                       </React.Fragment>
                    ))}

                            

                               
                           
                    </div>
            
                    {(current_data.user_selected_answer && current_data.user_selected_answer.length>0)?<><div className="ay_time-wrap d-flex justify-content-center">
                       <button className="btn d-flex yt-btn mx-2">
                           <img alt="" className="img-fluid" src="/images/avg-time1.png" />
                           <div className="ay_time d-grid">
                               <span>Your Time</span>
                               <span>{secondsToHms(current_data.taken_time)}</span>
                           </div>
                       </button>
                       <button className="btn d-flex at-btn mx-2">
                           <img alt="" className="img-fluid" src="/images/avg-time2.png" />
                           <div className="ay_time d-grid">
                               <span>Avg.Time</span>
                               <span>{current_data.get_practice_answer?secondsToHms(current_data.get_practice_answer.average_time):secondsToHms(0)}</span>
                           </div>
                       </button>
                       
                       {current_data.video && current_data.video.length>0?<button className="btn d-flex at-btn mx-2">
                          <a className="d-flex align-items-center" href={current_data.video} target="_blank">
                           <img alt="" className="img-fluid" src="/images/video.png" />
                           <div className="ay_time d-grid">
                               <span>Video <br />Link</span>
                           </div>
                           </a>
                       </button>:""}
                     </div>
                   <div className="com_sol-box">
                       <h3 className="blue">Solution</h3>
                       <div className="com_sol-box-inner">
                            <MathJaxContext config={config} className="demo">
                                <MathJax dynamic={true}><p className="gray" dangerouslySetInnerHTML={{__html: current_data.solution}}></p></MathJax>
                           </MathJaxContext>

                           
                       </div>
                   </div><div className="nav_b d-flex justify-content-around" >
            <button className="btn pr_btn" type="button" onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={()=>updateCurrentPage(active_question+1,current_data.id)}>Next</button>
    
           </div></>:<div className="nav_b d-flex justify-content-around" >
           <button className="btn pr_btn" type="button" onClick={()=>updateCurrentPage(active_question-1,current_data.id)}>Previous</button>
            <button className="btn ne_btn" type="button"  onClick={(event) =>updateCurrentPage(active_question+1,current_data.id)}>Next</button>
                <button className="btn ne_btn" type="button"  onClick={(event) =>add_numeric_type_answer(current_data.id,current_data.type,0,current_data.chapter_id,current_data.subject_id,event)}>Submit</button>
                </div>
               }

             
              <div className="show_q-r" style={{ display: rightansweranimation ? "block" : "none" }}>
                <div className=" show_q-r-inner">
                <img alt="" className="img-fluid" src="/images/Correct-Answer.gif" />
                </div>
                </div>

                <div className="show_q-r" style={{ display: wrongansweranimation ? "block" : "none" }}>
                     <div className=" show_q-r-inner wrong_qut">
                    <img alt="" className="img-fluid" src="/images/error-img.gif" />
                    </div>
                </div>


            </>
            
        );
   
      
    }  
    

export default OptionSingle;