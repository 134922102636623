import React, { useState}from "react";
import {Loader} from './elements/Loader';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {GetQuestionList} from './elements/pyqquestion/GetQuestionList';
import { IMAGE_URL} from '../constants/global';
import { useLocation } from 'react-router-dom';
export const Pyq= ()=>{
    const location = useLocation();
    let pageDefault=location.state;
    const [loader, setLoader] = useState(false);
    return (
    <>
        <Header />
        {loader ? <Loader />: ""}

        <div className="page_banner-wrapper">
            <div className="page_baneer bill_banner">
                <div className="p_yq">
                    <div className="p_yq-left d-flex">
                        <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                            <span className="ae_heading text-start">{pageDefault.chapter_name} </span>
                            <h3 className="text-uppercase text-left">{pageDefault.topic_name}</h3>
                        </div>
                    </div>
                    <div className="p_yq-right justify-content-end">
                        <button className="btn text-white text-end">Pyq</button>
                    </div>
                </div>
                <div className="page_overlay"></div>
            </div>
        </div>

        <div className="qm_wrapper pq_qm-wrapper">
            <GetQuestionList setLoader={setLoader} pageDefault={pageDefault}/>
        </div>
        {/*<Footer />*/}
    </>
    );     
}
export default Pyq;