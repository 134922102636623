import React, { useState ,useEffect}from "react";
import moment from 'moment';
import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import {getOnlyDate,getMonthYear} from './elements/AppHelper';
import {RatingStar} from './elements/RatingStar';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Message} from './elements/Message';
export const MyPlan= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const [flashShow, setFlashShow] = useState("");
    const [show, setShow] = useState({status:false,planlist:null,subscription_id:null});
    
    const handleShow=(subscription_plan,subscription_id)=>{
      
        setShow({status:true,planlist:subscription_plan,subscription_id:subscription_id})
    }

    function isInThePast(date) {
        const today = new Date();
      
        // 👇️ OPTIONAL!
        // This line sets the hour of the current date to midnight
        // so the comparison only returns `true` if the passed-in date
        // is at least yesterday
        today.setHours(23, 59, 59, 998);
        return date > today;
    }

    const handleClose = () => setShow({status:false,planlist:null,subscription_id:null});
    
      
    const [data, setData] = useState({
        status:false,
        message:"",
        get_single_plan:null,
      }); 

     const [type, setType] = useState(location.state!=null?location.state.type:"pyq"); 

  
    let redirect_url="practice";
      if (type==="practice") {
        redirect_url="/practice_chapter";
    }else if (type==="pyq") {
        redirect_url="/pyq_chapter";
    }else if (type==="test") {
        redirect_url="/test_topic_wise";
    }

      useEffect(() => { 
       try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
      
           axios.get(APP_URL+'get_myplan',{headers,params:{type:type}},[])
           .then(response=>{
            setLoader(false);
            
            setData({
                status: response.data.status,
                message: response.data.message,
                get_single_plan: response.data.get_single_plan,
                get_combo_plan: response.data.get_combo_plan,
              });
           })
           .catch(error=>{
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
            setLoader(false);  
            setData({
                status: false,
                message: "",
                get_single_plan: null,
                get_combo_plan: null,
              });
           })
        } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
        setData({
            status: false,
            message: "",
            get_single_plan: null,
            get_combo_plan: null,
          });
       
    }

       return () => {
            
        }

      },[type]);
      

      const add_to_cart=(subscription_id,type,duration,price,is_buy_subscription_click)=>{
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
                }; 
               setLoader(true);
          
               axios.post(APP_URL+"add_to_cart",{subscription_id:subscription_id,type:type,duration:duration,price:price},{headers})
               .then(response=>{
                setLoader(false);
                setShow({status:false,planlist:null,subscription_id:null})
                if (response.data.cart_total) {
                    localStorage.setItem('cart_count',response.data.cart_total);
                }else{
                    localStorage.removeItem('cart_count');
                }
                
                if (is_buy_subscription_click===1) {
                   navigate("/subscription_cart");
                }else{
                    setFlashShow(<Message variant="success" children={response.data.message}/>);    
                }
                
                
                
               })
               .catch(error=>{
                setLoader(false);  
                
              
               })
       
             } catch (e) { //code will jump here if there is a network problem
            setLoader(false);  
            
         
        }
    }
       return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
             <div className="page_banner-wrapper">   
                            <div className="page_baneer">
                               
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                        <img alt="" className="img-fluid" src="/images/pyq/pyq-bg.svg" />
                                 <div className="d-flex">
                                    <h1>My Plan</h1>
                                 </div>
                                    </div>
                                    <div className="p_yq-right">
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                             {type}
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                              <li><a className="dropdown-item" onClick={() => setType("pyq")}>PYQs</a></li>
                                              <li><a className="dropdown-item" onClick={() => setType("test")}>Test</a></li>
                                              <li><a className="dropdown-item" onClick={() => setType("practice")}>Practice</a></li>
                                            </ul>
                                          </div>
                                    </div>
                                </div>
                                <div className="page_overlay"></div>
                               
                            </div>

                          
                     

                            <ProfileSideMenu />
                    </div>

    
             <div className="s_plan-wrapper">
             {flashShow}
        
                <div className="s_plan">
                { data.status===true && data.get_single_plan.map((result,key) => ( 
                    <div className={result.is_active === 0?"plan-card running":"plan-card valid"} key={key}>
                        <img alt="" className="el_img" src={result.image?IMAGE_URL+result.image:"/images/plan-card/dummy.png"}/>
                        <h4>{result.name}</h4>
                        <div className="review-bar">
                        <div className="review_right">
                                <div className="review_right-top d-flex">
                                    <div className="review_right-top-inner">
                                        <img alt="" className="" src="/images/plan-card/r_user.png" />
                                    </div>
                                    <p><span>{result.total_rating_user} </span>Rating</p>
                                </div>
                                <div className="review_right-bottom d-flex justify-content-start">
                                    <h5>{result.total_rating?result.total_rating:0}</h5>
                                    <RatingStar rating_value={result.total_rating?result.total_rating:0} />
                                </div>
                            </div>
                            <div className="review_left">
                                <div className="review_left_inner d-flex m-auto w-100">
                                    <img alt="" className="" src="/images/plan-card/user.png" />
                                    <p><span>{result.total_enrolled} </span>Enrolled</p>
                                </div>
                                {result.is_active === 0 && result.duration_type === 0 ?<div className="sub_box">
                                <Button variant="primary" className="g_btn d-purple" onClick={()=>handleShow(result.subscription_plan,result.id)}>Renew</Button>
                                </div>:<div className="sub_box">
                                    {result.duration_type === 0 || (result.duration_type === 1 && result.is_active === 1 && !isInThePast(new Date(moment(result.subscription_plan[0].start_date, 'DD-MM-YYYY')))) ?
                                <NavLink to={redirect_url} className="g_btn d-orange " state={{subject_id:result.subject_id,subject_name:result.subject_name,sub_category_name:"dadsa"}}>Start Now</NavLink> : <span>{ result.duration_type === 1 && isInThePast(new Date(moment(result.subscription_plan[0].start_date, 'DD-MM-YYYY'))) ? <span>Start On: {moment(result.subscription_plan[0].start_date, 'DD-MM-YYYY').format('DD MMM, YYYY')}</span> :'' }</span>}
                                {result.duration_type === 0 ? <Button variant="primary" className="g_btn d-blue" onClick={()=>handleShow(result.subscription_plan,result.id)}>Extend</Button> : ''}
                                </div>}
                            </div>
                           
                        </div>
                        <div className="gear_bg">
                            <div className="gear_bg-inner">
                                <img alt="" className="" src="/images/plan-card/bg-gear.png" />
                            </div>
                        </div>
                        
                        {result.expiry_date || result.duration_type === 1 ? <div className="card_status">
                        {(result.duration_type === 1 && !isInThePast(new Date(moment(result.subscription_plan[0].end_date, 'DD-MM-YYYY')))) ? <div className="card_status-box"><small>Expired</small></div> :<div className="card_status-box">
                                <small>Valid till</small>
                                <h3>{getOnlyDate(result.expiry_date)}</h3>
                                <small><b>{getMonthYear(result.expiry_date)}</b></small>
                            </div>}
                        </div>:<NavLink to={redirect_url} className="card_status" state={{subject_id:result.subject_id,subject_name:result.subject_name,sub_category_name:""}}>
                            <div className="card_status-box">
                                <p>Start <br/> Demo</p>
                            </div>
                        </NavLink>}
                        {(result.duration_type === 1 && !isInThePast(new Date(moment(result.subscription_plan[0].end_date, 'DD-MM-YYYY')))) ? '' :                         
                        <NavLink to="/subscription_details" className="full_link" state={{subscription_id:result.id,sub_category_name:""}}></NavLink>}
                    </div>
                    ))}
                    
                </div>              
            </div>

            {data.status===true && (data.get_single_plan.length>0 || data.get_combo_plan.length>0)?"":<div className="default_d-box">
                            <div className="default-box">
                                    <img alt="" className="img-fluid" src="/images/bluebag.png" />
                                    <p className="m-0 mt-4"><b>Buy your favourites courses</b> </p>
                                    <span>Practice & give tests on any of your devices</span>
                            </div>
                    </div>}

            <div className="co_plan">
                <div className="s_plan-wrapper">
                
                    {data.status===true && data.get_combo_plan.length>0?<h2 className="co_heading">Combo Plans</h2>:""}

                   
                    <div className="s_plan">
                        
                    { data.status===true && data.get_combo_plan.map((result,key) => ( 
                    <div className={result.is_active === 0?"plan-card running":"plan-card valid"} key={key}>
                      <img alt="" className="el_img" src={result.image?IMAGE_URL+result.image:"/images/plan-card/dummy.png"}/>
                    <h4>{result.name}</h4>
                    <div className="review-bar">
                    <div className="review_right">
                            <div className="review_right-top d-flex">
                                <div className="review_right-top-inner">
                                
                                    
                                    <img alt="" className="" src="/images/plan-card/r_user.png" />
                                </div>
                                <p><span>{result.total_rating_user} </span>Rating</p>
                            </div>
                            <div className="review_right-bottom d-flex justify-content-start">
                                <h5>{result.total_rating?result.total_rating:0}</h5>
                               
                                <RatingStar rating_value={result.total_rating?result.total_rating:0} />
                                                                   
                                
                            </div>
                        </div>
                        <div className="review_left">
                            <div className="review_left_inner d-flex m-auto w-100">
                                <img alt="" className="" src="/images/plan-card/user.png" />
                                <p><span>{result.total_enrolled} </span>Enrolled</p>
                            </div>
                            {result.is_active === 0  && result.duration_type === 0 ?<div className="sub_box">
                            <Button variant="primary" className="g_btn d-purple" onClick={()=>handleShow(result.subscription_plan,result.id)}>Renew</Button>
                            </div>:<div className="sub_box">
                            {result.duration_type === 0 || (result.duration_type === 1 && result.is_active === 1 && !isInThePast(new Date(moment(result.subscription_plan[0].start_date, 'DD-MM-YYYY')))) ?
                            <NavLink to="/subject_by_subscription" className="g_btn" state={{subscription_id:result.id,subscription_name:result.name,type:type}}>Start Now</NavLink>: <span>{ result.duration_type === 1 && isInThePast(new Date(moment(result.subscription_plan[0].start_date, 'DD-MM-YYYY'))) ? <span>Start On: {moment(result.subscription_plan[0].start_date, 'DD-MM-YYYY').format('DD MMM, YYYY')}</span> :'' }</span>}
                            {result.duration_type === 0 ? <Button variant="primary" className="g_btn d-blue" onClick={()=>handleShow(result.subscription_plan,result.id)}>Extend</Button> : ''}
                            </div>}
                        </div>
                    </div>
                    <div className="gear_bg">
                        <div className="gear_bg-inner">
                            <img alt="" className="" src="/images/plan-card/bg-gear.png" />
                        </div>
                    </div>
                    
                    {result.expiry_date || result.duration_type === 1 ? <div className="card_status">
                    {(result.duration_type === 1 && !isInThePast(new Date(moment(result.subscription_plan[0].end_date, 'DD-MM-YYYY')))) ? <div className="card_status-box"><small>Expired</small></div> :<div className="card_status-box">
                            <small>Valid till</small>
                            <h3>{getOnlyDate(result.expiry_date)}</h3>
                            <small><b>{getMonthYear(result.expiry_date)}</b></small>
                        </div>}
                    </div>:<NavLink to="/subject_by_subscription" className="card_status" state={{subscription_id:result.id,subscription_name:result.name,type:type}}>
                        <div className="card_status-box">
                            
                            <p>Start <br/> Demo</p>
                        </div>
                    </NavLink>}
                    {(result.duration_type === 1 && !isInThePast(new Date(moment(result.subscription_plan[0].end_date, 'DD-MM-YYYY')))) ? '' :   
                    <NavLink to="/subscription_details" className="full_link" state={{subscription_id:result.id,sub_category_name:""}}></NavLink>}
                </div>
                    ))}
                        </div>
                        </div>
            </div>
            
                

                  
                    


            <Modal show={show.status} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub" >
              <Modal.Header closeButton>
                <Modal.Title><h3>Select Subscription</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-0">
              
               <div className="edit_bill-info p-0">
                        <div className="exam_catagory p-0 ">
                            
                            <div className="get_sub_box-wrap ">
                                
                            { show.status===true && show.planlist.map((plan,key) => ( 
                                <div className="get_sub-box d-xl-flex d-md-flx d-sm-block justify-content-between mb-3" key={key}>
                                   <div className="d-flex align-items-center">
                                    <div className="get_month d-flex align-items-center">
                                        <p><span>{plan.duration}</span></p>
                                        <small>Month</small>
                                    </div>
                                    <h5 className="offer_p ">₹{plan.dummy_price}/-</h5>
                                    <h5 className="final_p ">₹{plan.price}/-</h5>
                                   </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <button className="rounded-pill get_but-sub" type="button" onClick={()=>add_to_cart(show.subscription_id,type,plan.duration,plan.price,1)}>Buy Subscription</button>
                                    <div className="c_cart">
                                    
                                    <button type="button" className="p-0" onClick={()=>add_to_cart(show.subscription_id,type,plan.duration,plan.price,0)}><i className="las la-shopping-cart"></i></button>
                                        
                                    </div>
                                    </div>
                                </div>
                            ))}
                                
                                  
                            </div>
                        </div>
                       </div>
                
              </Modal.Body>
              {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer> */}
            </Modal>
            
         
    


             <Footer />
              </main>
             
            
            </>);
    }  
    

export default MyPlan;