import React, { useState ,useEffect}from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL } from '../constants/global';
import { useNavigate,useLocation,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import "../css/slick-theme.min.css";
import "../css/slick.min.css";
import Slider from "react-slick";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
export const TestAnalysisCompetitive= ()=>{
    
    let api_token=localStorage.getItem('_user_token');
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    

    const [data, setData] = useState({
        status:false,
        message:"",
        data: null,
        current_user_data: null,
        top_students: null,
        
      }); 
      let pageDefault=location.state;

         
    const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + api_token
    }; 


    useEffect(() => { 
        
        try {
      
            setLoader(true);
       
            axios.get(APP_URL+'get_comparitive',{headers,params:{test_id:pageDefault.test_id}},[])
            .then(response=>{
             setLoader(false);
            
             setData({
                 status: response.data.status,
                 message: response.data.message,
                 data: response.data.data,
                 current_user_data: response.data.current_user_data,
                 top_students: response.data.top_students,
               });
              
               
             
              
            })
            .catch(error=>{
            
            if (error.response.status===401) {
                localStorage.clear();
                navigate("/login");   
            }
             setLoader(false);  
             setData({
                 status: false,
                 message: "",
                 data: null,
                 current_user_data: null,
                 top_students: null,
               });
             //console.log(error);
            })
   
    
          } catch (e) { //code will jump here if there is a network problem
         setLoader(false);  
         setData({
             status: false,
             message: "",
             data: null,
             current_user_data: null,
             top_students: null,
           });
         //console.log(e);
     }
 
        return () => {
             
         }
 
       },[pageDefault]);
    
       var settings = {
        dir:true,
        responsive: [
            {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 1
            }
            },
            {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 1
            }
            }
        ]
      };
     
       
   
  
      

       return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
              {/* Inner _age Banner End */}
     <div className="page_banner-wrapper">   
                            <div className="page_baneer rounded-0">
                                <div className="p_yq">
                                    <div className="p_yq-left d-flex">
                                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                                 <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                                    <span className="ae_heading text-start">{pageDefault.topic_name}</span>
                                    <h3 className="text-uppercase text-left">{pageDefault.test_name}</h3>
                                 </div>
                                    </div>
                                    {/* <div className="p_yq-right justify-content-end">
                                        <button className="btn text-white text-end">Practice</button>
                                    </div> */}
                                </div>
                                <div className="page_overlay rounded-0"></div>                              
                            </div>
                    </div>
            {/* Inner _age Banner End */}


            {/*--Topic-start----*/}
            <div className="topic_wrapper">
                <div className="topic_box">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                         


                      

                        <NavLink to="/test_analysis_topic_wise" className="nav-link" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image}}>Topic Wise</NavLink>
                      
                        <NavLink to="/test_analysis_question_wise" className="nav-link" state={{test_id:pageDefault.test_id,test_name:pageDefault.test_name,topic_name:pageDefault.topic_name,sub_category_image:pageDefault.sub_category_image}}>Question Wise</NavLink>
                        
                        <button className="nav-link active" id="nav-t-wise-tab" data-bs-toggle="tab" data-bs-target="#nav-t-wise" type="button" role="tab" aria-controls="nav-t-wise" aria-selected="true">Comparative</button>
                        
                        


                        </div>
                      </nav>
                      <div className="tab-content final_analysis" id="nav-tabContent">
                       
                      
                        <div className="tab-pane fade show active" id="nav-Comperitive" role="tabpanel" aria-labelledby="nav-Comperitive-tab">
                            <div className="t_w-card comperative">
                                <h2 className="fw-bolder blue">Topic Wise Test</h2>
                                <div className="comperative_header">
                                        <div className="d-flex">
                                            <img alt="" className="img-fluid" src="/images/plan-card/electronic.png" />
                                            

                                            <div className=" m-auto mx-0">
                                                <h3 className="m-0 blue">{pageDefault.topic_name}</h3>
                                                <h3 className="m-0 blue">{pageDefault.test_name}</h3>
                                            </div>
                                        </div>
                                        <div className="user_marks">
                                            <h4 className="m-0 blue">Your Marks</h4>
                                            <div className="d-flex">
                                                <h2 className="m-0">{data.status===true?(data.current_user_data.total_correct_marks-data.current_user_data.total_incorrect_marks).toFixed(2):0} /</h2><span className="">{data.status===true?data.current_user_data.total_marks.toFixed(2):0}</span>
                                            </div>
                                           
                                        </div>
                                </div>
                                <div className="t_w-card-inner">
                                    <div className="t_w-card-box pt-0">
                                        <div className="user_won-bar d-flex justify-content-between">
                                            <div className="d-flex">
                                                <div className="position-relative">
                                                    <div className="img_won">
                                                    <img alt="" className="img-fluid position-absolute top-50 start-50 translate-middle" src={data.status===true && data.current_user_data.image.length>0?IMAGE_URL+data.current_user_data.image:"/images/dashboard/user.png"} />
                                                    </div>
                                                    <img alt=""  className="img-fluid border-0 y_badge" src="/images/winner/badge.png" />
                                                </div>
                                                <span className="text-white">  {data.status===true?data.current_user_data.fullname:""}</span>
                                            </div>
                                            <div className="d-flex">
                                                <button className="rounded-circle d-blue d-flex p-0 resresh_data" type=""><i className="las la-sync text-white m-auto"></i></button>
                                                <div className="text-center me-3 d-flex" >
                                                    <div className="m-auto">
                                                        <h3 className="m-0 ms-0 text-white">{data.status===true?data.current_user_data.rank_no:0}</h3>
                                                        <small className="text-white">Your Rank</small>
                                                    </div> 
                                                </div>
                                            </div>       
                                        </div>
                                        <div className="score_card d-flex pt-5 justify-content-around">
                                            <div className="card_status correct">

                                                <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((data.current_user_data.total_correct_question/data.current_user_data.total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `#2ed138`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#2ed138',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                   <p className="green"> {data.status===true?data.current_user_data.total_correct_question:0}</p> 
                                                    <br />
                                                   <span className="crt_data green">Correct</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="crt_total">{data.status===true?data.current_user_data.total_correct_marks.toFixed(2):0} marks</span>
                                              </div>

                                              
                                               
                                        </div>
                                        <div className="card_status incorrect">

                                            <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((data.current_user_data.total_incorrect_question/data.current_user_data.total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `#F88125`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="orange">{data.status===true?data.current_user_data.total_incorrect_question:0}</p>
                                                    <br />
                                                  <span className="ict_data orange">  Incorrect</span>
                                                    </CircularProgressbarWithChildren>
                                                    <span className="ict_total">{data.status===true?data.current_user_data.total_incorrect_marks.toFixed(2):0} marks</span>
                                              </div>

                                          
                                           
                                    </div>
                                    <div className="card_status skip">

                                        <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?(((data.current_user_data.total_question-(data.current_user_data.total_correct_question+data.current_user_data.total_incorrect_question))/data.current_user_data.total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `grey`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                    <p className="gray">{data.status===true?(data.current_user_data.total_question-(data.current_user_data.total_correct_question+data.current_user_data.total_incorrect_question)):0}</p>
                                                    <br />
                                                   <span className="utm_data gray">Unattempted</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="utm_total">0 marks</span>
                                              </div>

                                    
                                       
                                           </div>
                                        </div>
                                    </div>

                                    <Slider {...settings}>
                                    { data.status===true && data.top_students.map((result,key) => ( 
                                    
                                        
                                    <div className="t_w-card-box pt-0 other_tw-card" key={key}>
                                       
                                        <div className="user_won-bar d-flex justify-content-between">
                                            <div className="d-flex">
                                                <div className="position-relative">
                                                    <div className="img_won">
                                                    <img alt="" className="img-fluid" src={result.image.length>0?IMAGE_URL+result.image:"/images/dashboard/user.jpg"} />
                                                    </div>
                                               
                                                    
                                                    <img alt=""  className="img-fluid border-0 y_badge" src="/images/winner/badge.png" />
                                                </div>
                                            
                                                <span className="text-white">{result.fullname}</span>
                                            </div>
                                            <div className="d-flex">
                                                <button className="rounded-circle d-blue d-flex p-0 resresh_data" type=""><i className="las la-sync text-white m-auto"></i></button>
                                                <div className="text-center me-3 d-flex" >
                                                    <div className="m-auto">
                                                        <h3 className="m-0 ms-0 text-white">{result.rank_no}</h3>
                                                        <small className="text-white">Rank</small>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                                
                                        </div>
                                        <div className="mt-2 d-flex justify-content-center">
                                            <h3 className="ms-2">{(result.total_correct_marks-result.total_incorrect_marks).toFixed(2) } /</h3>
                                            <span className="half_score">{result.total_marks.toFixed(2)}</span>
                                        </div>
                                        <div className="score_card d-flex justify-content-around">
                                            <div className="card_status correct ">

                                                <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((result.total_correct_question/result.total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `#2ed138`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                <p className="green">   {result.total_correct_question}</p>
                                                    <br />
                                                    <span className="crt_data green">Correct</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="crt_total">{result.total_correct_marks.toFixed(2)} marks</span>
                                              </div>

                                               
                                                
                                        </div>
                                        <div className="card_status incorrect">


                                             <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?((result.total_incorrect_question/result.total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `#F88125`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                                <p className="orange">   {result.total_incorrect_question}</p>
                                                    <br />
                                                    <span className="ict_data orange">  Incorrect</span>
                                                    </CircularProgressbarWithChildren>
                                                    <span className="ict_total">{result.total_incorrect_marks.toFixed(2)} marks</span>
                                              </div>

                                          
                                           
                                    </div>
                                    <div className="card_status skip">
                                                  
                                        <div className="score_c-gr" >
                                               <CircularProgressbarWithChildren value={data.status===true?(((result.total_question-(result.total_correct_question+result.total_incorrect_question))/result.total_question)*100).toFixed(2):0} styles={{
                                                        // Customize the root svg element
                                                        root: {},
                                                        // Customize the path, i.e. the "completed progress"
                                                        path: {
                                                        // Path color
                                                        stroke: `grey`,
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Customize transition animation
                                                        transition: 'stroke-dashoffset 0.5s ease 0s',
                                                        // Rotate the path
                                                        transform: 'rotate(0.0turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the circle behind the path, i.e. the "total progress"
                                                        trail: {
                                                        // Trail color
                                                        stroke: '#d6d6d6',
                                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                        strokeLinecap: 'butt',
                                                        // Rotate the trail
                                                        transform: 'rotate(0.25turn)',
                                                        transformOrigin: 'center center',
                                                        },
                                                        // Customize the text
                                                        text: {
                                                        // Text color
                                                        fill: '#000000',
                                                        // Text size
                                                        fontSize: '16px',
                                                        },
                                                        // Customize background - only used when the `background` prop is true
                                                        background: {
                                                        fill: '#3e98c7',
                                                        },
                                                    }}>
                                               <p className="gray">   {result.total_question-(result.total_correct_question+result.total_incorrect_question)}</p>
                                                    <br />
                                                    <span className="utm_data gray">Unattempted</span> 
                                                    </CircularProgressbarWithChildren>
                                                    <span className="utm_total">0 marks</span>
                                              </div>


                                       
                                </div>
                                        </div>
                                    </div>

                                    ))}

                                    </Slider>
                                   
                                </div>
                            </div>
                        </div>
                      </div>
                </div>
            </div>

            {/*-----Topic-end--*/}


            
  
            <ProfileSideMenu />


             <Footer />
              </main>
             
            
            </>);
    }  
    

export default TestAnalysisCompetitive;