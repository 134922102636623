import {Header} from './elements/Header';
import {Footer} from './elements/Footer';



export const AboutUs= ()=>{
   
    return (
        <><main>
         <Header />
        
   

        <div className="container mt-5">
          <div className="y_exam-heading">
            <h3 className="m-0">About Us</h3>
            </div>

            <div className="pp_details mb-5">
              <p>Welcome to Abhays Karo, an educational platform that offers an innovative approach to learning through comprehensive question sets and detailed analysis. We understand that traditional learning methods can often leave students feeling overwhelmed and unprepared, which is why we've developed a unique online platform that provides tailored practice questions to help students master their subjects. We offer various products such as Practice Questions, Test Series and Previous Year Questions with detailed tests and videos for various competitive exams and we also offer video lectures as well as practice questions for various competitive exams like GATE, IIT JEE, NEET, UPSC, etc. Students can purchase subject-wise courses on our platform.</p>
              <p>Our mission is to provide an effective and engaging way for students to learn and achieve their academic goals. At Abhays Karo, we offer a vast array of practice questions across a range of subjects, from math and science to history and language arts. Our question sets are carefully curated to ensure that students are exposed to a variety of different problem types and levels of difficulty, helping them to build the skills and confidence needed to succeed.</p>
              <p>But we don't just stop at providing questions. Our platform also features a detailed analysis of each practice session, allowing students to identify their strengths and weaknesses and track their progress over time. Our analysis tools provide insights into areas where students may need extra help, so they can focus their efforts on the topics that will help them the most.</p>
              <h3>Salient Features:</h3>
              <ul>
              <li>Display the time taken to solve a question.</li>
              <li>Best time taken to solve a question.</li>
              <li>Students can bookmark the question.</li>
              <li>Students can jump to any question.</li>
              <li>Students can see all questions on a single page.</li>
              <li>Students can filter correct, wrong, skipped, and unattended questions.</li>
              <li>Topic-wise and Subject wise questions.</li>
              <li>Topic-wise analysis.</li>             
              </ul>
              <p>We believe that education should be accessible to everyone, which is why we offer affordable pricing plans and a user-friendly interface that is accessible on any device. Whether you're a student, parent, or educator, Abhays Karo has something to offer.</p>
              <p>Join us today and take the first step towards achieving your academic goals.</p>
            </div>

          
         </div>


         <Footer />
          </main>
         
        
        </>);

}
export default AboutUs;