import React, { useState ,useEffect}from "react";

import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import { useLocation } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {GetQuestionList} from './elements/testquestion/GetQuestionList';
export const TestQuestion= ()=>{
    const location = useLocation();
    const [loader, setLoader] = useState(false);

    
    let pageDefault=location.state;
    

    const [show, setShow] = useState({status:false});
    const handleClose = () => setShow({status:false});
    const handleShow=(single_data)=>{
        setShow({status:true}) 
    }

    useEffect(() => { 
        
       

        if (pageDefault.test_status===1) {
           setShow({status:true}) 
        }
        return () => {
           
        }
 
      },[]);
 
    

     

    return (
        
            <>
          
             <Header />
             {loader ? <Loader />: ""}
             {/* Inner _age Banner End */}
        <div className="page_banner-wrapper">
            <div className="page_baneer">
                <div className="p_yq">
                    <div className="p_yq-left d-flex">
                    <img alt="" className="img-fluid" src={pageDefault.sub_category_image?IMAGE_URL+pageDefault.sub_category_image:"/images/plan-card/dummy.png"} />
                        <div className="d-flex flex-column justify-content-center ms-2 ms-md-5 ms-sm-3 ms-xl-5">
                            <span className="ae_heading text-start">{pageDefault.topic_name}</span>

                            <h3 className="text-uppercase text-left fw-normal"><span> {pageDefault.test_name}</span></h3>
                        </div>
                    </div>
                    <div className="p_yq-right justify-content-end">
                        <button className="btn text-white text-end">Test</button>
                        <Button onClick={()=>handleShow("")}>Info</Button>
                    </div>
                </div>
                <div className="page_overlay"></div>
            </div>
        </div>
        {/* Inner _age Banner End */}
        
        <GetQuestionList setLoader={setLoader} pageDefault={pageDefault}/>

       

       
            
            {/* <ProfileSideMenu /> */}
             {/*<Footer />*/}


             <Modal show={show.status} onHide={handleClose} size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered className="select_sub p-0" >
              <Modal.Header closeButton>
                <Modal.Title><h3 className="m-0">Test Information!</h3></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                
                {pageDefault.description?<div dangerouslySetInnerHTML={{__html: pageDefault.description}}></div>:<h1>No Data Found</h1>}
                 
                <div className="d-flex justify-content-center">
                        <button type="button" className="rounded-pill cl_btn" onClick={handleClose}>Dismiss</button>
                </div>
              
              </div>                    
              </Modal.Body>
            
         
            </Modal>


            
             
            
            </>);
    }  
    

export default TestQuestion;