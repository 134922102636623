import React, { useState ,useEffect} from "react";

import axios from './general/axios.js';
import {Header} from './elements/Header';
import {Footer} from './elements/Footer';
import {Loader} from './elements/Loader';
import {ProfileSideMenu} from './elements/ProfileSideMenu';
import { APP_URL} from '../constants/global';
import { useNavigate,NavLink } from 'react-router-dom';
import { IMAGE_URL } from '../constants/global'
import "../css/slick-theme.min.css";
import "../css/slick.min.css";
import Slider from "react-slick";
import 'bootstrap/dist/js/bootstrap';

export const Dashboard= ()=>{
  
    var settings = {
        centerMode: true,
        centerPadding: "400px",
        slidesToShow: 1,
        focusOnSelect: true,
        dots: false,
        infinite: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 990,
                settings: {
                    arrows: true,
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                    centerMode: false,      
                    slidesToShow: 1,
                },
            },  
            {
                breakpoint: 1300,
                settings: {
                    arrows: true,
                    centerMode: false,
                    slidesToShow: 1,
                },
            }, 
            {
                breakpoint: 1500,
                settings: {
                    arrows: true,
                    centerMode: false,
                    slidesToShow: 1,
                },
            }, 
            {
                breakpoint: 1700,
                settings: {
                    arrows: true,
                    centerMode: false,
                    slidesToShow: 1,
                },
            }, 
        ],
    };

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const [updateHome, setupdateHome] = useState({
        status:true,
        category_id: 0,
        selected_type: "practice",
        category_name: "All",
    }); //useState default

    let api_token=localStorage.getItem('_user_token');

    const [data, setData] = useState({
        status:false,
        get_slider: null,
        selected_category_list: null,
        subcategory: null,
        user_subscription: null,
    }); //useState default

    const [subcategory, setSubCategoryData] = useState({
        status:false,
        data: null,
    }); //useState default

    let user_data=JSON.parse(localStorage.getItem('_user_data'));
      
    function get_home_page(user_selected_category_id=0){
        try {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + api_token
            }; 
           setLoader(true);
        //    axios.get(APP_URL+'get_category',{headers,params: {
        //     foo: 'bar'
        //   }},[])
           axios.get(APP_URL+'get_home_page',{headers,params:{user_selected_category_id:user_selected_category_id}},[])
           .then(response=>{
            setLoader(false);
            
            setData({
                status: response.data.status,
                get_slider: response.data.get_slider,
                selected_category_list: response.data.selected_category_list,
                user_subscription: response.data.user_subscription,
              });
             
              setSubCategoryData({
                status: response.data.status,
                data: response.data.subcategory,
             });
           })
           .catch(error=>{
            if (error.response.status===401) {
              localStorage.clear();
              navigate("/login");   
            }
            setLoader(false);  
            
           })
         } catch (e) { //code will jump here if there is a network problem
        setLoader(false);  
       
    }
   
    }

    useEffect(() => {         
        get_home_page();
        return () => {
        }
    },[]);

    function update_home_page(category_id,category_name,selected_type) 
    {
        try {
            const headers = {
                "Content-Type": "application/json",
                Authorization: "Bearer " + api_token
            }; 
            setLoader(true);

            axios.get(APP_URL+'get_home_sub_category',{headers,params:{category_id:category_id,type:selected_type}},[])
            .then(response=>{
                setLoader(false);

                setSubCategoryData({
                    status: response.data.status,
                    data: response.data.subcategory,
                });
            })
            .catch(error=>{
                setSubCategoryData({
                    status: false,
                    data: null,
                });
                setLoader(false);
            })
     
        } catch (e) { //code will jump here if there is a network problem
            setLoader(false);  
            setSubCategoryData({
                status: false,
                data: null,
            });
        }
        setupdateHome({selected_type: selected_type,category_id: category_id,category_name:category_name});
    }

      if (data.status===true) {

       /*  $(function() {
            var $st = $(".pagination");
            var $slickEl = $(".center");
          
            $slickEl.on("init reInit afterChange", function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $st.text(i + " of " + slick.slideCount);
            });
          
            $slickEl.slick({
                
            });
            }); */
      }

        return (
        
            <>
            <main>
             <Header />
             
             {loader ? <Loader />: ""}
    
                {/*Inner _age Banner End */}
                <div className="page_banner-wrapper"> 
                                <div className="page_baneer">
                                    <div className="page_baneer-inner user_l-desh d-xl-flex d-md-flex d-sm-flex d-flex">
                                    <div className="page_banner-user ">
                                        <div className="user_profile">
                                            <img alt="" className="img-fluid" src={user_data.image?IMAGE_URL+user_data.image:"/images/dashboard/user.png"} />
                                            <div className="edit_p-img">
                                                                                             
                                            </div>
                                        </div>
                                        <div className="user_name d-flex">
                                            <div className="user_name-inner m-auto  ">
                                                <h4>{user_data.firstname!=null?user_data.firstname:"User"} {user_data.lastname!=null?user_data.lastname:""}</h4>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                      {updateHome.category_name}
                                                    </button>
                                                    <ul className="dropdown-menu w-100">
                                                    <li><a className="dropdown-item"  onClick={() => update_home_page(0,"All",updateHome.selected_type)} href="#"><span>All </span></a></li>
                                                    { data.status===true && data.selected_category_list.map((selected_category,key) => ( 
                                                      
                                                      <li key={key}  onClick={() => update_home_page(selected_category.category_id,selected_category.category_name,updateHome.selected_type)}><a className="dropdown-item" href="#"><span>{selected_category.category_name}</span></a></li>

                                                    ))}                                                    
                                                     <li> <NavLink to="/edit_goal" className="dropdown-item justify-content-start"><i className="las la-plus bg-transparent blue fs-6 me-2"></i><span> Add New</span></NavLink></li>

                                                      {/* <button className="d-flex w-100"><span><i className="las la-plus-circle"></i>Add New Goal</span></button> */}
                                                    </ul>
                                                  </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page_banner-data">
                                        <div className="">
                                            {/* <p>Hello,<span>{user_data.firstname}</span>  ! Welcome to Practice Kiya</p> */}
                                        <div className="banner-data-box">
                                            <NavLink to="/my_plan" className="box-data" state={{type:"practice"}}>
                                                    <p>Practice</p>
                                                    <h5>{ (data.status ? data.user_subscription.total_practice : "0") }</h5>
                                                    <img alt="" className="box-img" src="/images/dashboard/s_pr.png" />
                                            </NavLink>
                                            <NavLink to="/my_plan" className="box-data" state={{type:"test"}}>
                                                <p>Tests</p>
                                                <h5>{ (data.status ? data.user_subscription.total_test : "0") }</h5>
                                                <img alt="" className="box-img" src="/images/dashboard/s_te.png" />
                                             </NavLink>
                                             <NavLink to="/my_plan" className="box-data" state={{type:"pyq"}}>
                                                <p>PYQs</p>
                                                <h5>{ (data.status ? data.user_subscription.total_pyq : "0") }</h5>
                                                <img alt="" className="box-img " src="/images/dashboard/s_py.png" />
                                            </NavLink>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                   
                                  
                                <Slider {...settings}>
                               
                                         
                                   { data.status===true && data.get_slider.map((slider,key) => (
                                       
                                      <div className="slide" key={key}>
                                       <div className="student_slide">
                                         <div className="ss_wrap m-0 h-100">
                                             {/* <div className="d-flex ">
                                                 <h2 className="my-auto">{slider.title}</h2>
                                             </div> */}
                                          
                                                 <img alt="" className="img-fluid " src={IMAGE_URL+slider.image} />
                                           
                                         </div>
                                       </div>
                                     </div>
                                       
                                   ))}

                                    </Slider>
                                      
                                
                                  
                                  
                                  
                                    <div className="page_overlay"></div>
                                   
                                </div>
                         
    
                       <ProfileSideMenu />

                        </div>
    
                {/*Inner _age Banner End */}
    
    
                 {/*We-Offer Banner start */}
    
                 <div className="offer_wrapper">
                        <div className="offer">
                            <h3 className="text-center mb-2 offer_t-rs">We Offer</h3>
                            <div className="row justify-content-xl-between justify-content-md-center justify-content-sm-center" >
                                <div className="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 px-xxl-0 px-xl-2 px-md-2 px-sm-2 px-1" data-target="practice" onClick={() => update_home_page(updateHome.category_id,updateHome.category_name,"practice")}>
                                   <div className={updateHome.selected_type==="practice" ? 'practice_b offer_box active': "offer_box"}>
                                        <img alt="" className="img-fluid" src="/images/dashboard/practice.png" />
                                        <div className="d-flex">   <h3>Practice</h3></div>
                                   </div>
                                </div>
                                
                                <div className="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 px-xxl-0 px-xl-2 px-md-2 px-sm-2 px-1" data-target="series" onClick={() => update_home_page(updateHome.category_id,updateHome.category_name,"test")}>
                                    <div className={updateHome.selected_type==="test" ? 'test_sb offer_box active': "offer_box"}>
                                        <img alt="" className="img-fluid" src="/images/dashboard/test.png" />
                                        <div className="d-flex">   <h3>Test Series</h3></div>
                                    </div>                           
                                </div>
                                <div className="col-xxl-3 col-xl-4 col-md-4 col-sm-4 col-4 px-xxl-0 px-xl-2 px-md-2 px-sm-2 px-1" data-target="pyqs" onClick={() => update_home_page(updateHome.category_id,updateHome.category_name,"pyq")}>
                                    <div className={updateHome.selected_type==="pyq" ? 'pyq_b offer_box active': "offer_box"}>
                                        <img alt="" className="img-fluid" src="/images/dashboard/pyqs.png" />
                                        <div className="d-flex">
                                            <h3>PYQs</h3>
                                        </div>
                                   </div>
                                </div>
                            </div>
    
                        </div>
                        <div className="y_exam-heading m-0">
                            <h3 className="offer_t-rs">Your Exams</h3>
                            {/* <a href="">View All</a> */}
                        </div>
                        
                  <div className="y_exam">
    
                    <div className="y_exam-box filter-item">
                        
                    { subcategory.status===true && subcategory.data.map((subcategory,key) => (

                        <div className="y_box" data-item="practice" key={key}>
                          
                            <img alt="y_box-img" className="img-fluid" src={subcategory.sub_category_image?IMAGE_URL+subcategory.sub_category_image:"/images/offer/offer2.png"} />
                            
                            <div className="y_box-direct">
                                <h5>{subcategory.sub_category_name}</h5>
                                
                                <NavLink to="/subscription" state={{sub_category_id:subcategory.sub_category_id,sub_category_name: subcategory.sub_category_name,sub_category_image: subcategory.sub_category_image,selected_type:updateHome.selected_type}}><i className="las la-arrow-right text-white"></i></NavLink>
                              
                                {/* <NavLink to={`/subscription/${subcategory.sub_category_id}`} state={{sub_category_id:subcategory.sub_category_id,sub_category_name: subcategory.sub_category_name,selected_type:updateHome.selected_type}}><i className="las la-arrow-right text-white"></i></NavLink> */}
                              

                       
                            </div>
                        {/*<i className="las la-ellipsis-v"></i>*/}
                        </div>
                   ))}

                       
                     
                    </div>
                    <div className="n_update-bar rounded-pill">
                        <div className="n_update">
                            <div className="d-flex">
                                <img alt="" className="img-fluid" src="/images/offer/offer8.png" />
                               <div className="d-flex">
                                <h1>daily new updates</h1>
                               </div>
                            </div>
                            <NavLink to="/blog_list" className="rounded-circle"><i className="las la-arrow-right"></i></NavLink>

                          
                            
    
                        </div>
                    </div>
                  </div>
    
    
                 </div>
                    
                
                  {/*We-Offer Banner End */}
    
             <Footer />
              </main>
             
            
            </>);
    }  
    

export default Dashboard;